const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaTipoPergunta: [
        { id: 1, valor: 'Descritiva' },
        { id: 2, valor: 'Numérica' },
        { id: 3, valor: 'Multipla Escolha' },
        { id: 4, valor: 'Confirmação' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CHECKLIST_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'CHECKLIST_LISTADO':
            return { ...state, lista: action.payload.data };

        default:
            return state;
    }
}
