import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndCheckbox from '../../common/form/labelAndCheckbox';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './enderecoActions';

class EnderecoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_petroshow'
								component={LabelAndInputNumber}
								label='ID Petroshow' placeholder='Informe o ID'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereço'
								cols='12 12 4 4'
								readOnly={readOnly} />

							<Field
								name='bairro'
								component={LabelAndInput}
								label='Bairro' placeholder='Informe o bairro'
								cols='12 12 3 3'
								readOnly={readOnly} />

							<Field
								name='numero'
								component={LabelAndInput}
								label='Número' placeholder='Informe o numero'
								cols='12 12 2 2'
								readOnly={readOnly} />

							<Field
								name='id_cidade'
								component={Select}
								options={this.props.listaCidade}
								label='Cidade'
								cols='12 12 4 6'
								placeholder='Selecione a cidade'
								readOnly={readOnly} />

							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Telefone' placeholder='Informe o telefone'
								cols='12 6 4 3'
								mask='(99) 9999-9999'
								readOnly={readOnly} />

							<Field
								name='celular'
								component={LabelAndInputMask}
								label='Celular' placeholder='Informe o celular'
								cols='12 6 4 3'
								mask='(99) 9 9999-9999'
								readOnly={readOnly} />

							<Field
								name='latitude'
								component={LabelAndInput}
								label='Latitude' placeholder='Informe a latitude'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='longitude'
								component={LabelAndInput}
								label='Longitude' placeholder='Informe a longitude'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='croqui'
								component={LabelAndInput}
								label='Croqui' placeholder='Informe o croqui'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='codigo_vendedor'
								component={LabelAndInput}
								label='Código Agente Comercial' placeholder='Informe código agente comercial'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='capacidade_tanque'
								component={LabelAndInputNumber}
								label='Capacidade do Tanque' placeholder='Informe a capacidade'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='intervalo_compra'
								component={LabelAndInputNumber}
								label='Intervalo de Compra (dias)' placeholder='Informe o intervalo'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='intervalo_contato'
								component={LabelAndInputNumber}
								label='Intervalo de Contato (dias)' placeholder='Informe o intervalo'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='serra'
								component={LabelAndCheckbox}
								label='Cliente na Serra' placeholder='Informe se o cliente é na serra'
								cols='12 6 4 3'
								readOnly={readOnly} />
								
							<Select
								name='veiculo_tipos'
								label='Tipos de Veículo' placeholder='Informe os tipos de veículo'
								cols='12 12 12 12'
								multiple
								readOnly={readOnly}
								options={this.props.listaVeiculoTipo}
								value={this.props.listaVeiculoTipo.map(item => ({ value: item.id, label: item.valor })).filter(item => (this.props.formularioValues.veiculo_tipos || []).includes(item.value))}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										veiculo_tipos: value.map(item => item.value)
									});
								}} />
								
							<Field
								name='alerta_entrega'
								component={LabelAndInput}
								label='Alerta para Entrega' placeholder='Informe se existe algum alerta para a entrega'
								cols='12 12 12 12'
								readOnly={readOnly} />
								
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

EnderecoForm = reduxForm({form: 'enderecoForm', destroyOnUnmount: false})(EnderecoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('enderecoForm')(state),
	registro: state.endereco.registro,
	listaCidade: state.endereco.listaCidade,
	listaVeiculoTipo: state.endereco.listaVeiculoTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EnderecoForm);
