import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import ButtonTable from '../common/table/buttonTable';

import { setModoTela, initForm } from './entradaActions';

class EntradaForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let veiculo = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
	
							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />

							<Field
								name='id_unidade'
								component={Select}
								options={this.props.listaUnidade}
								label='Unidade'
								cols='12 12 12 12'
								placeholder='Selecione a unidade'
								readOnly={readOnly} />
								
							<Field
								name='id_motorista'
								component={Select}
								options={this.props.listaMotorista}
								label='Motorista'
								cols='12 12 12 12'
								placeholder='Selecione o motorista'
								readOnly={readOnly} />

							<Field
								name='id_veiculo'
								component={Select}
								options={this.props.listaVeiculo}
								label='Veículo'
								cols='12 6 4 4'
								placeholder='Selecione o veículo'
								readOnly={readOnly} />

							<Field
								name='id_carreta1'
								component={Select}
								options={this.props.listaVeiculo}
								label='Carreta 1'
								cols='12 6 4 4'
								placeholder='Selecione a carreta'
								readOnly={readOnly} />

							<Field
								name='id_carreta2'
								component={Select}
								options={this.props.listaVeiculo}
								label='Carreta 2'
								cols='12 6 4 4'
								placeholder='Selecione a carreta'
								readOnly={readOnly} />

							{(veiculo && veiculo.documentacao_vencida) && (
								<Grid cols='12 12 12 12'>
									<div class="alert alert-danger alert-dismissible">
										<h6><i class="icon fas fa-ban"></i> Veículo possui problemas na documentação!</h6>
									</div>
								</Grid>
							)}
								
							<Field
								name='id_distribuidora'
								component={Select}
								options={this.props.listaDistribuidora}
								label='Distribuidora'
								cols='12 6 4 4'
								placeholder='Selecione a distribuidora'
								readOnly={readOnly} />
								
							<Field
								name='id_pedido_direto'
								component={Select}
								options={this.props.listaPedido.map(item => ({ id: item.id, valor: item.giro }))}
								label='Giro (Logística Direta)'
								cols='12 12 12 12'
								placeholder='Selecione o giro'
								readOnly={readOnly} />

							<Field
								name='numero_nfe'
								component={LabelAndInput}
								label='Número da Nota' placeholder='Informe o número da nota'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
							
							<Field
								name='datahora_previsao_chegada'
								component={LabelAndInputDateTime}
								label='Previsão de Descarga' placeholder='Informe a previsão'
								cols='12 6 4 4'
								readOnly={readOnly} />

							<Field
								name='codigo_sm_ida'
								component={LabelAndInputNumber}
								label='Código SM de Ida (Engerisco)' placeholder='Informe o código'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='codigo_sm_volta'
								component={LabelAndInputNumber}
								label='Código SM de Volta (Engerisco)' placeholder='Informe o código'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />
                
						
						</Row>

							<br />

						<Row>

							<Grid cols='6 6 4 2'>
								<Button
									text='Adicionar Produto'
									type={'success'}
									icon={'fa fa-plus'}
									disabled={this.props.formularioValues.id && this.props.formularioValues.datahora_descarga}
									event={() => {
										let lista = (this.props.formularioValues.itens || []).map(item => item);
										lista.push({ idAux: new Date().getTime(), ativo: true });
										this.props.initForm({
											...this.props.formularioValues,
											itens: lista
										});
									}} />
									
							</Grid>
							
						</Row>

						{(this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
							
							return (
								<Row key={item.id || item.idAux} 
									style={{ paddingTop: 8, marginTop: 8 }}>
									<Grid cols='10 10 11 11'>									
										<Row>								
											<Select
												options={this.props.listaProduto}
												cols='12 8 8 8'
												label={'Produto'}
												placeholder='Selecione o produto'
												value={item.id_produto}
												onChange={(data) => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																return {
																	...itemAux,
																	id_produto: data
																}
															} else {
																return itemAux;
															}
														})
													}, true);
												}} />
																		
											<LabelAndInputNumber
												label='Quantidade' 
												placeholder='Informe o quantidade'
												cols='12 4 4 4'
												casas={2}
												readOnly={readOnly}
												value={item.quantidade}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																return {
																	...itemAux,
																	quantidade: data.target.value
																}
															} else {
																return itemAux;
															}
														})
													}, true);
												}} />
										</Row>
									</Grid>
									<Grid cols='2 2 1 1' 
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
										<div 
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center'
											}} >										
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={this.props.formularioValues.id && this.props.formularioValues.datahora_descarga}
												event={() => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																return {
																	...itemAux,
																	ativo: false
																}
															} else {
																return itemAux;
															}
														})
													});
												}} />
										</div>
									</Grid>			
								</Row>
							)
						})}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

EntradaForm = reduxForm({form: 'entradaForm', destroyOnUnmount: false})(EntradaForm);
const mapStateToProps = state => ({
	sessao: state.auth.entrada,
	formularioValues: getFormValues('entradaForm')(state),
	registro: state.entrada.registro,
	listaProduto: state.entrada.listaProduto,
	listaUnidade: state.entrada.listaUnidade,
	listaTipo: state.entrada.listaTipo,
	listaTanque: state.entrada.listaTanque,
    listaMotorista: state.entrada.listaMotorista,
    listaVeiculo: state.entrada.listaVeiculo,
    listaDistribuidora: state.entrada.listaDistribuidora,
    listaPedido: state.entrada.listaPedido
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EntradaForm);
