import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioPerfilReducer from '../usuarioPerfil/usuarioPerfilReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import UnidadeReducer from '../unidade/unidadeReducer';
import MotoristaReducer from '../motorista/motoristaReducer';
import VeiculoTipoReducer from '../veiculoTipo/veiculoTipoReducer';
import VeiculoReducer from '../veiculo/veiculoReducer';
import ProdutoReducer from '../produto/produtoReducer';
import DistribuidoraReducer from '../distribuidora/distribuidoraReducer';
import TanqueReducer from '../tanque/tanqueReducer';
import ClienteReducer from '../cliente/clienteReducer';
import EnderecoReducer from '../cliente/endereco/enderecoReducer';
import PrecoDiaReducer from '../precoDia/precoDiaReducer';
import AdicionalDiarioReducer from '../adicionalDiario/adicionalDiarioReducer';
import FreteLitroReducer from '../freteLitro/freteLitroReducer';
import PrecoGeralReducer from '../precoGeral/precoGeralReducer';
import PoliticaPrecoReducer from '../politicaPreco/politicaPrecoReducer';
import PedidoReducer from '../pedido/pedidoReducer';
import CustoPrazoReducer from '../custoPrazo/custoPrazoReducer';
import EntradaReducer from '../entrada/entradaReducer';
import SaidaReducer from '../saida/saidaReducer';
import TransferenciaReducer from '../transferencia/transferenciaReducer';
import AnaliseCurvaPrecoReducer from '../analiseCurvaPreco/analiseCurvaPrecoReducer';
import DashboardPedidoReducer from '../dashboardPedido/dashboardPedidoReducer';
import DashboardPetroshowReducer from '../dashboardPetroshow/dashboardPetroshowReducer';
import SetorReducer from '../setor/setorReducer';
import ApontamentoGrupoReducer from '../apontamentoGrupo/apontamentoGrupoReducer';
import ApontamentoTipoReducer from '../apontamentoTipo/apontamentoTipoReducer';
import ApontamentoReducer from '../apontamento/apontamentoReducer';
import VeiculoTipoConsumoReducer from '../veiculoTipoConsumo/veiculoTipoConsumoReducer';
import MetaMotoristaReducer from '../metaMotorista/metaMotoristaReducer';
import MetaMotoristaGrupoReducer from '../metaMotoristaGrupo/metaMotoristaGrupoReducer';
import ChamadoReducer from '../chamado/chamadoReducer';
import MetaVendedorReducer from '../metaVendedor/metaVendedorReducer';
import MetaVendaReducer from '../metaVenda/metaVendaReducer';
import ProspeccaoReducer from '../prospeccao/prospeccaoReducer';
import ProspeccaoEventoReducer from '../prospeccao/prospeccaoEvento/prospeccaoEventoReducer';
import VeiculoStatusReducer from '../veiculoStatus/veiculoStatusReducer';
import PontoMapaReducer from '../pontoMapa/pontoMapaReducer';
import WhatsappReducer from '../whatsapp/whatsappReducer';
import ChecklistReducer from '../checklist/checklistReducer';
import RelatorioMovimentosReducer from '../relatorioMovimentos/relatorioMovimentosReducer';
import OficinaReducer from '../oficina/oficinaReducer';
import ManutencaoReducer from '../manutencao/manutencaoReducer';
import estoqueManualReducer from '../estoqueManual/estoqueManualReducer';
import LimiteCreditoReducer from '../limiteCredito/limiteCreditoReducer';
import VeiculoDocumentoReducer from '../veiculoDocumento/veiculoDocumentoReducer';
import ManutencaoPreventivaTipoReducer from '../manutencaoPreventivaTipo/manutencaoPreventivaTipoReducer';
import ManutencaoPreventivaReducer from '../manutencaoPreventiva/manutencaoPreventivaReducer';
import VeiculoDespesaReducer from '../veiculoDespesa/veiculoDespesaReducer';
import DashboardClienteReducer from '../dashboardCliente/dashboardClienteReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	dashboardPedido: DashboardPedidoReducer,
	dashboardPetroshow: DashboardPetroshowReducer,
	usuarioPerfil: UsuarioPerfilReducer,
	usuario: UsuarioReducer,
	unidade: UnidadeReducer,
	motorista: MotoristaReducer,
	veiculoTipo: VeiculoTipoReducer,
	veiculo: VeiculoReducer,
	produto: ProdutoReducer,
	distribuidora: DistribuidoraReducer,
	tanque: TanqueReducer,
	cliente: ClienteReducer,
	endereco: EnderecoReducer,
	precoDia: PrecoDiaReducer,
	adicionalDiario: AdicionalDiarioReducer,
	freteLitro: FreteLitroReducer,
	precoGeral: PrecoGeralReducer,
	politicaPreco: PoliticaPrecoReducer,
	pedido: PedidoReducer,
	custoPrazo: CustoPrazoReducer,
	entrada: EntradaReducer,
	saida: SaidaReducer,
	transferencia: TransferenciaReducer,
	analiseCurvaPreco: AnaliseCurvaPrecoReducer,
	setor: SetorReducer,
	apontamentoGrupo: ApontamentoGrupoReducer,
	apontamentoTipo: ApontamentoTipoReducer,
	apontamento: ApontamentoReducer,
	veiculoTipoConsumo: VeiculoTipoConsumoReducer,
	metaMotorista: MetaMotoristaReducer,
	metaMotoristaGrupo: MetaMotoristaGrupoReducer,
	chamado: ChamadoReducer,
	metaVendedor: MetaVendedorReducer,
	metaVenda: MetaVendaReducer,
	prospeccao: ProspeccaoReducer,
	prospeccaoEvento: ProspeccaoEventoReducer,
	veiculoStatus: VeiculoStatusReducer,
	pontoMapa: PontoMapaReducer,
	whatsapp: WhatsappReducer,
	checklist: ChecklistReducer,
	relatorioMovimentos: RelatorioMovimentosReducer,
	oficina: OficinaReducer,
	manutencao: ManutencaoReducer,
	estoqueManual: estoqueManualReducer,
	limiteCredito: LimiteCreditoReducer,
	veiculoDocumento: VeiculoDocumentoReducer,
	manutencaoPreventivaTipo: ManutencaoPreventivaTipoReducer,
	manutencaoPreventiva: ManutencaoPreventivaReducer,
	veiculoDespesa: VeiculoDespesaReducer,
	dashboardCliente: DashboardClienteReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

