import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, 
	getListaUnidade, getListaProduto , getListaPosicaoVeiculo, getListaManual
} from './dashboardPetroshowActions';

import contentCardBody from '../common/template/contentCardBody';

class DashboardPetroshow extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getListaUnidade();
		this.props.getListaProduto();
		this.props.getLista();
		this.props.getListaTerceiros();
		this.props.getListaEntradas();
		this.props.getListaSaidas();
		this.props.getListaTransferencias();
		this.props.getListaPosicaoVeiculo();
		this.props.getListaManual();
    }

    render() {

		let totalS10EstoqueManual = 0;
		let totalS10EstoqueBase = 0;
		let totalS10SobreRodas = 0;
		let totalS10Faturado = 0;
		let totalS10EstoqueVirtualInfinitum = 0;

		let totalS500EstoqueManual = 0;
		let totalS500EstoqueBase = 0;
		let totalS500SobreRodas = 0;
		let totalS500Faturado = 0;
		let totalS500EstoqueVirtualInfinitum = 0;

        return (
			<Content>

				{this.state.itens ? this.modalItens() : null}

				{this.props.listaUnidade.filter(unidade => !unidade.valor.toUpperCase().includes('DIRETA') && this.props.lista.filter(item => item.id_unidade == unidade.id).length).map(unidade => {

					let totalEstoqueManual = 0;
					let totalEstoqueBase = 0;
					let totalSobreRodas = 0;
					let totalFaturado = 0;
					let totalEstoqueVirtualInfinitum = 0;

					return (
						<ContentCard key={unidade.id}>
							<ContentCardHeader>
								<label>Saldos - {unidade.valor}</label>
							</ContentCardHeader>
							<contentCardBody>
								<Table responsive>
									<THead>
										<Tr>
											<Th alignCenter></Th>
											<Th alignCenter>Estoque Base</Th>
											<Th alignCenter>Sobre Rodas</Th>
											<Th alignCenter>Faturado (não carregado)</Th>
											<Th alignCenter>Estoque Virtual Infinitum</Th>
											<Th alignCenter>Estoque Virtual Petroshow</Th>
											<Th alignCenter>Diferença (Infinitum - Petroshow)</Th>
										</Tr>
									</THead>
									<TBody>
										{(this.props.listaProduto || []).map(produto => {

											let registroManual = this.props.listaManual.filter(manual => manual.id_unidade == unidade.id && manual.id_produto == produto.id).pop();

											let estoqueManual = parseFloat(registroManual ? registroManual.quantidade : 0);
											
											let tanques = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'ESTOQUE BASE')[0]
											let rodas = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'SOBRE RODAS')[0]
											let faturado = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'FATURADO (NÃO CARREGADO)')[0]
											
											let estoqueVirtualInfinitum = 
												parseFloat(tanques ? tanques.quantidade : 0) 
												+ parseFloat(rodas ? rodas.quantidade : 0) 
												- parseFloat(faturado ? faturado.quantidade : 0);
											
											let listaAux = this.props.lista.filter(item => item.id_unidade == unidade.id 
												&& item.id_produto == produto.id
												&& item.status != 'PROGRAMADO'
											);
											
											totalEstoqueManual += estoqueManual;
											totalEstoqueBase += parseFloat(listaAux[0].quantidade || 0);
											totalSobreRodas += parseFloat(listaAux[1].quantidade || 0);
											totalFaturado += parseFloat(listaAux[3].quantidade || 0);
											totalEstoqueVirtualInfinitum += parseFloat(estoqueVirtualInfinitum || 0);
											
											if (produto.id == 1) {
												totalS10EstoqueManual += estoqueManual;
												totalS10EstoqueBase += parseFloat(listaAux[0].quantidade || 0);
												totalS10SobreRodas += parseFloat(listaAux[1].quantidade || 0);
												totalS10Faturado += parseFloat(listaAux[3].quantidade || 0);
												totalS10EstoqueVirtualInfinitum += parseFloat(estoqueVirtualInfinitum || 0);
											} else {
												totalS500EstoqueManual += estoqueManual;
												totalS500EstoqueBase += parseFloat(listaAux[0].quantidade || 0);
												totalS500SobreRodas += parseFloat(listaAux[1].quantidade || 0);
												totalS500Faturado += parseFloat(listaAux[3].quantidade || 0);
												totalS500EstoqueVirtualInfinitum += parseFloat(estoqueVirtualInfinitum || 0);
											}
											
											return (
												<Tr key={produto.id}>

													<Td alignCenter backgroundColor={produto.id == 1 ? '#FFFDD0' : '#f2c2b8'}>{produto.valor}</Td>
													
													{this.props.lista.filter(item => item.id_unidade == unidade.id 
														&& item.id_produto == produto.id
														&& item.status != 'PROGRAMADO'
														&& item.status != 'À FATURAR'
													).map((item, i) => {

														let lista = [];
														if (item.status == 'SOBRE RODAS') {
															lista = (this.props.listaEntrada || []).filter(entrada => 
																entrada.id_unidade == item.id_unidade
																&& entrada.id_produto == item.id_produto
																&& entrada.status == item.status
															);

															lista = lista.concat((this.props.listaTransferencia || []).filter(transferencia => 
																(transferencia.id_unidade_destino == item.id_unidade)
																&& transferencia.id_produto == item.id_produto
																&& transferencia.status == item.status
															));
														}

														return (
															<Td key={i} alignCenter
																title={
																	lista.map(mov => {

																		let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];
																		
																		return `${mov.giro ? `GIRO ${mov.giro} | ` : ''}${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`;
																	}).join('\n')
																}
																onClick={() => {
																	if (!lista.length) {
																		// window.alert('Nenhum registro encontrado');
																	} else {
																		this.setDialogItens(lista.map(mov => {

																			let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];
	
																			return `${mov.giro ? `GIRO ${mov.giro} | ` : ''}${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`;
																		}));
																		// window.alert(
																		// 	lista.map(mov => {

																		// 		let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];

																		// 		return `${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`;
																		// 	}).join('\n')
																		// );
																	}
																}}
																>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)}</Td>
														);
													})}
													<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(estoqueVirtualInfinitum, 0)}</Td>
													<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(estoqueManual, 0)}</Td>
													<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(estoqueVirtualInfinitum - estoqueManual, 0)}</Td>
												</Tr>
											);
										})}
										
										<Tr backgroundColor={'#f2f2f0'}>
											<Td alignRight width={100} bold fontSize={16}>TOTAL</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueBase, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalSobreRodas, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalFaturado, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueVirtualInfinitum, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueManual, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueVirtualInfinitum - totalEstoqueManual, 0)}</Td>
										</Tr>
										
									</TBody>
								</Table>			
							</contentCardBody>
						</ContentCard>
					);
				})}
				
				<ContentCard>
					<ContentCardHeader>
						<label>Total das Unidades</label>
					</ContentCardHeader>
					<contentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter></Th>
									<Th alignCenter>Estoque Base</Th>
									<Th alignCenter>Sobre Rodas</Th>
									<Th alignCenter>Faturado (não carregado)</Th>
									<Th alignCenter>Estoque Virtual Infinitum</Th>
									<Th alignCenter>Estoque Virtual Petroshow</Th>
									<Th alignCenter>Diferença (Infinitum - Petroshow)</Th>
								</Tr>
							</THead>
							<TBody>
								<Tr>
									<Td alignCenter width={100} backgroundColor={'#FFFDD0'}>B S10 ADITIVADO</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueBase, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodas, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturado, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueVirtualInfinitum, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueManual, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueVirtualInfinitum - totalS10EstoqueManual, 0)}</Td>
								</Tr>	
								<Tr>
									<Td alignCenter width={100} backgroundColor={'#f2c2b8'}>B S500 ADITIVADO</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueBase, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500SobreRodas, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500Faturado, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueVirtualInfinitum, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueManual, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueVirtualInfinitum - totalS500EstoqueManual, 0)}</Td>
								</Tr>			
								<Tr backgroundColor={'#f2f2f0'}>
									<Td alignRight width={100} bold fontSize={16}>TOTAL</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueBase + totalS500EstoqueBase, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodas + totalS500SobreRodas, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturado + totalS500Faturado, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueVirtualInfinitum + totalS500EstoqueVirtualInfinitum, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueManual + totalS500EstoqueManual, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal((totalS10EstoqueVirtualInfinitum + totalS500EstoqueVirtualInfinitum) - (totalS10EstoqueManual + totalS500EstoqueManual), 0)}</Td>
								</Tr>								
							</TBody>
						</Table>			
					</contentCardBody>
				</ContentCard>
			</Content>
        )
	}

	setDialogItens(itens) {
		this.setState({
			...this.state,
			itens: itens
		})
	}
	
	modalItens() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogItens(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								{(this.state.itens || []).map(item => (
									<p key={item}>{item}</p>
								))}								
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setDialogItens(null);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboardPetroshow.aguardando,
	lista: state.dashboardPetroshow.lista,
	listaTerceiro: state.dashboardPetroshow.listaTerceiro,
	listaEntrada: state.dashboardPetroshow.listaEntrada,
	listaSaida: state.dashboardPetroshow.listaSaida,
	listaTransferencia: state.dashboardPetroshow.listaTransferencia,
	listaUnidade: state.dashboardPetroshow.listaUnidade,
	listaProduto: state.dashboardPetroshow.listaProduto,
	listaPosicaoVeiculo: state.dashboardPetroshow.listaPosicaoVeiculo,
	listaManual: state.dashboardPetroshow.listaManual
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, getListaUnidade, getListaProduto, getListaPosicaoVeiculo, getListaManual
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPetroshow)
