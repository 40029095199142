import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initFormUsuario } from './clienteActions';
import { setErroAPI, setSuccess } from '../common/toast/toast';

class ClienteUsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={'readOnly'} />

							<Field
								name='login'
								component={LabelAndInput}
								label='Login *'
								placeholder='Informe o Usuário'
								cols='12 12 12 12'
								readOnly={'readOnly'} />

							{this.props.formularioValues.senha_gerada ? (
								<Field
									name='senha_gerada'
									component={LabelAndInput}
									label='Senha Gerada'
									placeholder='Informe a Senha'
									cols='12 12 12 12'
									readOnly={'readOnly'} />
							) : null}

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (!this.props.formularioValues.senha_gerada ? (
									<Button
										text={this.props.formularioValues.id ? 'Gerar nova Senha' : 'Criar Acesso'}
										submit
										type={'success'}
										icon={'fa fa-check'} />
								) : (
									<Button
										text='Copiar Senha'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={async () => {
											await navigator.clipboard.writeText(this.props.formularioValues.senha_gerada);
											setSuccess('Senha Copiada')
										}} />
								))}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

ClienteUsuarioForm = reduxForm({form: 'clienteUsuarioForm', destroyOnUnmount: false})(ClienteUsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('clienteUsuarioForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initFormUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteUsuarioForm);
