import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './precoDiaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInput from '../common/form/labelAndInput';
import ColorUtils from '../common/colorUtils/ColorUtils';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro } from './precoDiaActions';

class PrecoDia extends Component {

	state = {
		linhaSelecionada: null,
		idAlteracao: null,
		preco: null,
		codigo: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data' placeholder='Informe a data'
							cols='6 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data: data.target.value });
								this.props.getLista();
							}} />

						{this.props.filtro.data && DateFormat.formatarDataTelaParaSql(this.props.filtro.data) 
							&& DateFormat.formatarDataTelaParaSql(this.props.filtro.data) == DateFormat.getDataAtual() ? (
							<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
								<Button
									text='Processar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({});
									}} />
							</Grid>
						) : null}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}>Distribuidora</Th>
								<Th width={200} alignCenter>Produto</Th>
								<Th width={100} alignCenter>Anterior</Th>
								<Th width={100} alignCenter>Preço</Th>
								<Th width={100} alignCenter>Código</Th>
								<Th width={100}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_distribuidora} ${item.nome_produto || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id}
									backgroundColor={item.cor_distribuidora} color={ColorUtils.getCorFonte(item.cor_distribuidora)} >
									<Td minWidth={200}>{item.nome_distribuidora}</Td>
									<Td minWidth={200} alignCenter>{item.nome_produto}</Td>
									<Td minWidth={200} alignCenter
										title={(item.historico || []).map(historico => `${DateFormat.formatarDataSqlParaTela(historico.data)}: R$ ${FormatUtils.formatarValorTela(historico.preco, 4)}`).join('\n')}
										>{(item.historico || []).filter((historico, i) => i == 0).map(historico => FormatUtils.formatarValorTela(historico.preco, 4)).join('')}</Td>
									{item.id != this.state.idAlteracao ? (
										<Td minWidth={100} alignCenter
											title={(item.historico || []).map(historico => `${DateFormat.formatarDataSqlParaTela(historico.data)}: R$ ${FormatUtils.formatarValorTela(historico.preco, 4)}`).join('\n')} >
											{FormatUtils.formatarValorTela(item.preco, 4)}
										</Td>
									) : (
										<Td minWidth={100} alignCenter>
											<LabelAndInputNumber
												placeholder='Informe o preço'
												cols='12 12 12 12'
												casas={4}
												value={this.state.preco}
												onChange={(data) => {
													this.setState({ ...this.state, preco: data.target.value })
												}} />
										</Td>
									)}
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										item.codigo
									: (
										<LabelAndInput
											placeholder='Informe o código'
											cols='12 12 12 12'
											value={this.state.codigo}
											onChange={(data) => {
												this.setState({ ...this.state, codigo: data.target.value })
											}} />
									)}</Td>
									<Td alignRight minWidth={100}>
										{!this.state.idAlteracao || item.id != this.state.idAlteracao ? (
											<>
												<ButtonTable
													type={'primary'}
													icon={'fas fa-history'}
													visible={true}
													disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
													event={() => {
														if (!item.historico) {
															window.alert('Sem históricos anteriores');
														} else {
															window.alert(
																item.historico.map(historico => `${DateFormat.formatarDataSqlParaTela(historico.data)}: R$ ${FormatUtils.formatarValorTela(historico.preco, 4)}`).join('\n')
															);
														}
													}} />
													
												<ButtonTable
													type={'warning'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
													event={() => {
														this.setState({ ...this.state, idAlteracao: item.id, preco: FormatUtils.formatarValorTela(item.preco, 4), codigo: item.codigo });
													}} />
											</>
										) : (
											<>
												<ButtonTable
													type={'success'}
													icon={'fas fa-check'}
													visible={true}
													disabled={false}
													event={() => {
														this.props.salvar({ 
															...item,
															preco: this.state.preco,
															codigo: this.state.codigo
														});
														this.setState({ ...this.state, idAlteracao: null, preco: null, codigo: null });
													}} />
													
												<ButtonTable
													type={'danger'}
													icon={'fas fa-times'}
													visible={true}
													disabled={false}
													event={() => {
														this.setState({ ...this.state, idAlteracao: null, preco: null, codigo: null });
													}} />
											</>
										)}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.precoDia.modoTela,
	lista: state.precoDia.lista,
	filtro: state.precoDia.filtro,
	aguardando: state.precoDia.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrecoDia);
