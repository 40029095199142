
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	lista: [],
	filtro: {
		
	},
	listaUnidade: [],
	listaProduto: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
	
		case 'RELATORIO_MOVIMENTOS_FILTRO':
            return { ...state,
				filtro: action.payload
			};

        case 'RELATORIO_MOVIMENTOS_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'RELATORIO_MOVIMENTOS_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

        case 'RELATORIO_MOVIMENTOS_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

        default:
            return state;
    }
}
