const INITIAL_STATE = {
	aguardando: false,
	lista: [],
	listaTerceiro: [],
	listaEntrada: [],
	listaSaida: [],
	listaTransferencia: [],
	listaUnidade: [],
	listaProduto: [],
	listaPosicaoVeiculo: [],
	listaMovimento: [],
	listaManual: []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_PETROSHOW_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'DASHBOARD_PETROSHOW_LISTADO':
			return { ...state, lista: action.payload.data };
			
		case 'DASHBOARD_PETROSHOW_TERCEIRO_LISTADO':
			return { ...state, listaTerceiro: action.payload.data };
			
		case 'DASHBOARD_PETROSHOW_ENTRADA_LISTADO':
			return { ...state, listaEntrada: action.payload.data };

		case 'DASHBOARD_PETROSHOW_SAIDA_LISTADO':
			return { ...state, listaSaida: action.payload.data };

		case 'DASHBOARD_PETROSHOW_TRANSFERENCIA_LISTADO':
			return { ...state, listaTransferencia: action.payload.data };
		
		case 'DASHBOARD_PETROSHOW_UNIDADE_SELECT_LISTADO':
			return { ...state, listaUnidade: action.payload.data };
		
		case 'DASHBOARD_PETROSHOW_PRODUTO_SELECT_LISTADO':
			return { ...state, listaProduto: action.payload.data };

		case 'DASHBOARD_PETROSHOW_VEICULO_POSICAO_LISTADO':
			return { ...state, listaPosicaoVeiculo: action.payload.data };

		case 'DASHBOARD_PETROSHOW_MOVIMENTOS_LISTADO':
			return { ...state, listaMovimento: action.payload.data }

		case 'DASHBOARD_PETROSHOW_ESTOQUE_MANUAL_LISTADO':
			return { ...state, listaManual: action.payload.data.filter(item => item.petroshow) }

        default:
            return state;
    }
}
