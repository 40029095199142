const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaVeiculo: [],
    listaTipo: [],
    listaPeriodo: [
        { id: 30, valor: '1 mês' },
        { id: 60, valor: '2 meses' },
        { id: 90, valor: '3 meses' },
        { id: 120, valor: '4 meses' },
        { id: 150, valor: '5 meses' },
        { id: 180, valor: '6 meses' },
        { id: 210, valor: '7 meses' },
        { id: 240, valor: '8 meses' },
        { id: 270, valor: '9 meses' },
        { id: 300, valor: '10 meses' },
        { id: 330, valor: '11 meses' },
        { id: 365, valor: '1 ano' },
        { id: 458, valor: '15 meses' },
        { id: 730, valor: '2 anos' },
        { id: 1095, valor: '3 anos' },
        { id: 1460, valor: '4 anos' }
    ],
    listaKm: [
        { id: 5000, valor: '5.000 km' },
        { id: 10000, valor: '10.000 km' },
        { id: 15000, valor: '15.000 km' },
        { id: 20000, valor: '20.000 km' },
        { id: 30000, valor: '30.000 km' },
        { id: 40000, valor: '40.000 km' },
        { id: 45000, valor: '45.000 km' },
        { id: 50000, valor: '50.000 km' },
        { id: 60000, valor: '60.000 km' },
        { id: 70000, valor: '70.000 km' },
        { id: 80000, valor: '80.000 km' },
        { id: 90000, valor: '90.000 km' },
        { id: 100000, valor: '100.000 km' },
        { id: 120000, valor: '120.000 km' },
        { id: 250000, valor: '250.000 km' },
        { id: 360000, valor: '360.000 km' },
        { id: 400000, valor: '400.000 km' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MANUTENCAO_PREVENTIVA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'MANUTENCAO_PREVENTIVA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'MANUTENCAO_PREVENTIVA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
            };

        case 'MANUTENCAO_PREVENTIVA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };
                      
        default:
            return state;
    }
}
