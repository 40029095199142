import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setFiltro(filtro) {
    return {
        type: 'ANALISE_CURVA_PRECO_FILTRO',
        payload: filtro
    };
}

export function getLista() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/precoDia/listarHistorico?
			&id_distribuidora=${getState().analiseCurvaPreco.filtro.id_distribuidora || 0}
			&id_produto=${getState().analiseCurvaPreco.filtro.id_produto || 0}`)
		.then(resp => {
			dispatch({
		        type: 'ANALISE_CURVA_PRECO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaDistribuidora() {
    const request = axios.get(`${consts.API_URL}/distribuidora/listarSelect`);
    return {
        type: 'ANALISE_CURVA_PRECO_DISTRIBUIDORA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'ANALISE_CURVA_PRECO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}