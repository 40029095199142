import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'LIMITE_CREDITO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'LIMITE_CREDITO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('limiteCreditoForm', registro)
    ];
}

export function getLista(agenda) {
    const request = axios.get(`${consts.API_URL}/limiteCredito${agenda ? '?agenda=true' : ''}`);
    return {
        type: 'LIMITE_CREDITO_LISTADO',
        payload: request
    };
}

export function initFormProspeccao(registro = {}) {
    return [
        initialize('prospeccaoForm', registro)
    ];
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/limiteCredito`, {
				...registro,
				datahora_abertura: DateFormat.getDataHoraAtual(),
				cpf_cnpj: String(registro.cpf_cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				volume_mes: FormatUtils.formatarValorBanco(registro.volume_mes, 2),
				valor_medio: FormatUtils.formatarValorBanco(registro.valor_medio, 2),
				limite_sugerido: FormatUtils.formatarValorBanco(registro.limite_sugerido, 2),
				limite_proposto: FormatUtils.formatarValorBanco(registro.limite_proposto, 2),
				prazo: FormatUtils.formatarValorBanco(registro.prazo, 0),
				area_frota_propria: FormatUtils.formatarValorBanco(registro.area_frota_propria, 0),
				area_frota_arrendada: FormatUtils.formatarValorBanco(registro.area_frota_arrendada, 0),
				area_soja: FormatUtils.formatarValorBanco(registro.area_soja, 0),
				area_milho: FormatUtils.formatarValorBanco(registro.area_milho, 0),
				area_algodao: FormatUtils.formatarValorBanco(registro.area_algodao, 0),
				area_pasto: FormatUtils.formatarValorBanco(registro.area_pasto, 0),
				area_outros: FormatUtils.formatarValorBanco(registro.area_outros, 0),
				limite_liberado: FormatUtils.formatarValorBanco(registro.limite_liberado, 2),
				limite_volume: FormatUtils.formatarValorBanco(registro.limite_volume, 2),
				id_usuario_vendedor: getState().auth.usuario.id,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/limiteCredito`, {
				...registro,
				cpf_cnpj: String(registro.cpf_cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				volume_mes: FormatUtils.formatarValorBanco(registro.volume_mes, 2),
				valor_medio: FormatUtils.formatarValorBanco(registro.valor_medio, 2),
				limite_sugerido: FormatUtils.formatarValorBanco(registro.limite_sugerido, 2),
				limite_proposto: FormatUtils.formatarValorBanco(registro.limite_proposto, 2),
				prazo: FormatUtils.formatarValorBanco(registro.prazo, 0),
				area_frota_propria: FormatUtils.formatarValorBanco(registro.area_frota_propria, 0),
				area_frota_arrendada: FormatUtils.formatarValorBanco(registro.area_frota_arrendada, 0),
				area_soja: FormatUtils.formatarValorBanco(registro.area_soja, 0),
				area_milho: FormatUtils.formatarValorBanco(registro.area_milho, 0),
				area_algodao: FormatUtils.formatarValorBanco(registro.area_algodao, 0),
				area_pasto: FormatUtils.formatarValorBanco(registro.area_pasto, 0),
				area_outros: FormatUtils.formatarValorBanco(registro.area_outros, 0),
				limite_liberado: FormatUtils.formatarValorBanco(registro.limite_liberado, 2),
				limite_volume: FormatUtils.formatarValorBanco(registro.limite_volume, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/limiteCredito?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'LIMITE_CREDITO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEndereco() {
    const request = axios.get(`${consts.API_URL}/endereco/listarSelect`);
    return {
        type: 'LIMITE_CREDITO_ENDERECO_SELECT_LISTADO',
        payload: request
    };
}