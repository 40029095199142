const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [],
    listaCidade: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'DISTRIBUIDORA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'DISTRIBUIDORA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'DISTRIBUIDORA_CIDADE_SELECT_LISTADO':
            return { ...state, listaCidade: action.payload.data };

        default:
            return state;
    }
}
