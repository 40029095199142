import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './manutencaoPreventivaForm';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaVeiculo } from './manutencaoPreventivaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';

class ManutencaoPreventiva extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		id_status: 1,
		veiculos: []
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
		this.props.getListaVeiculo();

		let idInterval = setInterval(() => {
			this.props.getLista();
			this.props.getListaTipo();
			this.props.getListaVeiculo();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		// let listaExportacao = [];
		// this.props.lista.forEach(item => {
		// 	(item.itens || []).forEach(manutencaoPreventivaPreventivaItem => {
		// 		listaExportacao.push({
		// 			...item,
		// 			id: `${item.id}-${manutencaoPreventivaItem.id}`,					
		// 			datahora_programacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_programacao),
		// 			datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
		// 			datahora_previsao_chegada: item.datahora_previsao_chegada ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada) : null,
		// 			datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
		// 			nome_produto: manutencaoPreventivaItem.nome_produto,
		// 			quantidade: FormatUtils.formatarValorTela(manutencaoPreventivaItem.quantidade, 2)
		// 		});
		// 	});
		// });
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ data_manutencao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()) });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Row>

						<Select
							options={this.props.listaTipo}
							label='Tipo'
							cols='12 4 4 4'
							placeholder='Selecione o tipo'
							value={this.state.id_tipo}
							onChange={value => this.setState({ ...this.state, id_tipo: value })} />

						<Select
							options={this.props.listaVeiculo}
							label='Veículo'
							cols='12 4 4 4'
							placeholder='Selecione o veículo'
							value={this.state.veiculos}
							multiple
							onChange={values => {
								this.setState({
									...this.state,
									veiculos: (values || []).map(item => item.value)
								});
							}} />

						<Select
							options={[{id: 1, valor: 'Última Manutenção' }, {id: 2, valor: 'Todas as Manutenções' }]}
							label='Status'
							cols='12 4 4 4'
							placeholder='Selecione o status'
							value={this.state.id_status}
							onChange={value => this.setState({ ...this.state, id_status: value })} />

					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Data da Manutenção</Th>
								<Th colspan={1} alignCenter>Tipo</Th>
								<Th colspan={1} alignCenter>Placa</Th>
								<Th colspan={1} alignCenter>Data da Próxima</Th>
								<Th colspan={1} alignCenter>Km da Próxima</Th>
								<Th colspan={1} alignCenter>Odômetro Veículo</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.id_tipo && item.id_tipo != this.state.id_tipo) {
									return false;
								}
								if (this.state.veiculos.length && !this.state.veiculos.includes(item.id_veiculo)) {
									return false;
								}
								if (this.state.id_status == 1 && !item.ultima) {
									return false;
								}
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.data_manutencao)} ${item.nome_tipo} ${item.placa_veiculo || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => {

								let backgroundPeriodo = item.status_periodo == 'atencao' ? '#dede04' : (item.status_periodo == 'atrasado' ? '#b30502' : null);
								let colorPeriodo = item.status_periodo == 'atencao' ? '#000000' : (item.status_periodo == 'atrasado' ? '#ffffff' : null);
								let backgroundKm = item.status_km == 'atencao' ? '#dede04' : (item.status_km == 'atrasado' ? '#b30502' : null);
								let colorKm = item.status_km == 'atencao' ? '#000000' : (item.status_km == 'atrasado' ? '#ffffff' : null);

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{DateFormat.formatarDataSqlParaTela(item.data_manutencao)}</Td>
										<Td alignCenter>{item.nome_tipo}</Td>
										<Td alignCenter>{item.placa_veiculo}</Td>
										<Td alignCenter backgroundColor={backgroundPeriodo} color={colorPeriodo}>{DateFormat.formatarDataSqlParaTela(item.data_proxima_manutencao)}</Td>
										<Td alignCenter backgroundColor={backgroundKm} color={colorKm}>{item.km_proxima_manutencao ? FormatUtils.formatarValorTelaDecimal(item.km_proxima_manutencao, 0) : ''}</Td>
										<Td alignCenter>{item.odometro_veiculo ? FormatUtils.formatarValorTelaDecimal(item.odometro_veiculo, 0) : ''}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														data_manutencao: DateFormat.formatarDataSqlParaTela(item.data_manutencao)
													});
													this.props.initForm({
														...item,
														data_manutencao: DateFormat.formatarDataSqlParaTela(item.data_manutencao)
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														data_manutencao: DateFormat.formatarDataSqlParaTela(item.data_manutencao)
													});
													this.props.initForm({
														...item,
														data_manutencao: DateFormat.formatarDataSqlParaTela(item.data_manutencao)
													});
												}} />
										</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.manutencaoPreventiva,
	modoTela: state.manutencaoPreventiva.modoTela,
	lista: state.manutencaoPreventiva.lista,
	listaTipo: state.manutencaoPreventiva.listaTipo,
    listaVeiculo: state.manutencaoPreventiva.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaVeiculo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoPreventiva);
