import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_PETROSHOW_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/entrada/listarSaldosTanques`);
    return {
        type: 'DASHBOARD_PETROSHOW_LISTADO',
        payload: request
    };
}

export function getListaEstoquePetroshow() {
    const request = axios.get(`${consts.API_URL}/estoqueManual`);
    return {
        type: 'DASHBOARD_PETROSHOW_MANUAL_LISTADO',
        payload: request
    };
}

export function getListaTerceiros() {
    const request = axios.get(`${consts.API_URL}/entrada/listarSaldosTerceiros`);
    return {
        type: 'DASHBOARD_PETROSHOW_TERCEIRO_LISTADO',
        payload: request
    };
}

export function getListaEntradas() {
    const request = axios.get(`${consts.API_URL}/entrada/listarDashboardPetroshow`);
    return {
        type: 'DASHBOARD_PETROSHOW_ENTRADA_LISTADO',
        payload: request
    };
}

export function getListaSaidas() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboardPetroshow`);
    return {
        type: 'DASHBOARD_PETROSHOW_SAIDA_LISTADO',
        payload: request
    };
}


export function getListaTransferencias() {
    const request = axios.get(`${consts.API_URL}/transferencia/listarDashboardPetroshow`);
    return {
        type: 'DASHBOARD_PETROSHOW_TRANSFERENCIA_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'DASHBOARD_PETROSHOW_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'DASHBOARD_PETROSHOW_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPosicaoVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/posicao`);
    return {
        type: 'DASHBOARD_PETROSHOW_VEICULO_POSICAO_LISTADO',
        payload: request
    };
}

export function getListaManual() {
    const request = axios.get(`${consts.API_URL}/estoqueManual`);
    return {
        type: 'DASHBOARD_PETROSHOW_ESTOQUE_MANUAL_LISTADO',
        payload: request
    };
}