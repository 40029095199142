import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Map from '../common/map/map';
import Select from '../common/form/select';

import { setModoTela, getLista, getListaStatus, getListaTipo } from './veiculoStatusActions';
import DateFormat from '../common/dateFormat/DateFormat';

import markerTruckVermelho from '../assets/mapa/marker_truck_vermelho.png';
import markerTruckAzul from '../assets/mapa/marker_truck_azul.png';
import markerTruckVerde from '../assets/mapa/marker_truck_verde.png';
import markerTruckAmarelo from '../assets/mapa/marker_truck_amarelo.png';
import markerTruckLaranja from '../assets/mapa/marker_truck_laranja.png';

class VeiculoStatus extends Component {

	state = {
		id_tipo: null,
		status: null
	}

	componentDidMount() {

	}

    componentWillMount() {
		this.props.getListaStatus()
		this.props.getLista();
		this.props.getListaTipo();
	}
	
    render() {

		let pontos = (this.props.lista || [])
		.filter(item => item.Latitude && item.Longitude)
		.filter(item => {
			
			let veiculo = this.props.listaStatus.filter(veiculo => veiculo.placa == item.Placa)[0]

			return !this.state.id_tipo || (veiculo && veiculo.id_tipo == this.state.id_tipo);

		}).filter(item => {
			
			let veiculo = this.props.listaStatus.filter(veiculo => veiculo.placa == item.Placa)[0]

			return !this.state.status 
				|| (this.state.status == 'sem' && (!veiculo || !veiculo.status))
				|| (this.state.status == 'programado' && veiculo && veiculo.status == 'PROGRAMADO')
				|| (this.state.status == 'sobre' && veiculo && veiculo.status == 'SOBRE RODAS');

		}).map(item => {

			let veiculo = this.props.listaStatus.filter(veiculo => veiculo.placa == item.Placa)[0];

			return {
				id: item.id,
				nome: `${item.Placa}\nTipo: ${veiculo && veiculo.nome_tipo ? veiculo.nome_tipo : '-'}\nStatus: ${veiculo && veiculo.status ? veiculo.status : 'Sem Status'}\nOdômetro: ${item.Odometro || ''}\nDescrição Sistema: ${item.DescricaoSistema || ''}\nDescrição Tecnologia: ${item.DescricaoTecnologia || ''}\nAtualização: ${DateFormat.formatarDataHoraSqlParaTela(String(item.DataTecnologia).split('-').join('/').substring(0, 16))}\nLocalização: ${item.Latitude || ''},${item.Longitude || ''}`,
				latitude: item.Latitude,
				longitude: item.Longitude,
				icone: veiculo && veiculo.status ? (veiculo.status == 'SOBRE RODAS' ? 'marker-truck-verde' : 'marker-truck-azul') : 'marker-truck-vermelho'
			};
		});
		
        return (
            <div>
                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Select
									options={this.props.listaTipo}
									label='Tipo'
									cols='12 12 4 4'
									placeholder='Selecione o tipo' 
									value={this.state.id_tipo} 
									onChange={value => this.setState({ ...this.state, id_tipo: value })} />

								<Select
									options={[{ id: 'sem', valor: 'Sem Status' }, { id: 'programado', valor: 'Programado' }, { id: 'sobre', valor: 'Sobre Rodas' }]}
									label='Status'
									cols='12 12 4 4'
									placeholder='Selecione o status' 
									value={this.state.status} 
									onChange={value => this.setState({ ...this.state, status: value })} />
							</Row>
							<Row>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVermelho} />
									Sem Status
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckAzul} />
									Programado
								</div>
								<div style={{ padding: 4 }}>
									<img src={markerTruckVerde} />
									Sobre Rodas
								</div>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>			
							<Map 
								pontos={pontos}
								rota={this.state.rota}
								rotaCarregada={this.state.rotaCarregada}
								setRota={rota => {
									this.setState({
										...this.state,
										rotaCarregada: rota.rotaCarregada,
										rota: {
											pontos: rota ? rota.pontos : [],
											itens: rota ? rota.itens : [],
											overview_polyline: rota.overview_polyline || null
										},
										postos: rota ? rota.pontos : []
									});
								}} />		
						</ContentCardBody>
					</ContentCard>
                </Content>
            </div>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.veiculoStatus.modoTela,
	lista: state.veiculoStatus.lista,
	listaStatus: state.veiculoStatus.listaStatus,
	listaTipo: state.veiculoStatus.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getLista, getListaStatus, getListaTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoStatus);
