const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaPedido: [],
	listaCidade: [],
	listaTipo: [
		{ id: 5, valor: 'WhatsApp' },
		{ id: 1, valor: 'Telefone' },
		{ id: 2, valor: 'E-mail' },
		{ id: 3, valor: 'Visita' },
		{ id: 4, valor: 'Outro' }
	],
	listaTipoRetorno: [
		{ id: 1, valor: 'Venda Realizada' },
		{ id: 2, valor: 'Venda Perdida' },
		{ id: 3, valor: 'Não Respondido' },
		{ id: 4, valor: 'Manutenção de Contato' },
		{ id: 5, valor: 'Outro' }
	]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PROSPECCAO_EVENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PROSPECCAO_EVENTO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'PROSPECCAO_EVENTO_PEDIDO_LISTADO':
            return {
				...state,
				listaPedido: action.payload.data
			};

        case 'PROSPECCAO_EVENTO_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

        default:
            return state;
    }
}
