import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import ColorUtils from '../common/colorUtils/ColorUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { getLista, setFiltro, getListaUnidade, getListaProduto, imprimir } from './relatorioMovimentosActions';

class RelatorioMovimentos extends Component {

	state = {
		linhaSelecionada: null,
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 259200000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaUnidade();
		this.props.getListaProduto();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let saldoCalculado = 0;

		let lista = this.props.lista.filter(item => {
			if (!this.props.filtro.id_unidade || item.id_unidade != this.props.filtro.id_unidade) {
				return false;
			}
			if (!this.props.filtro.id_produto || item.id_produto != this.props.filtro.id_produto) {
				return false;
			}
			return true;
		}).map(item => {
			saldoCalculado += parseFloat(item.quantidade);

			return {
				...item,
				saldo: saldoCalculado
			}
		}).filter(item => {
			if (this.state.dataInicial
				&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora)) < DateFormat.formatarDataTelaParaSql(this.state.dataInicial)) {
				return false;
			}
			if (this.state.dataFinal
				&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora)) > DateFormat.formatarDataTelaParaSql(this.state.dataFinal)) {
				return false;
			}
			return true;
		});

		let saldoInicial = null;
		let saldoFinal = null;
		let quantidadeEntradas = 0;
		let quantidadeSaidas = 0;

		let listaAgrupamento = [];
		
		let cabecalho = {
			unidade: this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0] ? this.props.listaUnidade.filter(item => item.id == this.props.filtro.id_unidade)[0].valor : '',
			produto: this.props.listaProduto.filter(item => item.id == this.props.filtro.id_produto)[0] ? this.props.listaProduto.filter(item => item.id == this.props.filtro.id_produto)[0].valor : '',
			dataInicial: this.state.dataInicial,
			dataFinal: this.state.dataFinal,
			dataEmissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
		};
		let paginas = [{ lista: [] }];

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							label='Unidade' placeholder='Selecione a unidade'
							cols='6 6 4 2'
							options={this.props.listaUnidade}
							value={this.props.filtro.id_unidade}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_unidade: value });
								this.props.getLista();
							}} />

						<Select
							label='Produto' placeholder='Selecione o produto'
							cols='6 6 4 2'
							options={this.props.listaProduto}
							value={this.props.filtro.id_produto}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_produto: value });
								this.props.getLista();
							}} />

						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />

						
						<Grid cols='6 6 3 2' style={{ marginTop: 28 }}>
							<ExcelFile
								filename={'Movimentos'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora: DateFormat.formatarDataHoraSqlParaTela(item.datahora),
									quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
									saldo: FormatUtils.formatarValorTela(item.saldo, 2)
								}))} name='Movimentos'>
									<ExcelColumn label='Data Hora' value='datahora' />
									<ExcelColumn label='Tipo' value='tipo' />
									<ExcelColumn label='Descrição' value='descricao' />
									<ExcelColumn label='Giro / NF' value='nf_giro' />
									<ExcelColumn label='Unidade' value='nome_unidade' />
									<ExcelColumn label='Produto' value='nome_produto' />
									<ExcelColumn label='Status' value='status' />
									<ExcelColumn label='Quantidade' value='quantidade_movimento' />
									<ExcelColumn label='Saldo' value='saldo' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>

						<Grid cols='6 6 3 2' style={{ marginTop: 28 }}>
							<Button
								text='Imprimir'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {

									let totalizadores = [];

									totalizadores.push({
										backgroundColor: '#c1dbe8',
										fontWeight: 'bold',
										nome: 'Saldo Inicial',
										valor: FormatUtils.formatarValorTelaDecimal(saldoInicial, 2)
									});
	
									totalizadores.push({
										backgroundColor: '#99cfb1',
										fontWeight: 'bold',
										nome: 'Entradas',
										valor: ''
									});
									
									listaAgrupamento.filter(item => item.tipo == 'ENTRADA').forEach(item => {
										totalizadores.push({
											backgroundColor: '#99cfb1',
											nome: item.descricao,
											valor: FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)
										});
									});
	
									totalizadores.push({
										backgroundColor: '#99cfb1',
										fontWeight: 'bold',
										nome: 'Total',
										valor: FormatUtils.formatarValorTelaDecimal(quantidadeEntradas, 2)
									});
									
									totalizadores.push({
										backgroundColor: '#ebdcab',
										fontWeight: 'bold',
										nome: 'Saídas',
										valor: ''
									});
	
									listaAgrupamento.filter(item => item.tipo == 'SAIDA').map(item => {
										totalizadores.push({
											backgroundColor: '#ebdcab',
											nome: item.descricao,
											valor: FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)
										});
									});
	
									totalizadores.push({
										backgroundColor: '#ebdcab',
										fontWeight: 'bold',
										nome: 'Total',
										valor: FormatUtils.formatarValorTelaDecimal(quantidadeSaidas, 2)
									});
	
									totalizadores.push({
										backgroundColor: '#c1dbe8',
										fontWeight: 'bold',
										nome: 'Saldo do Período',
										valor: FormatUtils.formatarValorTelaDecimal(saldoFinal - saldoInicial, 2)
									});
	
									totalizadores.push({
										backgroundColor: '#c1dbe8',
										fontWeight: 'bold',
										nome: 'Saldo Final',
										valor: FormatUtils.formatarValorTelaDecimal(saldoFinal, 2)
									});

									this.props.imprimir(cabecalho, paginas, totalizadores);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200} alignCenter>Data Hora</Th>
								<Th width={200} alignCenter>Tipo</Th>
								<Th width={200} alignCenter>Descrição</Th>
								<Th width={200} alignCenter>Giro / NF</Th>
								<Th width={200} alignCenter>Unidade</Th>
								<Th width={200} alignCenter>Produto</Th>
								<Th width={200} alignCenter>Status</Th>
								<Th width={200} alignRight>Quantidade</Th>
								<Th width={200} alignRight>Saldo</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map((item, i) => {
								
								if (item.status != 'ESTOQUE FÍSICO') {
									
									if (item.status == 'ESTOQUE BASE') {
										
										//Saldo Inicial
										if (saldoInicial == null) {
											saldoInicial = parseFloat(item.saldo) - parseFloat(item.quantidade_movimento);
										}
										saldoFinal = parseFloat(item.saldo);

										//Saldo por agrupadores
										if (parseFloat(item.quantidade_movimento) >= 0) {
											
											quantidadeEntradas += parseFloat(item.quantidade_movimento);

											if (!listaAgrupamento.filter(agrupador => agrupador.descricao == item.descricao && agrupador.tipo == 'ENTRADA').length) {
												listaAgrupamento.push({
													descricao: item.descricao,
													tipo: 'ENTRADA',
													quantidade: parseFloat(item.quantidade_movimento)
												});
												listaAgrupamento.sort((a, b) => a.descricao > b.descricao ? 1 : -1);
											} else {
												listaAgrupamento = listaAgrupamento.map(agrupador => {
													if (agrupador.descricao == item.descricao && agrupador.tipo == 'ENTRADA') {
														return {
															...agrupador,
															quantidade: agrupador.quantidade + parseFloat(item.quantidade_movimento)
														};
													} else {
														return agrupador;
													}
												});
											}

										} else {

											quantidadeSaidas += parseFloat(item.quantidade_movimento) * -1;

											if (!listaAgrupamento.filter(agrupador => agrupador.descricao == item.descricao && agrupador.tipo == 'SAIDA').length) {
												listaAgrupamento.push({
													descricao: item.descricao,
													tipo: 'SAIDA',
													quantidade: parseFloat(item.quantidade_movimento) * -1
												});
												listaAgrupamento.sort((a, b) => a.descricao > b.descricao ? 1 : -1);
											} else {
												listaAgrupamento = listaAgrupamento.map(agrupador => {
													if (agrupador.descricao == item.descricao && agrupador.tipo == 'SAIDA') {
														return {
															...agrupador,
															quantidade: agrupador.quantidade + parseFloat(item.quantidade_movimento) * -1
														};
													} else {
														return agrupador;
													}
												});
											}
										}
									}
								}

								paginas[paginas.length - 1].lista.push({
									dataHora:  { valor: DateFormat.formatarDataHoraSqlParaTela(item.datahora), backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									tipo:      { valor: item.tipo, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									descricao: { valor: item.descricao, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									giroNF:    { valor: item.nf_giro, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									unidade:   { valor: item.nome_unidade, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									produto:   { valor: item.nome_produto, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									status:    { valor: item.status, backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									quantidade:{ valor: FormatUtils.formatarValorTelaDecimal(item.quantidade_movimento, 2), backgroundColor: item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null, color: item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null },
									saldo:     { valor: FormatUtils.formatarValorTelaDecimal(item.saldo, 2) }
								});

								if (paginas[paginas.length - 1].lista.length >= 34) {
									paginas.push({ lista: [] });
								}
								
								return (
									<Tr key={item.id} >
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora)}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.tipo}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.descricao}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.nf_giro}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.nome_unidade}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.nome_produto}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={200} alignCenter>{item.status}</Td>
										<Td backgroundColor={item.status == 'ESTOQUE FÍSICO' ? '#04b04e' : null} color={item.status == 'ESTOQUE FÍSICO' ? '#ffffff' : null} minWidth={100} alignRight>{FormatUtils.formatarValorTelaDecimal(item.quantidade_movimento, 2)}</Td>
										<Td minWidth={100} alignRight bold>{FormatUtils.formatarValorTelaDecimal(item.saldo, 2)}</Td>
									</Tr>
								);
							})}
							
							<Tr backgroundColor='#c1dbe8'>
								<Td colspan={8} alignRight bold>Saldo Inicial</Td>
								<Td colspan={1} alignRight bold>{FormatUtils.formatarValorTelaDecimal(saldoInicial, 2)}</Td>
							</Tr>
							<Tr backgroundColor='#99cfb1'>
								<Td colspan={8} alignRight bold>Entradas</Td>
								<Td colspan={1} alignRight bold></Td>
							</Tr>
							{listaAgrupamento.filter(item => item.tipo == 'ENTRADA').map(item => (
								<Tr key={item.descricao} backgroundColor='#99cfb1'>
									<Td colspan={8} alignRight>{item.descricao}</Td>
									<Td colspan={1} alignRight>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)}</Td>
								</Tr>	
							))}
							<Tr backgroundColor='#99cfb1'>
								<Td colspan={8} alignRight bold>Total</Td>
								<Td colspan={1} alignRight bold>{FormatUtils.formatarValorTelaDecimal(quantidadeEntradas, 2)}</Td>
							</Tr>
							<Tr backgroundColor='#ebdcab'>
								<Td colspan={8} alignRight bold>Saídas</Td>
								<Td colspan={1} alignRight bold></Td>
							</Tr>
							{listaAgrupamento.filter(item => item.tipo == 'SAIDA').map(item => (
								<Tr key={item.descricao} backgroundColor='#ebdcab'>
									<Td colspan={8} alignRight>{item.descricao}</Td>
									<Td colspan={1} alignRight>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)}</Td>
								</Tr>	
							))}
							<Tr backgroundColor='#ebdcab'>
								<Td colspan={8} alignRight bold>Total</Td>
								<Td colspan={1} alignRight bold>{FormatUtils.formatarValorTelaDecimal(quantidadeSaidas, 2)}</Td>
							</Tr>
							<Tr backgroundColor='#c1dbe8'>
								<Td colspan={8} alignRight bold>Saldo do Período</Td>
								<Td colspan={1} alignRight bold>{FormatUtils.formatarValorTelaDecimal(saldoFinal - saldoInicial, 2)}</Td>
							</Tr>
							<Tr backgroundColor='#c1dbe8'>
								<Td colspan={8} alignRight bold>Saldo Final</Td>
								<Td colspan={1} alignRight bold>{FormatUtils.formatarValorTelaDecimal(saldoFinal, 2)}</Td>
							</Tr>
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	lista: state.relatorioMovimentos.lista,
	filtro: state.relatorioMovimentos.filtro,
	listaUnidade: state.relatorioMovimentos.listaUnidade,
	listaProduto: state.relatorioMovimentos.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, setFiltro, getListaUnidade, getListaProduto, imprimir }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioMovimentos);
