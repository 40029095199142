const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaTipo: [{ id: 1, valor: 'Pessoa Física' }, { id: 2, valor: 'Pessoa Jurídica' }],
	listaEndereco: [],
	listaCidade: [],
	listaProspeccao: [],
	listaUnidade: [],
	listaUsuario: [],
	listaAtividade: [],
	listaProduto: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PROSPECCAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PROSPECCAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'PROSPECCAO_ENDERECO_SELECT_LISTADO':
            return {
				...state,
				listaEndereco: action.payload.data
			};

		case 'PROSPECCAO_PROSPECCAO_SELECT_LISTADO':
            return {
				...state,
				listaProspeccao: action.payload.data
			};

		case 'PROSPECCAO_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'PROSPECCAO_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

		case 'PROSPECCAO_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
			};

		case 'PROSPECCAO_ATIVIDADE_SELECT_LISTADO':
			return {
				...state,
				listaAtividade: action.payload.data
			};

		case 'PROSPECCAO_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
			};

        default:
            return state;
    }
}
