import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pedidoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';

import { 
	setModoTela, initForm, salvar, excluir, getListaEntrega, salvarCodigoSM, setDialogCodigoSM,
	getListaDistribuidora, getListaPoliticaPreco, getListaUnidade, getListaMotorista, 
	getListaVeiculo, getListaCliente, getListaEndereco, getListaProduto, getListaCustoPrazo, gerarOrcamento,
	verificaEstoqueManual
} from './pedidoActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';

class PedidoEntrega extends Component {

	state = {
		linhaSelecionada: null,
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 2592000000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
		this.props.getListaEntrega(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaDistribuidora();
		this.props.getListaPoliticaPreco();
		this.props.getListaUnidade();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
		this.props.getListaCliente();
		this.props.getListaEndereco();
		this.props.getListaProduto();
		this.props.getListaCustoPrazo();
		this.props.verificaEstoqueManual();

		let idInterval = setInterval(() => {
			if (this.props.modoTela == 'lista') {
				this.props.getListaEntrega(this.state.dataInicial, this.state.dataFinal);
			}
			this.props.getListaDistribuidora();
			this.props.getListaPoliticaPreco();
			this.props.getListaUnidade();
			this.props.getListaMotorista();
			this.props.getListaVeiculo();
			this.props.getListaCliente();
			this.props.getListaEndereco();
			this.props.getListaProduto();
			this.props.getListaCustoPrazo();
			this.props.verificaEstoqueManual();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
    }

    render() {

		if (!this.props.estoqueManualLancado) {
			return (
				<div>
					<Content>
						
						<Row>
							<Grid cols='12 12 12 12'>
								<div class="alert alert-danger alert-dismissible">
									<h5><i class="icon fas fa-ban"></i> Operação Bloqueada!</h5>
									<h6>Lance o estoque físico de todas as bases para desbloquear.</h6>
								</div>
							</Grid>
						</Row>
					</Content>
				</div>
			);
		}
		
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={this.props.salvar} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} visualizacao />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				
				{this.props.dialogPedido ? this.modalCodigoSM() : null}

				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
							
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getListaEntrega(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>

				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data</Th>
								<Th alignCenter>Giro</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Vendedor</Th>
								<Th alignCenter>Clientes</Th>
								<Th alignCenter>Finalizado Base</Th>
								<Th alignCenter>Início Viagem</Th>
								<Th alignCenter>Fim Viagem</Th>
								<Th alignCenter>Código SM</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaEntrega.filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.data_pedido)} ${item.nome_status} ${item.clientes}
										${item.giro} ${item.nome_motorista} ${item.placa_veiculo} ${item.nome_usuario_inclusao}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).filter(item => item.id_status == 3 || item.id_status == 7)
								.filter(item => item.id_usuario_inclusao == (this.props.sessao ? this.props.sessao.id : 0)
										|| (this.props.usuarioCarregado && this.props.usuarioCarregado.acesso_pedido_aprovacao))
								.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									backgroundColor={
										item.datahora_fim_retorno ? '#5dc763' : (
											item.datahora_inicio_retorno ? '#778ced' : (
												item.datahora_fim_entrega ? '#f2ec3d' : (
													item.datahora_inicio_entrega ? '#f2aa2e' : '#d17c73'
												)
											)
										)
									} >
									<Td>{DateFormat.formatarDataSqlParaTela(item.data_pedido)}</Td>
									<Td alignCenter>{item.giro}</Td>
									<Td alignCenter>{item.datahora_fim_retorno ? 'Finalizada' : (
										item.datahora_inicio_retorno ? 'Retornando' : (
											item.datahora_fim_entrega ? 'Entregue' : (
												item.datahora_inicio_entrega ? 'Em Entrega' : 'Não Iniciada'
											)
										)
									)}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_usuario_inclusao}</Td>
									<Td alignCenter>{item.clientes}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega)}</Td>
									<Td alignCenter>
										{item.datahora_inicio_entrega ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_entrega) : ''}
									</Td>
									<Td alignCenter>
										{item.datahora_fim_retorno ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_retorno) : ''}
									</Td>
									<Td alignCenter>
										{item.codigo_sm_entrega || '-'} / {item.codigo_sm_retorno || '-'}
									</Td>
									<Td alignRight minWidth={200}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {

												this.props.setDialogCodigoSM({
													id: item.id,
													codigo_sm_entrega: item.codigo_sm_entrega || null,
													codigo_sm_retorno: item.codigo_sm_retorno || null
												});
												
											}} />

										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2)
													}))
												});
												this.props.initForm({
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2)
													}))
												});
											}} />

										<ButtonTable
											type={'primary'}
											icon={'fas fa-download'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.gerarOrcamento(item);
											}} />

										<ButtonTable
											type={'success'}
											icon={'fab fa-whatsapp'}
											visible={this.state.linhaSelecionada == item.id}
											
											title={item.celular_motorista ? 'Mensagem para motorista' : 'Sem motorista ou motorista sem celular configurado'}
											event={() => {

												let mensagem = `*Giro**:* *${item.giro}*\n`;
												mensagem += `Placa: ${item.placa_veiculo}\n`;
												mensagem += `Entregas:\n`;
												(item.itens || []).forEach((pedidoItem, i) => {													
													mensagem += `  *Pedido* *${i + 1}**:*\n`;
													mensagem += `  Cliente: ${pedidoItem.nome_cliente}\n`;
													mensagem += `  Endereço: ${pedidoItem.nome_endereco}\n`;
													mensagem += `  Produto: ${pedidoItem.nome_produto}\n`;
													mensagem += `  Quantidade: ${FormatUtils.formatarValorTela(pedidoItem.quantidade, 2)}\n`;
													mensagem += `  Obs: ${pedidoItem.observacao}\n\n`;
												});
												if (item.rota_link) {
													mensagem += `Link: ${item.rota_link}`;
												}
												window.open(`https://api.whatsapp.com/send?phone=+55${item.celular_motorista}&text=${encodeURI(mensagem)}`, '_blank');
										
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	modalCodigoSM() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Rastreamento</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.props.setDialogCodigoSM(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Row>
								<LabelAndInputNumber
									label='Código SM Ida' 
									placeholder='Informe o código'
									cols='12 12 12 12'
									casas={0}
									value={this.props.dialogPedido.codigo_sm_entrega}
									onChange={data => {
										this.props.setDialogCodigoSM({
											...this.props.dialogPedido,
											codigo_sm_entrega: data.target.value
										});
									}} />

								<LabelAndInputNumber
									label='Código SM Volta' 
									placeholder='Informe o código'
									cols='12 12 12 12'
									casas={0}
									value={this.props.dialogPedido.codigo_sm_retorno}
									onChange={data => {
										this.props.setDialogCodigoSM({
											...this.props.dialogPedido,
											codigo_sm_retorno: data.target.value
										});
									}} />
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.props.salvarCodigoSM({
										id: this.props.dialogPedido.id,
										codigo_sm_entrega: this.props.dialogPedido.codigo_sm_entrega ? FormatUtils.formatarValorBanco(this.props.dialogPedido.codigo_sm_entrega, 0) : null,
										codigo_sm_retorno: this.props.dialogPedido.codigo_sm_retorno ? FormatUtils.formatarValorBanco(this.props.dialogPedido.codigo_sm_retorno, 0) : null,
									});
									this.props.setDialogCodigoSM(null);
								}}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.pedido.aguardando,
	modoTela: state.pedido.modoTela,
	listaEntrega: state.pedido.listaEntrega,
	dialogPedido: state.pedido.dialogPedido,
	estoqueManualLancado: state.pedido.estoqueManualLancado
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getListaEntrega, salvarCodigoSM, setDialogCodigoSM,
	getListaDistribuidora, getListaPoliticaPreco, getListaUnidade, getListaMotorista, 
	getListaVeiculo, getListaCliente, getListaEndereco, getListaProduto, getListaCustoPrazo, gerarOrcamento,
	verificaEstoqueManual
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoEntrega);
