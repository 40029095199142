import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './politicaPrecoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import LabelAndInput from '../common/form/labelAndInput';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaDistribuidora } from './politicaPrecoActions';
import distribuidora from '../distribuidora/distribuidora';
import ColorUtils from '../common/colorUtils/ColorUtils';

class PoliticaPreco extends Component {

	state = {
		linhaSelecionada: null,
		idAlteracao: null,
		id_distribuidora: null,
		estrategia: null,
		preco_manual: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaDistribuidora();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let cor = 0;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data' placeholder='Informe a data'
							cols='6 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data: data.target.value });
								this.props.getLista();
								this.setState({ ...this.state, idAlteracao: null, dias_prazo: null, percentual_indice: null });
							}} />

						{this.props.filtro.data && DateFormat.formatarDataTelaParaSql(this.props.filtro.data) 
							&& DateFormat.formatarDataTelaParaSql(this.props.filtro.data) == DateFormat.getDataAtual() ? (
							<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
								<Button
									text='Processar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({});
									}} />
							</Grid>
						) : null}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					{window.innerWidth >= 900 
					? this.tabela(cor)
					: this.responsivo(cor)}
					
				</ContentCardBody>
			</ContentCard>
		);
	}

	tabela(cor) {
		
		return (
			<Table responsive>
				<THead>
					<Tr>
						<Th width={200}>Unidade</Th>
						<Th width={200} alignCenter>Produto</Th>
						<Th width={200} alignCenter>Distribuidora</Th>
						<Th width={100} alignCenter>Preço Manual</Th>
						<Th width={100} alignCenter>Estratégia</Th>
						<Th width={100} alignCenter>Política</Th>
						<Th width={100} alignCenter>Preço</Th>
						<Th width={100}></Th>
					</Tr>
				</THead>
				<TBody>
					{this.props.lista.filter(item => {
						if (this.state.pesquisar
							&& !(`${item.nome_unidade} ${item.nome_produto || ''} ${item.nome_distribuidora || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
							return false;
						}
						return true;
					}).map((item, i) => {

						if (this.props.lista[i-1] && this.props.lista[i-1].nome_unidade != item.nome_unidade) {
							cor = cor == '#f2f2f0' ? null : '#f2f2f0';
						}

						return (
							<Tr key={item.id} backgroundColor={cor}>
								<Td minWidth={200}>{item.nome_unidade}</Td>
								<Td minWidth={200} alignCenter>{item.nome_produto}</Td>
								<Td minWidth={100} 
									alignCenter
									backgroundColor={this.props.listaDistribuidora.filter(distribuidora => distribuidora.id == item.id_distribuidora).map(distribuidora => distribuidora.cor).join()}
									color={item.id == this.state.idAlteracao ? null : ColorUtils.getCorFonte(this.props.listaDistribuidora.filter(distribuidora => distribuidora.id == item.id_distribuidora).map(distribuidora => distribuidora.cor).join())}>
									{item.id != this.state.idAlteracao ? item.nome_distribuidora
									: (
										<Select
											options={this.props.listaDistribuidora}
											cols='12 12 12 12'
											label={''}
											placeholder='Selecione a distribuidora'
											value={this.state.id_distribuidora}
											onChange={(data) => {
												this.setState({ ...this.state, id_distribuidora: data })
											}} />
									)}
								</Td>
								<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
									FormatUtils.formatarValorTela(item.preco_manual, 4)
								: (
									<LabelAndInputNumber
										placeholder='Informe o preço'
										cols='12 12 12 12'
										casas={4}
										value={this.state.preco_manual}
										onChange={(data) => {
											this.setState({ ...this.state, preco_manual: data.target.value })
										}} />
								)}</Td>
								<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
									FormatUtils.formatarValorTela(item.estrategia, 2)
								: (
									<LabelAndInputNumber
										placeholder='Informe a estratégia'
										cols='12 12 12 12'
										casas={2}
										value={this.state.estrategia}
										onChange={(data) => {
											this.setState({ ...this.state, estrategia: data.target.value })
										}} />
								)}</Td>									
								<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.politica, 0)}</Td>
								<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.preco, 4)}</Td>
								<Td alignRight minWidth={100}>
									{!this.state.idAlteracao || item.id != this.state.idAlteracao ? (
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true}
											disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
											event={() => {
												this.setState({ 
													...this.state, 
													idAlteracao: item.id,
													id_distribuidora: item.id_distribuidora,
													estrategia: FormatUtils.formatarValorTela(item.estrategia, 2),
													preco_manual: FormatUtils.formatarValorTela(item.preco_manual, 4)
												});
											}} />
									) : (
										<>
											<ButtonTable
												type={'success'}
												icon={'fas fa-check'}
												visible={true}
												disabled={false}
												event={() => {
													this.props.salvar({ 
														...item,
														id_distribuidora: this.state.id_distribuidora,
														estrategia: this.state.estrategia,
														preco_manual: this.state.preco_manual
													});
													this.setState({ ...this.state, idAlteracao: null, id_distribuidora: null, estrategia: null, preco_manual: null });
												}} />
												
											<ButtonTable
												type={'danger'}
												icon={'fas fa-times'}
												visible={true}
												disabled={false}
												event={() => {
													this.setState({ ...this.state, idAlteracao: null, id_distribuidora: null, estrategia: null, preco_manual: null });
												}} />
										</>
									)}
								</Td>
							</Tr>
						)
					})}
				</TBody>
			</Table>
		)
	}

	responsivo(cor) {

		return (
			<Table responsive>
				<THead>
					<Tr>
						<Th></Th>
					</Tr>
				</THead>
				<TBody>
					{this.props.lista.filter(item => {
						if (this.state.pesquisar
							&& !(`${item.nome_unidade} ${item.nome_produto || ''} ${item.nome_distribuidora || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
							return false;
						}
						return true;
					}).map((item, i) => {
						if (this.props.lista[i-1] && this.props.lista[i-1].nome_unidade != item.nome_unidade) {
							cor = cor == '#f2f2f0' ? null : '#f2f2f0';
						}
						return (
							<Tr key={item.id} backgroundColor={cor}>
								<Td>
									<Row>
										<Grid cols='12 6 4 4'>
											<LabelAndInput
												label='Unidade'
												placeholder='Selecione a unidade'
												cols='12 12 12 12'
												readOnly='readOnly'
												value={item.nome_unidade}
												onChange={(data) => {
												}} />
										</Grid>
										<Grid cols='12 6 4 3'>
											<LabelAndInput
												label='Produto'
												placeholder='Selecione o produto'
												cols='12 12 12 12'
												readOnly='readOnly'
												value={item.nome_produto}
												onChange={(data) => {
												}} />
										</Grid>
										<Grid cols='12 6 4 5'
											style={{
												backgroundColor: this.props.listaDistribuidora.filter(distribuidora => distribuidora.id == item.id_distribuidora).map(distribuidora => distribuidora.cor).join(),
												color: item.id == this.state.idAlteracao ? null : ColorUtils.getCorFonte(this.props.listaDistribuidora.filter(distribuidora => distribuidora.id == item.id_distribuidora).map(distribuidora => distribuidora.cor).join())
											}}>													
											{item.id != this.state.idAlteracao ? (
												<LabelAndInput
													label='Distribuidora'
													placeholder='Selecione a distribuidora'
													cols='12 12 12 12'
													readOnly='readOnly'
													value={item.nome_distribuidora}
													onChange={(data) => {
													}} />
											) : (
												<Select
													options={this.props.listaDistribuidora}
													cols='12 12 12 12'
													label={'Distribuidora'}
													placeholder='Selecione a distribuidora'
													value={this.state.id_distribuidora}
													onChange={(data) => {
														this.setState({ ...this.state, id_distribuidora: data })
													}} />
											)}
										</Grid>
										<Grid cols='6 6 4 3'>
											{item.id != this.state.idAlteracao ? (
												<LabelAndInputNumber
													label='Preço Manual'
													placeholder='Informe o preço'
													cols='12 12 12 12'
													casas={4}
													readOnly='readOnly'
													value={FormatUtils.formatarValorTela(item.preco_manual, 4)}
													onChange={(data) => {
													}} />
											) : (
												<LabelAndInputNumber
													label='Preço Manual'
													placeholder='Informe o preço'
													cols='12 12 12 12'
													casas={4}
													value={this.state.preco_manual}
													onChange={(data) => {
														this.setState({ ...this.state, preco_manual: data.target.value })
													}} />
											)}
										</Grid>
										<Grid cols='6 6 4 2'>
											{item.id != this.state.idAlteracao ? (
												<LabelAndInputNumber
													label='Estratégia'
													placeholder='Informe a estratégia'
													cols='12 12 12 12'
													casas={2}
													readOnly='readOnly'
													value={FormatUtils.formatarValorTela(item.estrategia, 2)}
													onChange={(data) => {
													}} />
											) : (
												<LabelAndInputNumber
													label='Estratégia'
													placeholder='Informe a estratégia'
													cols='12 12 12 12'
													casas={2}
													readOnly={item.id != this.state.idAlteracao}
													value={this.state.estrategia}
													onChange={(data) => {
														this.setState({ ...this.state, estrategia: data.target.value })
													}} />
											)}
										</Grid>									
										<Grid cols='3 6 4 2'>
											<LabelAndInputNumber
												label='Política'
												placeholder='Informe a política'
												cols='12 12 12 12'
												casas={0}
												readOnly='readOnly'
												value={FormatUtils.formatarValorTela(item.politica, 0)}
												onChange={(data) => {
												}} />
										</Grid>
										<Grid cols='5 6 4 3'>
											<LabelAndInputNumber
												label='Preço'
												placeholder='Informe o preço'
												cols='12 12 12 12'
												casas={4}
												readOnly='readOnly'
												value={FormatUtils.formatarValorTela(item.preco, 4)}
												onChange={(data) => {
												}} />
										</Grid>
										<Grid cols='4 6 4 2' style={{ paddingTop: 32 }}>
											{!this.state.idAlteracao || item.id != this.state.idAlteracao ? (
												<ButtonTable
													type={'warning'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
													event={() => {
														this.setState({ 
															...this.state, 
															idAlteracao: item.id,
															id_distribuidora: item.id_distribuidora,
															estrategia: FormatUtils.formatarValorTela(item.estrategia, 2),
															preco_manual: FormatUtils.formatarValorTela(item.preco_manual, 4)
														});
													}} />
											) : (
												<>
													<ButtonTable
														type={'success'}
														icon={'fas fa-check'}
														visible={true}
														disabled={false}
														event={() => {
															this.props.salvar({ 
																...item,
																id_distribuidora: this.state.id_distribuidora,
																estrategia: this.state.estrategia,
																preco_manual: this.state.preco_manual
															});
															this.setState({ ...this.state, idAlteracao: null, id_distribuidora: null, estrategia: null, preco_manual: null });
														}} />
															
													<ButtonTable
														type={'danger'}
														icon={'fas fa-times'}
														visible={true}
														disabled={false}
														event={() => {
															this.setState({ ...this.state, idAlteracao: null, id_distribuidora: null, estrategia: null, preco_manual: null });
														}} />
												</>
											)}
										</Grid>
									</Row>
								</Td>
							</Tr>
						)
					})}
				</TBody>
			</Table>
		)
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.politicaPreco.modoTela,
	lista: state.politicaPreco.lista,
	listaDistribuidora: state.politicaPreco.listaDistribuidora,
	filtro: state.politicaPreco.filtro,
	aguardando: state.politicaPreco.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaDistribuidora }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PoliticaPreco);
