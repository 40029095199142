import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'MANUTENCAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('manutencaoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/manutencao`);
    return {
        type: 'MANUTENCAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/manutencao`, {
                ...registro,
                datahora_agendamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_agendamento),
                datahora_previsao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_previsao),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/manutencao`, {
                ...registro,
                datahora_agendamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_agendamento),
                datahora_entrada: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_entrada),
                datahora_previsao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_previsao),
                datahora_teste: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_teste),
                datahora_liberacao: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_liberacao),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/manutencao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/manutencaoTipo/listarSelect`);
    return {
        type: 'MANUTENCAO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaOficina() {
    const request = axios.get(`${consts.API_URL}/oficina/listarSelect`);
    return {
        type: 'MANUTENCAO_OFICINA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'MANUTENCAO_VEICULO_SELECT_LISTADO',
        payload: request
    };
}
