import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './veiculoForm';
import Select from 'react-select';
import SelectComponent from '../common/form/select';

import { 
	setModoTela, initForm, salvar, excluir, getListaDashboard, getListaTipo,
	getListaUnidade, getListaMotorista, getListaReboque, alterarConfiguracao,
	getListaEntradas, getListaSaidas, getListaTransferencias, getListaPosicaoVeiculo
} from './veiculoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';

class VeiculoDashboard extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getListaUnidade();
		this.props.getListaMotorista();
		this.props.getListaReboque();
        this.props.getListaDashboard();
		this.props.getListaEntradas();
		this.props.getListaSaidas();
		this.props.getListaTransferencias();
		this.props.getListaPosicaoVeiculo();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>	
				
				{this.state.info ? this.modalInfo() : null}

				<ContentCardHeader>

					<SelectComponent
						options={this.props.listaUnidade}
						label='Unidade'
						cols='12 12 12 12'
						placeholder='Selecione a unidade'
						value={this.state.id_unidade}
						onChange={value => this.setState({
							...this.state,
							id_unidade: value
						})} />
				</ContentCardHeader>

				<ContentCardBody>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter minWidth={10}>Placa</Th>
								<Th alignCenter minWidth={200}>Base</Th>
								<Th alignCenter minWidth={300}>Motorista</Th>
								<Th alignCenter minWidth={150}>Carreta 1</Th>
								<Th alignCenter minWidth={150}>Carreta 2</Th>
								{/*<Th colspan={1}>Modelo</Th>*/}
								{/*<Th alignCenter>Capacidade</Th>*/}
								<Th alignCenter minWidth={10}>Pos.</Th>
								<Th alignCenter minWidth={200}>Manutenção</Th>
								<Th alignCenter minWidth={180}>Em Deslocamento</Th>
								<Th alignCenter minWidth={180} colspan={4}>Agendadamento</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.id_unidade && item.id_unidade_conf != this.state.id_unidade) {
									return false;
								}
								if (this.state.pesquisar
									&& !(`${item.placa} ${item.modelo || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => {

								let transito = (item.itens || []).filter(viagem => viagem && viagem.status == 'TRANSITO')[0];
								let agendadas = (item.itens || []).filter(viagem => viagem && viagem.status == 'AGENDADA').map(agendada => ({
									...agendada,
									title: `${agendada.tipo} \n`
										+ `${agendada.nome_motorista || 'Sem Motorista'} \n`
										+ `${(agendada.produtos || []).map(produto => (
											` -> ${produto.nome_produto}: ${parseInt(produto.quantidade)} l`
										)).join('\n')} \n`
								}));
								
								const titleTransito = !transito ? null :
									`${transito.tipo} \n`
									+ `${transito.nome_motorista || 'Sem Motorista'} \n`
									+ `${(transito.produtos || []).map(produto => (
										` -> ${produto.nome_produto}: ${parseInt(produto.quantidade)} l`
									)).join('\n')} \n`;
								
								const titleManutencaoAgendada = !(item.manutencao_agendada && item.manutencao_agendada[0]) ? null :
									`Tipo: ${item.manutencao_agendada[0].nome_tipo} \n`
									+ `Oficina: ${item.manutencao_agendada[0].nome_oficina} \n`
									+ `Agenda: ${DateFormat.formatarDataHoraSqlParaTela(item.manutencao_agendada[0].datahora)} \n`;

								const titleManutencaoAberta = !(item.manutencao_aberta && item.manutencao_aberta[0]) ? null :
									`Tipo: ${item.manutencao_aberta[0].nome_tipo} \n`
									+ `Oficina: ${item.manutencao_aberta[0].nome_oficina} \n`
									+ `Início: ${DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta[0].datahora_entrada)} \n`
									+ `Previsão: ${DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta[0].datahora_previsao)} \n`;
									
								let posicao = this.props.listaPosicaoVeiculo.filter(posicao => posicao.placa == item.placa)[0];
								
								//CONFIGURAÇÃO
								return (
									<Tr key={item.id}>
										<Td minWidth={10}>{item.placa}</Td>								
										
										<Td alignCenter minWidth={200}>
											{this.props.listaUnidade.length > 0 &&
											<Select
												// readOnly={props.readOnly}
												defaultValue={(this.props.listaUnidade || []).filter(opt => opt.id == item.id_unidade_conf).map(opt => ({ value: opt.id, label: opt.valor }))[0]}
												isClearable={true}
												isSearchable={true}
												// isDisabled={props.readOnly ? true : false}
												onChange={retorno => this.props.alterarConfiguracao({
													...item,
													id_unidade_conf: retorno ? retorno.value : null
												})}
												options={(this.props.listaUnidade || []).map(opt => ({ value: opt.id, label: opt.valor }))} />}
										</Td>
										
										{!item.terceiro &&
										<Td alignCenter minWidth={300}>
											{this.props.listaMotorista.length > 0 &&										
											<Select
												// readOnly={props.readOnly}
												defaultValue={(this.props.listaMotorista || []).filter(opt => opt.id == item.id_motorista_conf).map(opt => ({ value: opt.id, label: opt.valor }))[0]}
												isClearable={true}
												isSearchable={true}
												// isDisabled={props.readOnly ? true : false}
												onChange={retorno => this.props.alterarConfiguracao({
													...item,
													id_motorista_conf: retorno ? retorno.value : null
												})}
												options={(this.props.listaMotorista || []).map(opt => ({ value: opt.id, label: opt.valor }))} />}
										</Td>}

										{!item.terceiro &&
										<Td alignCenter minWidth={150}>
											{(this.props.listaReboque.length > 0 && parseInt(item.carretas || 0) >= 1) &&
											<Select
												// readOnly={props.readOnly}
												defaultValue={(this.props.listaReboque || []).filter(opt => opt.id == item.id_carreta1_conf).map(opt => ({ value: opt.id, label: opt.valor }))[0]}
												isClearable={true}
												isSearchable={true}
												// isDisabled={props.readOnly ? true : false}
												onChange={retorno => this.props.alterarConfiguracao({
													...item,
													id_carreta1_conf: retorno ? retorno.value : null
												})}
												options={(this.props.listaReboque || []).map(opt => ({ value: opt.id, label: opt.valor.split('-')[0].trim() }))} />}
										</Td>}

										{!item.terceiro &&
										<Td alignCenter minWidth={150}>
											{(this.props.listaReboque.length > 0 && parseInt(item.carretas || 0) >= 2) &&
											<Select
												// readOnly={props.readOnly}
												defaultValue={(this.props.listaReboque || []).filter(opt => opt.id == item.id_carreta2_conf).map(opt => ({ value: opt.id, label: opt.valor }))[0]}
												isClearable={true}
												isSearchable={true}
												// isDisabled={props.readOnly ? true : false}
												onChange={retorno => this.props.alterarConfiguracao({
													...item,
													id_carreta2_conf: retorno ? retorno.value : null
												})}
												options={(this.props.listaReboque || []).map(opt => ({ value: opt.id, label: opt.valor.split('-')[0].trim() }))} />}
										</Td>}

										{item.terceiro &&
										<Td colspan={3} alignCenter minWidth={600}>
											VEÍCULO DE TERCEIRO
										</Td>}

										{/*<Td>{item.modelo}</Td>*/}
										{/*<Td alignCenter>
											<p style={{ margin: 0, fontWeight: 'bold' }}>{parseInt(item.capacidade)} l</p>
											<p style={{ margin: 0 }}>{(item.compartimentos || []).length ? `(${(item.compartimentos || []).map(compartimento => compartimento).join(' | ')})` : ''}</p>
										</Td>*/}
										<Td alignCenter minWidth={10}>{item.latitude && item.longitude ? (
											<ButtonTable
												type={'primary'}
												icon={'fas fa-map-marker-alt'}
												visible={true}
												title={posicao && posicao.telemetria ? posicao.telemetria.localizacao : null}
												event={() => {
													window.open(`https://www.google.com/maps/place/${item.latitude},${item.longitude}`, '_blank');
												}} />
											) : null}
										</Td>
										{/*<Td alignCenter backgroundColor='#faea8e' bold title={titleManutencaoAgendada} onClick={() => this.setDialogInfo(titleManutencaoAgendada)} >
											{(item.manutencao_agendada && item.manutencao_agendada[0]) &&
											<p style={{ margin: 0 }}>Tipo: {item.manutencao_agendada[0].nome_tipo}</p>}

											{(item.manutencao_agendada && item.manutencao_agendada[0]) &&
											<p style={{ margin: 0 }}>Agenda: {DateFormat.formatarDataHoraSqlParaTela(item.manutencao_agendada[0].datahora)}</p>}
										</Td>*/}
										<Td alignCenter minWidth={200} bold title={titleManutencaoAberta} onClick={() => this.setDialogInfo(titleManutencaoAberta)} >
											{(item.manutencao_aberta && item.manutencao_aberta[0]) &&
											<div 
												style={{ 
													backgroundColor: '#cf4130', 
													color: '#ffffff', 
													borderRadius: 10, 
													margin: 2, 
													padding: 4, 
													minHeight: 95, 
													display: 'flex', 
													flexDirection: 'column',
													justifyContent: 'center' 
												}}>
												{(item.manutencao_aberta && item.manutencao_aberta[0]) &&
												<p style={{ margin: 0 }}>Tipo: {item.manutencao_aberta[0].nome_tipo}</p>}
												
												{(item.manutencao_aberta && item.manutencao_aberta[0]) &&
												<p style={{ margin: 0 }}>Início: {DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta[0].datahora_entrada)}</p>}
												
												{(item.manutencao_aberta && item.manutencao_aberta[0]) &&
												<p style={{ margin: 0 }}>Previsão: {DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta[0].datahora_previsao)}</p>}
											</div>}
										</Td>
										<Td alignCenter minWidth={180} bold title={titleTransito} onClick={() => this.setDialogInfo(titleTransito)} >
											{(transito && transito.tipo) &&
											<div
												style={{ 
													backgroundColor: '#4ba654', 
													color: '#ffffff', 
													borderRadius: 10, 
													margin: 2, 
													padding: 4, 
													minHeight: 95, 
													display: 'flex', 
													flexDirection: 'column',
													justifyContent: 'center' 
												}}>
												{transito.tipo &&
												<p style={{ margin: 0 }}>{transito.tipo}</p>}

												{transito.datahora &&
												<p style={{ margin: 0 }}>{DateFormat.formatarDataHoraSqlParaTela(transito.datahora)}</p>}

												{transito.codigo &&
												<p style={{ margin: 0 }}>{`Giro: ${transito.codigo}`}</p>}
												
												{transito.percentual_ida < 100 ? (
													<div style={{ paddingLeft: 4, paddingBottom: 4, paddingRight: 4 }}>
														<div class="progress" style={{ borderRadius: 10, padding: 1, position: 'relative', background: '#a3c2d9' }}> 
															<div class="progress-bar" style={{ borderRadius: 8, width: `${transito.percentual_ida}%`, background: '#147cc7' }}></div>
															<div style={{ position: 'absolute', width: '100%', marginTop: -2 }}>IDA: {transito.percentual_ida}%</div>
														</div>
													</div>
												) : (
													<div style={{ paddingLeft: 4, paddingBottom: 4, paddingRight: 4 }}>
														<div class="progress" style={{ borderRadius: 10, padding: 1, position: 'relative', background: '#a3c2d9' }}> 
															<div class="progress-bar" style={{ borderRadius: 8, width: `${transito.percentual_volta}%`, background: '#147cc7' }}></div>
															<div style={{ position: 'absolute', width: '100%', marginTop: -2 }}>VOLTA: {transito.percentual_volta}%</div>
														</div>
													</div>
												)}
											</div>}
										</Td>
										<Td alignCenter minWidth={180} bold>
											{agendadas.map(agendada => (
												<div key={agendada.giro}
													title={agendada.title} onClick={() => this.setDialogInfo(agendada.title)}
													style={{ 
														backgroundColor: '#4096db', 
														color: '#ffffff', 
														borderRadius: 10, 
														margin: 2, 
														padding: 4, 
														minHeight: 95, 
														display: 'flex', 
														flexDirection: 'column',
														justifyContent: 'center' 
													}}>
													{agendada.tipo &&
													<p style={{ margin: 0 }}>{agendada.tipo}</p>}

													{agendada.datahora &&
													<p style={{ margin: 0 }}>{DateFormat.formatarDataHoraSqlParaTela(agendada.datahora)}</p>}
													
													{agendada.codigo &&
													<p style={{ margin: 0 }}>{`Giro: ${agendada.codigo}`}</p>}
												</div>
											))}
										</Td>
										{/*<Td alignCenter minWidth={180} backgroundColor={(agendada2 && agendada2.tipo) ? '#8db0cc' : null} bold title={titleAgendada2} onClick={() => this.setDialogInfo(titleAgendada2)} >
											{(agendada2 && agendada2.tipo) &&
											<p style={{ margin: 0 }}>{agendada2.tipo}</p>}

											{(agendada2 && agendada2.datahora) &&
											<p style={{ margin: 0 }}>{DateFormat.formatarDataHoraSqlParaTela(agendada2.datahora)}</p>}
											
											{(agendada2 && agendada2.codigo) &&
											<p style={{ margin: 0 }}>{`Giro: ${agendada2.codigo}`}</p>}
										</Td>
										<Td alignCenter minWidth={180} backgroundColor={(agendada3 && agendada3.tipo) ? '#a1cdf0' : null} bold title={titleAgendada3} onClick={() => this.setDialogInfo(titleAgendada3)} >
											{(agendada3 && agendada3.tipo) &&
											<p style={{ margin: 0 }}>{agendada3.tipo}</p>}

											{(agendada3 && agendada3.datahora) &&
											<p style={{ margin: 0 }}>{DateFormat.formatarDataHoraSqlParaTela(agendada3.datahora)}</p>}
											
											{(agendada3 && agendada3.codigo) &&
											<p style={{ margin: 0 }}>{`Giro: ${agendada3.codigo}`}</p>}
										</Td>
										<Td alignCenter minWidth={180} backgroundColor={(agendada4 && agendada4.tipo) ? '#8db0cc' : null} bold title={titleAgendada4} onClick={() => this.setDialogInfo(titleAgendada4)} >
											{(agendada4 && agendada4.tipo) &&
											<p style={{ margin: 0 }}>{agendada4.tipo}</p>}

											{(agendada4 && agendada4.datahora) &&
											<p style={{ margin: 0 }}>{DateFormat.formatarDataHoraSqlParaTela(agendada4.datahora)}</p>}
											
											{(agendada4 && agendada4.codigo) &&
											<p style={{ margin: 0 }}>{`Giro: ${agendada4.codigo}`}</p>}
										</Td>*/}
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	setDialogInfo(info) {
		this.setState({
			...this.state,
			info: info
		})
	}
	
	modalInfo() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogInfo(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								<Grid cols='12 12 12 12'>
									{(this.state.info || '').split('\n').map(item => (
										<p key={item}>{item}</p>
									))}	
								</Grid>							
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setDialogInfo(null);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.veiculo,
	modoTela: state.veiculo.modoTela,
	aguardando: state.veiculo.aguardando,
	lista: state.veiculo.listaDashboard,
	listaEntrada: state.veiculo.listaEntrada,
	listaSaida: state.veiculo.listaSaida,
	listaTransferencia: state.veiculo.listaTransferencia,
	listaUnidade: state.veiculo.listaUnidade,
	listaMotorista: state.veiculo.listaMotorista,
	listaReboque: state.veiculo.listaReboque,
	listaPosicaoVeiculo: state.veiculo.listaPosicaoVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getListaDashboard, getListaTipo,
	getListaUnidade, getListaMotorista, getListaReboque, alterarConfiguracao,
	getListaEntradas, getListaSaidas, getListaTransferencias, getListaPosicaoVeiculo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoDashboard);
