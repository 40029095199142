import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ButtonTable from '../common/table/buttonTable';
import Map from '../common/map/mapRota';

import {
	setModoTela, initForm, salvar, imprimir
} from './pedidoActions';
import DateFormat from '../common/dateFormat/DateFormat';

class PedidoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = (this.props.formularioValues.id && 
				this.props.formularioValues.id_status != 1 && this.props.formularioValues.id_status != 2 
				&& this.props.formularioValues.id_status != 4) || this.props.excluir ? 'readOnly' : '';
		
		let administrador = this.props.usuarioCarregado ? this.props.usuarioCarregado.id_perfil == 2 : false;
		

		let valorTotalBaseGeral = 0;
		let valorTotalGeral = 0;
		let quantidadeGeral = 0;
		let lucroTotalGeral = 0;
		let valorFreteGeral = 0;
		let quantidadeS10 = 0;
		let quantidadeS500 = 0;
		let precoUnitarioS10 = 0;
		let precoUnitarioS500 = 0;
		let lucroBrutoGeral = 0;
		
		let veiculo = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];

		let custoOperacaoGeral = parseFloat(this.props.formularioValues.custo_operacao || 0);

		(this.props.formularioValues.itens || []).filter(item => item.ativo).forEach((item, i) => {

			//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO
			if (item.id_produto == 1 || item.id_produto == 3) {
							
				valorTotalBaseGeral += parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4))
					* parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
				valorTotalGeral += parseFloat(FormatUtils.formatarValorBanco(item.valor_total, 2));
				quantidadeGeral += parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
				
				let precoUnitario = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario, 4));
				let precoUnitarioBase = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4));
				
				let margemBruta = precoUnitario - precoUnitarioBase;
				lucroBrutoGeral += parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2)) * margemBruta;
			
				valorFreteGeral += parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4)) * parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
				lucroTotalGeral += parseFloat((parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2)) * parseFloat((parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario, 4)) - parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4))).toFixed(4))).toFixed(2));
				
			}

			//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO
			if (item.id_produto == 1) {
				quantidadeS10 += parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
			} else if (item.id_produto == 3) {
				quantidadeS500 += parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
			}

			//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO
			if (item.id_produto == 1) {
				precoUnitarioS10 = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4))
					+ parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4));
			} else {
				precoUnitarioS500 = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4))
				+ parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4));
			}
		});

		let margemMediaGeral = lucroBrutoGeral / quantidadeGeral;

		//Calcula o Custo de Operação Geral com base no custo do S10 e S500 com a quantidade de cada um
		// let custoOperacaoS10 = quantidadeGeral > 0 ? parseFloat(((parseFloat(this.props.formularioValues.custo_operacao_s10) * quantidadeS10) / quantidadeGeral).toFixed(4)) : 0;
		// let custoOperacaoS500 = quantidadeGeral > 0 ? parseFloat(((parseFloat(this.props.formularioValues.custo_operacao_s500) * quantidadeS500) / quantidadeGeral).toFixed(4)) : 0;
		
		// if (parseFloat(this.props.formularioValues.custo_operacao) != (custoOperacaoS10 + custoOperacaoS500)) {
		// 	this.props.initForm({
		// 		...this.props.formularioValues,
		// 		custo_operacao: custoOperacaoS10 + custoOperacaoS500
		// 	});
		// }

		//Calcula o custo do frete por litro
		let custoFreteGeral = quantidadeGeral ? parseFloat((valorFreteGeral / quantidadeGeral).toFixed(4)) : 0;

		let custoEntregaGeral = parseFloat((quantidadeGeral > 0 ? (parseFloat(this.props.formularioValues.custo_km) * parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.distancia, 0))) / quantidadeGeral : 0).toFixed(4));
		
		let margemMinimaGeral = custoOperacaoGeral + custoFreteGeral + custoEntregaGeral;
		let margemLiquidaGeral = margemMediaGeral - custoOperacaoGeral - custoFreteGeral - custoEntregaGeral;

		let lucroLiquidoGeral = parseFloat((quantidadeGeral * margemLiquidaGeral).toFixed(2));

		//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO
		if (!precoUnitarioS10) {
			let politicaCadastradaS10 = this.props.listaPoliticaPreco.filter(politica => 
				politica.id_unidade == this.props.formularioValues.id_unidade
				&& politica.id_produto == 1)[0]
			
			precoUnitarioS10 = politicaCadastradaS10 ? politicaCadastradaS10.preco : 0;
		}

		if (!precoUnitarioS500) {
			let politicaCadastradaS500 = this.props.listaPoliticaPreco.filter(politica => 
				politica.id_unidade == this.props.formularioValues.id_unidade
				&& politica.id_produto == 3)[0]
			
			precoUnitarioS500 = politicaCadastradaS500 ? politicaCadastradaS500.preco : 0;
		}

		let corMargem = '';
		if (quantidadeS500 + quantidadeS10 > 0) {
			if (margemLiquidaGeral <= 0) {
				corMargem = '#f55656';
			} else if (margemLiquidaGeral * 1.02 <= margemMinimaGeral) {
				corMargem = '#ded649';
			} else {
				corMargem = '#6bbd4d';
			}
		}

		// 1 - Digitando
		// 2 - Aguardando Aprovação
		// 3 - Rejeitado
		// 4 - Aprovado
		// 5 - Logística Parcial
		// 6 - Logística Finalizada
		// 7 - Entrega

		let acessoLogistica = !this.props.visualizacao && (this.props.formularioValues.id_status == 4 || this.props.formularioValues.id_status == 5 ||
			this.props.formularioValues.id_status == 6 || this.props.formularioValues.id_status == 7);

		let itensMapa = (this.props.formularioValues.itens || []).filter(item => item.ativo).map(item => ({
			...item,
			endereco: this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0]
		}));

		let unidadeSelecionada = this.props.listaUnidade.filter(item => item.id == this.props.formularioValues.id_unidade)[0];

		let compartimentos = (veiculo ? veiculo.compartimentos || [] : []).map((compartimento, i) => ({
			id: i+1,
			valor: `Compartimento ${i+1}`,
			capacidade: parseFloat(compartimento),
			tamanho: veiculo.capacidade ? `${parseInt((compartimento * 100) / parseInt(veiculo.capacidade))}%` : 100,
			ocupacao: 0
		}));

		let compartimentosOcupados = [];

		for (let i = 0; i < (this.props.formularioValues.itens || []).filter(item => item.ativo).length; i++) {
			const item = (this.props.formularioValues.itens || []).filter(item => item.ativo)[i];

			compartimentosOcupados = compartimentosOcupados.concat(item.compartimentos || []);
			
			let volume = parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));

			compartimentos = compartimentos.map(compartimento => {
				
				let disponivel = compartimento.capacidade - compartimento.ocupacao;
					
				if (!disponivel || !volume 
					|| !(item.compartimentos || []).includes(compartimento.id)) {
					return compartimento;
				} else {

					let registro = {
						...compartimento,
						id_produto: item.id_produto,
						ocupacao: compartimento.ocupacao + (volume <= disponivel ? volume : disponivel)
					}

					if (volume <= disponivel) {
						volume = 0;
					} else {
						volume -= disponivel;
					}

					return registro;
				}
			});

		}

		let alertas = [];
		if ((this.props.formularioValues.itens || []).filter(item => item.ativo).length > 1) {
			const item = (this.props.formularioValues.itens || []).filter(item => item.ativo)[(this.props.formularioValues.itens || []).filter(item => item.ativo).length - 1];
			const endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];
			if (endereco && endereco.serra) {
				alertas.push('Cliente na serra não pode estar no último compartimento')
			}
		}

		(this.props.formularioValues.itens || []).filter(item => item.ativo).forEach((item, i) => {
			const veiculo = this.props.listaVeiculo.filter(item => item.id == this.props.formularioValues.id_veiculo)[0];
			const endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];

			if (veiculo && endereco && (veiculo.veiculo_tipos || []).length > 0 && endereco.veiculo_tipos.filter(tipo => tipo == veiculo.id_tipo).length == 0) {
				alertas.push(`Este veículo não pode entregar para ${endereco.valor}`)
			}

		});


		(this.props.formularioValues.itens || []).filter(item => item.ativo).forEach((item, i) => {
			const endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];

			if (endereco && endereco.alerta_entrega) {
				alertas.push(endereco.alerta_entrega);
			}

		});

		let codigoVendedor = this.props.usuarioCarregado ? this.props.usuarioCarregado.codigo : null;

		return (
			<ContentCard>

				{this.state.itemVendaPerdida ? this.modalVendaPerdidaItem() : null}
				{this.state.modalVendaPerdida ? this.modalVendaPerdida() : null}

				<ContentCardHeader>
					<Row>
						{this.props.formularioValues.id_status == 1 &&
							<Grid cols='6 6 4 2'>
								<Button
									text='Enviar para Aprovação'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({
											...this.props.formularioValues,
											id_status: 2
										});
									}} />
							</Grid>
						}
						
						{this.props.formularioValues.id_status == 2 && 
							<Grid cols='6 6 4 2'>
								<Button
									text='Aprovar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({
											...this.props.formularioValues,
											id_status: 4,
											id_usuario_aprovacao_rejeicao: this.props.sessao.id,
											datahora_aprovacao_rejeicao: DateFormat.getDataHoraAtual()
										});
									}} />
							</Grid>
						}
						{this.props.formularioValues.id_status == 2 && 
							<Grid cols='6 6 4 2'>
								<Button
									text='Rejeitar'
									type={'danger'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({
											...this.props.formularioValues,
											id_status: 3,
											id_usuario_aprovacao_rejeicao: this.props.sessao.id,
											datahora_aprovacao_rejeicao: DateFormat.getDataHoraAtual()
										});
									}} />
							</Grid>
						}

						{(this.props.formularioValues.id && this.props.formularioValues.id_status == 1) && 
							<Grid cols='6 6 4 2'>
								<Button
									text='Venda Perdida'
									type={'danger'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.initForm({
											...this.props.formularioValues,
											concorrente: '',
											preco_concorrente_s10: null,
											preco_concorrente_s500: null
										});
										this.setState({ ...this.state, modalVendaPerdida: true });
									}} />
							</Grid>
						}

						{(acessoLogistica || (administrador && (this.props.formularioValues.id_status == 3 || this.props.formularioValues.id_status == 7))) &&
							<Select
								options={this.props.listaStatus}
								label='Status'
								cols='12 12 12 12'
								placeholder='Selecione o status'
								readOnly={null}
								value={this.props.formularioValues.id_status}
								onChange={data => {
									if (data) {
										let registro = { 
											...this.props.formularioValues,
											id_status: data
										};
										//Digitando - Reabrir
										if (data == 1) {
											registro = {
												...registro,
												id_usuario_aprovacao_rejeicao: null,
												id_usuario_logistica: null,
												id_usuario_entrega: null,
												datahora_aprovacao_rejeicao: null,
												datahora_logistica: null,
												datahora_entrega: null
											}
										}
										//Aprovado
										if (data == 4) {
											registro = {
												...registro,
												id_usuario_logistica: null,
												id_usuario_entrega: null,
												datahora_logistica: null,
												datahora_entrega: null
											}
										}
										//Logística Parcial
										if (data == 5) {
											registro = {
												...registro,
												id_usuario_logistica: null,
												id_usuario_entrega: null,
												datahora_logistica: null,
												datahora_entrega: null
											}
										}
										//Logística Finalizada
										if (data == 6) {
											registro = {
												...registro,
												id_usuario_logistica: this.props.sessao.id,
												id_usuario_entrega: null,
												datahora_logistica: DateFormat.getDataHoraAtual(),
												datahora_entrega: null
											}
										}
										//Entrega
										if (data == 7) {
											registro = {
												...registro,
												id_usuario_entrega: this.props.sessao.id,
												datahora_entrega: DateFormat.getDataHoraAtual()
											}
										}
										this.props.salvar(registro);
									}
							}} />
						}
					</Row>
				</ContentCardHeader>
				<Form event={this.props.handleSubmit}>
					{!this.props.modoLogistica ? (
						<ContentCardBody>
							{(this.props.formularioValues.id_status == 5 
								|| this.props.formularioValues.id_status == 6
								|| this.props.formularioValues.id_status == 7) && /*Logística Parcial, Logística Finalizada, Finalizado Base*/
							<Row>
								
								<Field
									name='retirado_base'
									component={LabelAndCheckbox}
									label='Retirado na Base' placeholder='Informe'
									cols='12 6 4 3'/>

								{!this.props.formularioValues.retirado_base &&
								<Field
									name='codigo_sm_entrega'
									component={LabelAndInputNumber}
									label='Código SM Ida' placeholder='Informe o Código SM Ida'
									cols='12 6 4 3'
									casas={0} />}
								
								{!this.props.formularioValues.retirado_base &&								
								<Field
									name='codigo_sm_retorno'
									component={LabelAndInputNumber}
									label='Código SM Volta' placeholder='Informe o Código SM Volta'
									cols='12 6 4 3'
									casas={0} />}

							</Row>}

							<Row>
								<Grid cols='12 6 6 6'>
									<Row>
										<Select
											name='id_unidade'
											options={this.props.listaUnidade}
											label='Unidade'
											cols='12 10 10 10'
											placeholder='Selecione o unidade'
											readOnly={readOnly}
											value={this.props.formularioValues.id_unidade}
											onChange={data => {
												//1 - OLEO DIESEL B S10 ADITIVA
												let politicaS10 = this.props.listaPoliticaPreco.filter(item => item.id_unidade == data && item.id_produto == 1)[0];
												let distribuidoraS10 = politicaS10 ? this.props.listaDistribuidora.filter(item => item.id == politicaS10.id_distribuidora)[0] : null;

												//3 - OLEO DIESEL B S500 ADITIVADO
												let politicaS500 = this.props.listaPoliticaPreco.filter(item => item.id_unidade == data && item.id_produto == 3)[0];
												let distribuidoraS500 = politicaS500 ? this.props.listaDistribuidora.filter(item => item.id == politicaS500.id_distribuidora)[0] : null;

												let itens = (this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
								
													//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO
													
													let politicaSelecionada = this.props.listaPoliticaPreco.filter(politica => 
														politica.id_unidade == data
														&& politica.id_produto == item.id_produto)[0];
														
													return {
														...item,
														preco_unitario_bruto: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_bruto, 4) : null,
														preco_unitario_base: politicaSelecionada ? FormatUtils.formatarValorTela(parseFloat(politicaSelecionada.preco) - parseFloat(politicaSelecionada.preco_frete), 4) : null,
														// preco_unitario: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco, 4) : 0,
														preco_unitario_frete: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_frete, 4) : null,
													};
												});

												this.props.initForm({
													...this.props.formularioValues,
													id_unidade: data,
													id_distribuidora_s10: distribuidoraS10 ? distribuidoraS10.id : null,
													id_distribuidora_s500: distribuidoraS500 ? distribuidoraS500.id : null,
													custo_operacao: this.props.listaUnidade.filter(item => item.id == data)[0] ? this.props.listaUnidade.filter(item => item.id == data)[0].custo_operacao || 0 : 0,
													// custo_operacao_s10: distribuidoraS10 ? distribuidoraS10.custo_operacao || 0 : 0,
													// custo_operacao_s500: distribuidoraS500 ? distribuidoraS500.custo_operacao || 0 : 0
													itens: itens
												}, true);

											}} />

										{this.props.formularioValues.id ? (
											<Field
												name='giro'
												component={LabelAndInputNumber}
												label='Giro' placeholder='Informe o giro'
												cols='12 2 2 2'
												casas={0}
												readOnly={'readOnly'} />
										) : null}

										<Select
											name='id_veiculo'
											options={this.props.listaVeiculo}
											label='Veículo'
											cols='12 8 8 8'
											placeholder='Selecione o veículo'
											readOnly={readOnly}
											value={this.props.formularioValues.id_veiculo}
											onChange={data => {
												let veiculo = this.props.listaVeiculo.filter(item => item.id == data)[0];									
												this.props.initForm({
													...this.props.formularioValues,
													id_veiculo: data,
													custo_km: veiculo ? veiculo.preco_km || 0 : 0
												});
											}} />
									
										<LabelAndInput
											label='Custo Km' 
											placeholder=''
											cols='12 4 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(this.props.formularioValues.custo_km, 4)}
											onChange={data => {										
															
											}} />
											
										{(veiculo && veiculo.documentacao_vencida) && (
											<Grid cols='12 12 12 12'>
												<div class="alert alert-danger alert-dismissible">
													<h6><i class="icon fas fa-ban"></i> Veículo possui problemas na documentação!</h6>
												</div>
											</Grid>
										)}

										<LabelAndInput
											label='Capacidade Veículo' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(veiculo ? veiculo.capacidade : 0, 2)}
											onChange={data => {										
															
											}} />
											
										<Field
											name='distancia'
											component={LabelAndInputNumber}
											label='Distância (Km)' placeholder='Informe a distância'
											cols='12 12 4 4'
											casas={0}
											readOnly={readOnly} />

										<LabelAndInput
											label='Quantidade p/ Completar' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											backgroundColor={parseFloat(veiculo ? veiculo.capacidade : 0) - quantidadeGeral < 0 ? '#f55656' : null}
											value={FormatUtils.formatarValorTelaDecimal(parseFloat(veiculo ? veiculo.capacidade : 0) - quantidadeGeral, 2)}
											onChange={data => {										
															
											}} /> 
											
										<Field
											name='rota_link'
											component={LabelAndInput}
											label='Link da Rota' placeholder='Informe o link'
											cols='12 12 12 12'
											readOnly={readOnly} />
										
										{this.props.formularioValues.id_status == 4 || this.props.formularioValues.id_status == 5 || this.props.formularioValues.id_status == 6 || this.props.formularioValues.id_status == 7 ? (
											<Field
												name='id_motorista'
												component={Select}
												options={this.props.listaMotorista}
												label='Motorista'
												cols='12 12 12 12'
												placeholder='Selecione o motorista'
												readOnly={acessoLogistica ? null : readOnly} />	
										) : null}

										{(this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
								
											let cliente = this.props.listaCliente.filter(cliente => cliente.id == item.id_cliente)[0];
											let endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];

											if (endereco && cliente && endereco.croqui) {
												return (
													<LabelAndInput
														name='croqui'
														component={LabelAndInput}
														label={`Croqui ${cliente.valor}`} placeholder='Croqui'
														cols='12 12 12 12'
														readOnly={'readOnly'}
														value={endereco.croqui}
														onChange={() => {}} />
												);
											}

										})}
										
										<Grid cols='12 12 12 12'>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												{compartimentos.map((compartimento, i) => {

													return (
														<div key={compartimento.id} 
															style={{ 
																width: compartimento.tamanho, 
																margin: 2,
																textAlign: 'center',
																fontWeight: 'bold'
															}}>
															{i+1} - {compartimento.capacidade} L
														</div>
													);
												})}
											</div>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												{compartimentos.map(compartimento => {

													return (
														<div key={compartimento.id} 
															style={{ 
																backgroundColor: '#e3e3e3', 
																height: 80, 
																width: compartimento.tamanho, 
																margin: 2,
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'end',
																borderRadius: 5,
															}}>
															{/*
																1 - OLEO DIESEL B S10 ADITIVA
																3 - OLEO DIESEL B S500 ADITIVADO
															*/}
															<div 
																style={{
																	backgroundColor: compartimento.id_produto == 1 ? '#FFFDD0' : (compartimento.id_produto == 3 ? '#f2c2b8' : '#36d184') /*parseFloat(veiculo ? veiculo.capacidade : 0) - quantidadeGeral < 0 ? '#f55656' : '#36d184'*/, 
																	height: compartimento.capacidade ? (compartimento.ocupacao * 80) / compartimento.capacidade : 0, 
																	width: '100%', 
																	margin: 2,
																	borderRadius: 6,
																	textAlign: 'center'
																}}>
																<div 
																	style={(compartimento.capacidade ? (compartimento.ocupacao * 80) / compartimento.capacidade : 0) < 20 
																		? { position: 'relative', top: -20 } 
																		: {}
																	} >
																	{FormatUtils.formatarValorTelaDecimal(compartimento.ocupacao,0)} L
																</div>
															</div>
														</div>
													);
												})}
											</div>

											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 5, paddingRight: 5, marginBottom: 8 }}>
												<div>Dianteira</div>
												<div>Traseira</div>												
											</div>

											{alertas.map(alerta => (
												<div class='alert alert-warning alert-dismissible' style={{ paddingTop: 20 }}>
													<h5 style={{ fontSize: 12 }}><i class='icon fas fa-exclamation-triangle'></i> {alerta}</h5>
												</div>
											))}
										</Grid>
						
									</Row>
								</Grid>

								<Grid cols='12 6 6 6'>
									<Row style={{ backgroundColor: '#FFFDD0' }}>
										<Field
											name='id_distribuidora_s10'
											component={SelectDefault}
											options={this.props.listaDistribuidora}
											label='Distribuidora S10'
											cols='12 8 8 8'
											placeholder='Selecione a distribuidora'
											readOnly={'readOnly'} />

										<LabelAndInput
											label='Preço S10' 
											placeholder=''
											cols='12 4 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(parseFloat(precoUnitarioS10 || 0) + parseFloat(this.props.formularioValues.custo_operacao || 0), 4)}
											onChange={data => {										
															
											}} />
									</Row> 
									<Row style={{ backgroundColor: '#f2c2b8' }}>
										<Field
											name='id_distribuidora_s500'
											component={SelectDefault}
											options={this.props.listaDistribuidora}
											label='Distribuidora S500'
											cols='12 8 8 8'
											placeholder='Selecione a distribuidora'
											readOnly={'readOnly'} />
											
										<LabelAndInput
											label='Preço S500' 
											placeholder=''
											cols='12 4 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(parseFloat(precoUnitarioS500 || 0) + parseFloat(this.props.formularioValues.custo_operacao || 0), 4)}
											onChange={data => {										
															
											}} />
									</Row> 
									<Row>									

										<LabelAndInput
											label='Custo Operação' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(custoOperacaoGeral, 4)}
											onChange={data => {										
															
											}} />

										<LabelAndInput
											label='Custo Frete' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(custoFreteGeral, 4)}
											onChange={data => {										
															
											}} />

										<LabelAndInput
											label='Custo Entrega (Km)' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(custoEntregaGeral, 4)}
											onChange={data => {										
															
											}} />

										<LabelAndInput
											label='Margem Mínima' 
											placeholder=''
											cols='12 12 6 6'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(margemMinimaGeral, 4)}
											onChange={data => {										
															
											}} />
										
										<LabelAndInput
											label='Margem Média' 
											placeholder=''
											cols='12 12 6 6'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(margemMediaGeral, 4)}
											onChange={data => {										
															
											}} />

									</Row>
									<Row style={{ backgroundColor: corMargem }}>
										<LabelAndInput
											label='Margem Líquida' 
											placeholder=''
											cols='12 12 6 6'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTela(margemLiquidaGeral, 4)}
											onChange={data => {										
															
											}} />
										
										<LabelAndInput
											label='Lucro Líquido' 
											placeholder=''
											cols='12 12 6 6'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(lucroLiquidoGeral, 2)}
											onChange={data => {										
															
											}} />

										{/* 
										
										<LabelAndInput
											label='Quantidade Total' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(quantidadeGeral, 2)}
											onChange={data => {										
															
											}} />

										<LabelAndInput
											label='Quantidade p/ Completar' 
											placeholder=''
											cols='12 12 4 4'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(parseFloat(veiculo ? veiculo.capacidade : 0) - quantidadeGeral, 2)}
											onChange={data => {										
															
											}} /> 

										<LabelAndInput
											label='Valor Total' 
											placeholder=''
											cols='12 12 12 12'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(valorTotalGeral, 2)}
											onChange={data => {										
															
											}} />
											
										<LabelAndInput
											label='Custo Total' 
											placeholder=''
											cols='12 12 12 12'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(valorTotalGeral - lucroLiquidoGeral, 2)}
											onChange={data => {										
															
											}} />

										<LabelAndInput
											label='Lucro Bruto' 
											placeholder=''
											cols='12 12 12 12'
											readOnly={'readOnly'}
											value={FormatUtils.formatarValorTelaDecimal(lucroTotalGeral, 2)}
											onChange={data => {										
															
											}} />
											
										*/}
									</Row>
								</Grid>
							</Row>
								
							<br />

							<Row>

								{this.props.formularioValues.id_distribuidora_s10 && this.props.formularioValues.id_distribuidora_s500 ? (								
									<Grid cols='6 6 4 2'>
										<Button
											text='Adicionar Item'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={this.props.formularioValues.id && this.props.formularioValues.id_status != 1 && this.props.formularioValues.id_status != 2}
											event={() => {
												let lista = (this.props.formularioValues.itens || []).map(item => item);
												lista.push({ idAux: new Date().getTime(), ativo: true, ordem: lista.length + 1 });
												this.props.initForm({
													...this.props.formularioValues,
													itens: lista
												});
											}} />
									</Grid>
								) : null}
								
							</Row>

							{(this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
								
								let valorTotalBase = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4))
									* parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));

								let percentualBase = parseFloat(parseFloat(FormatUtils.formatarValorBanco(item.valor_total, 2)) > 0 ? 100 - ((valorTotalBase * 100) / parseFloat(FormatUtils.formatarValorBanco(item.valor_total, 2)))
									: 0).toFixed(2);

								let precoUnitario = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario, 4));
								let precoUnitarioBase = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4));
								let precoUnitarioFrete = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4));
								let precoUnitarioPrazo = parseFloat(FormatUtils.formatarValorBanco(item.preco_prazo, 4));

								let margemBruta = precoUnitario - precoUnitarioBase;

								let endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];

								//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO

								return (
									<Row key={item.id || item.idAux} 
										style={{ paddingTop: 8, marginTop: 8, backgroundColor: item.id_produto == 1 ? '#FFFDD0' : (item.id_produto == 3 ? '#f2c2b8' : null) }}>
										<Grid cols='10 10 11 11'>									
											<Row>
												{!item.expandido &&
												<Select
													options={[{ id: 1, valor: 'Sim' }, { id: 2, valor: 'Não' }]}
													cols='12 2 1 1'
													label={'Confirmado'}
													placeholder='Selecione'
													value={item.confirmado ? 1 : 2}
													onChange={(data) => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		confirmado: data == 1
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />}

												<Select
													options={this.props.listaCliente}
													cols='12 6 3 3'
													label={'Cliente'}
													placeholder='Selecione o cliente'
													value={item.id_cliente}
													onChange={(data) => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		id_cliente: data
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />
												
												{!item.expandido &&
												<Select
													options={this.props.listaEndereco.filter(endereco => endereco.id_cliente == item.id_cliente)}
													cols='12 6 3 3'
													label={'Endereço'}
													placeholder='Selecione o endereço'
													value={item.id_endereco}
													onChange={(data) => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		id_endereco: data
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />}
												
												<Select
													options={this.props.listaProduto}
													cols='12 6 3 3'
													label={'Produto'}
													placeholder='Selecione o produto'
													value={item.id_produto}
													onChange={(data) => {

														let politicaSelecionada = this.props.listaPoliticaPreco.filter(politica => 
															politica.id_unidade == this.props.formularioValues.id_unidade
															&& politica.id_produto == data)[0];


														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		id_produto: data,
																		preco_unitario_bruto: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_bruto, 4) : null,
																		preco_unitario_base: politicaSelecionada ? FormatUtils.formatarValorTela(parseFloat(politicaSelecionada.preco) - parseFloat(politicaSelecionada.preco_frete), 4) : null,
																		preco_unitario: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco, 4) : 0,
																		preco_unitario_frete: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_frete, 4) : null,
																	}
																} else {
																	return itemAux;
																}
															})
														}, true);
													}} />

												{!item.expandido && 
												<LabelAndInputNumber
													label='Última Venda' 
													placeholder=''
													cols='12 6 2 2'
													casas={4}
													readOnly={'readOnly'}
													value={endereco ? (item.id_produto == 1 && endereco.ultimo_preco_s10
														? FormatUtils.formatarValorTela(endereco.ultimo_preco_s10, 4) 
															: (item.id_produto == 3 && endereco.ultimo_preco_s500 
																? FormatUtils.formatarValorTela(endereco.ultimo_preco_s500, 4) : '') 
														) : ''}
													onChange={data => {
													}} />}

												<LabelAndInputNumber
													label={!item.expandido ? `Quantidade` 
													: `Quantidade - Compart: ${compartimentos.filter(compartimento => 
														(item.compartimentos || []).includes(compartimento.id)
													).map(compartimento => compartimento.valor.replace('Compartimento ', '')).join(' e ') || 'Nenhum'}`}
													placeholder='Informe o quantidade'
													cols='12 6 2 2'
													casas={2}
													readOnly={readOnly}
													value={item.quantidade}
													onChange={data => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		quantidade: data.target.value
																	}
																} else {
																	return itemAux;
																}
															})
														}, true);
													}} />
												
												{!item.expandido ? (
													<LabelAndInputNumber
														label='Prazo' 
														placeholder='Informe o Prazo'
														cols='12 6 2 2'
														casas={0}
														readOnly={readOnly}
														value={item.prazo}
														onChange={data => {

															let precoBasePrazo = this.props.listaCustoPrazo.filter(item => item.dias == data.target.value)[0];

															this.props.initForm({
																...this.props.formularioValues,
																itens: this.props.formularioValues.itens.map(itemAux => {
																	if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																		return {
																			...itemAux,
																			prazo: data.target.value,
																			preco_base_prazo: precoBasePrazo ? precoBasePrazo.valor : 0
																		}
																	} else {
																		return itemAux;
																	}
																})
															}, true);
														}} />
												) : (
													<LabelAndInputNumber
													label='Prazo' 
													placeholder='Informe o Prazo'
													cols='12 6 1 1'
													casas={0}
													readOnly={readOnly}
													value={item.prazo}
													onChange={data => {

														let precoBasePrazo = this.props.listaCustoPrazo.filter(item => item.dias == data.target.value)[0];

														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		prazo: data.target.value,
																		preco_base_prazo: precoBasePrazo ? precoBasePrazo.valor : 0
																	}
																} else {
																	return itemAux;
																}
															})
														}, true);
													}} />
												)}

												<LabelAndInputNumber
													label='Preço Unitário' 
													placeholder='Informe o preço'
													cols='12 6 2 2'
													casas={4}
													readOnly={readOnly}
													value={item.preco_unitario}
													onChange={data => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		preco_unitario: data.target.value
																	}
																} else {
																	return itemAux;
																}
															})
														}, true);
													}} />

												{!item.expandido &&
												<LabelAndInputNumber
													label='Preço Sugerido' 
													placeholder='Informe o preço'
													cols='12 6 2 2'
													casas={4}
													readOnly={'readOnly'}
													value={FormatUtils.formatarValorTela(precoUnitarioPrazo + precoUnitarioFrete, 4)}
													onChange={data => {
														
													}} />}
													
												{!item.expandido &&
												<LabelAndInputNumber
													label='Valor Total' 
													placeholder='Informe o valor'
													cols='12 6 2 2'
													casas={2}
													readOnly={'readOnly'}
													value={item.valor_total}
													onChange={data => {										
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		valor_total: data.target.value
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />}
											
												{!item.expandido ? (
													<LabelAndInput
														label='Margem Bruta' 
														placeholder=''
														cols='12 6 4 2'
														readOnly={'readOnly'}
														value={FormatUtils.formatarValorTela(margemBruta, 4)}
														onChange={data => {										
															
														}} />
												) : (
													<LabelAndInput
														label='Margem Bruta' 
														placeholder=''
														cols='12 6 2 1'
														readOnly={'readOnly'}
														value={FormatUtils.formatarValorTela(margemBruta, 4)}
														onChange={data => {										
															
														}} />
												)}
													
												{!item.expandido &&
												<LabelAndInput
													label='Observação' 
													placeholder='Informe a observação'
													cols='12 12 8 10'
													casas={2}
													readOnly={acessoLogistica ? null : readOnly}
													value={item.observacao}
													onChange={data => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		observacao: data.target.value
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />}
												{!item.expandido &&
												<Select
													name='compartimentos'
													label='Compartimentos'
													placeholder='Informe os compartimentos'
													cols='12 12 4 2'
													multiple
													readOnly={readOnly}
													options={compartimentos.filter(compartimento => {														
														return (
															!compartimento.ocupacao 
															|| (
																compartimento.ocupacao > 0
																&& (compartimento.capacidade - compartimento.ocupacao) >= parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2)) 
																&& compartimento.id_produto == item.id_produto
															)
															// !compartimentosOcupados.includes(compartimento.id) 
															|| (item.compartimentos || []).includes(compartimento.id)
														);
													})}
													value={compartimentos.map(compartimento => ({ value: compartimento.id, label: compartimento.valor })).filter(compartimento => (item.compartimentos || []).includes(compartimento.value))}
													onChange={value => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		compartimentos: value.map(item => item.value)
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />}

												{/* 
												
												<LabelAndInput
													label='Lucro Bruto' 
													placeholder=''
													cols='12 6 4 3'
													readOnly={'readOnly'}
													value={FormatUtils.formatarValorTela(parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2)) * margemBruta, 2)}
													onChange={data => {										
														
													}} /> 
												
												<LabelAndInputNumber
													label='Preço Unitário (sem frete)' 
													placeholder='Informe o preço'
													cols='12 6 4 2'
													casas={4}
													readOnly={'readOnly'}
													value={item.preco_unitario_bruto}
													onChange={data => {
														
													}} />

												<LabelAndInputNumber
													label='Preço Unitário Base' 
													placeholder='Informe o preço'
													cols='12 6 4 2'
													casas={4}
													readOnly={'readOnly'}
													value={item.preco_unitario_base}
													onChange={data => {
														
													}} />
												*/}
											</Row>
										</Grid>
										<Grid cols='2 2 1 1' 
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center'
											}}>

											<div 
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
													marginBottom: 4
												}} >

												<ButtonTable
													type={'success'}
													icon={'fas fa-chevron-up'}
													visible={true}
													disabled={(this.props.formularioValues.id && this.props.formularioValues.id_status != 1 
																&& this.props.formularioValues.id_status != 2 && !acessoLogistica) 
														|| i == 0}
													event={() => {

														let lista = this.props.formularioValues.itens.map((itemAux, j) => {
															if (i == j) {
																return {
																	...itemAux,
																	ordem: itemAux.ordem - 1
																}
															} else if (i - 1 == j) {
																return {
																	...itemAux,
																	ordem: itemAux.ordem + 1
																}
															} else {
																return itemAux;
															}
														});
																										
														lista.sort((a, b) => a.ordem < b.ordem ? -1 : 1);

														this.props.initForm({
															...this.props.formularioValues,
															itens: lista
														});
													}} />
												
												<ButtonTable
													type={'success'}
													icon={'fas fa-chevron-down'}
													visible={true}
													disabled={(this.props.formularioValues.id && this.props.formularioValues.id_status != 1 
																&& this.props.formularioValues.id_status != 2 && !acessoLogistica) 
														|| i == this.props.formularioValues.itens.length - 1}
													event={() => {
														let lista = this.props.formularioValues.itens.map((itemAux, j) => {
															if (i == j) {
																return {
																	...itemAux,
																	ordem: itemAux.ordem + 1
																}
															} else if (i + 1 == j) {
																return {
																	...itemAux,
																	ordem: itemAux.ordem - 1
																}
															} else {
																return itemAux;
															}
														});
																										
														lista.sort((a, b) => a.ordem < b.ordem ? -1 : 1);

														this.props.initForm({
															...this.props.formularioValues,
															itens: lista
														});
													}} />
											</div>

											<div 
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center'
												}} >										
												<ButtonTable
													type={'primary'}
													icon={item.expandido ? 'fas fa-expand' : 'fas fa-compress'}
													visible={true}
													disabled={false}
													event={() => {
														this.props.initForm({
															...this.props.formularioValues,
															itens: this.props.formularioValues.itens.map(itemAux => {
																if (itemAux.id ? item.id == itemAux.id : item.idAux == itemAux.idAux) {
																	return {
																		...itemAux,
																		expandido: !itemAux.expandido
																	}
																} else {
																	return itemAux;
																}
															})
														});
													}} />

												<ButtonTable
													type={'danger'}
													icon={'fas fa-trash-alt'}
													visible={true}
													disabled={this.props.formularioValues.id && this.props.formularioValues.id_status != 1 
															&& this.props.formularioValues.id_status != 2}
													event={() => {
														this.setState({
															...this.state,
															itemVendaPerdida: { ...item }
														});
													}} />
											</div>
										</Grid>
									</Row>
								)
							})}

							<br />
							
						</ContentCardBody>
					) : (
						<ContentCardBody>
							<Row>

								<Select
									options={this.props.listaStatus}
									label='Status'
									cols='12 6 4 4'
									placeholder='Selecione o status'
									readOnly={null}
									value={this.props.formularioValues.id_status}
									onChange={data => {
										if (data) {
											let registro = { 
												...this.props.formularioValues,
												id_status: data
											};
											//Aprovado
											if (data == 4) {
												registro = {
													...registro,
													id_usuario_logistica: null,
													id_usuario_entrega: null,
													datahora_logistica: null,
													datahora_entrega: null
												}
											}
											//Logística Parcial
											if (data == 5) {
												registro = {
													...registro,
													id_usuario_logistica: null,
													id_usuario_entrega: null,
													datahora_logistica: null,
													datahora_entrega: null
												}
											}
											//Logística Finalizada
											if (data == 6) {
												registro = {
													...registro,
													id_usuario_logistica: this.props.sessao.id,
													id_usuario_entrega: null,
													datahora_logistica: DateFormat.getDataHoraAtual(),
													datahora_entrega: null
												}
											}
											//Entrega
											if (data == 7) {
												registro = {
													...registro,
													id_usuario_entrega: this.props.sessao.id,
													datahora_entrega: DateFormat.getDataHoraAtual()
												}
											}
											this.props.salvar(registro);
										}
								}} />

								<SelectDefault
									name='id_unidade'
									options={this.props.listaUnidade}
									label='Unidade'
									cols='12 6 3 4'
									placeholder='Selecione o unidade'
									readOnly={'readOnly'}
									value={this.props.formularioValues.id_unidade}
									onChange={data => {
								
									}} />

								<Field
									name='giro'
									component={LabelAndInputNumber}
									label='Giro' placeholder='Informe o giro'
									cols='12 4 2 1'
									casas={0}
									readOnly={'readOnly'} />
						
								<SelectDefault
									name='id_veiculo'
									options={this.props.listaVeiculo}
									label='Veículo'
									cols='12 8 3 3'
									placeholder='Selecione o veículo'
									readOnly={'readOnly'}
									value={this.props.formularioValues.id_veiculo}
									onChange={data => {
								
									}} />

								{(veiculo && veiculo.documentacao_vencida) && (
									<Grid cols='12 12 12 12'>
										<div class="alert alert-danger alert-dismissible">
											<h6><i class="icon fas fa-ban"></i> Veículo possui problemas na documentação!</h6>
										</div>
									</Grid>
								)}
											
								<Field
									name='rota_link'
									component={LabelAndInput}
									label='Link da Rota' placeholder='Informe o link'
									cols='12 12 12 12'
									readOnly={'readOnly'} />
								
								{(this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
								
									let cliente = this.props.listaCliente.filter(cliente => cliente.id == item.id_cliente)[0];
									let endereco = this.props.listaEndereco.filter(endereco => endereco.id == item.id_endereco)[0];

									if (endereco && cliente && endereco.croqui) {
										return (
											<LabelAndInput
												name='croqui'
												component={LabelAndInput}
												label={`Croqui ${cliente.valor}`} placeholder='Croqui'
												cols='12 12 12 12'
												readOnly={'readOnly'}
												value={endereco.croqui}
												onChange={() => {}} />
										);
									}

								})}
								<br />

							</Row>

							{(this.props.formularioValues.itens || []).filter(item => item.ativo).map((item, i) => {
								
								return (
									<Row key={item.id || item.idAux} 
										style={{ paddingTop: 8, marginTop: 8, backgroundColor: item.id_produto == 1 ? '#FFFDD0' : (item.id_produto == 3 ? '#f2c2b8' : null) }}>
										<Grid cols='12 12 12 12'>									
											<Row>								
												<SelectDefault
													options={this.props.listaCliente}
													cols='12 6 3 3'
													label={'Cliente'}
													placeholder='Selecione o cliente'
													readOnly={'readOnly'}
													value={item.id_cliente}
													onChange={(data) => {
														
													}} />												
												
												<SelectDefault
													options={this.props.listaProduto}
													cols='12 6 2 1'
													label={'Produto'}
													placeholder='Selecione o produto'
													readOnly={'readOnly'}
													value={item.id_produto}
													onChange={(data) => {

													}} />

												<LabelAndInputNumber
													label='Quantidade' 
													placeholder='Informe o quantidade'
													cols='12 6 2 1'
													casas={2}
													readOnly={'readOnly'}
													value={item.quantidade}
													onChange={data => {
														
													}} />
											
												<LabelAndInput
													label='Observação' 
													placeholder='Informe a observação'
													cols='12 12 5 7'
													casas={2}
													readOnly={'readOnly'}
													value={item.observacao}
													onChange={data => {
													
													}} />
													
											</Row>
										</Grid>
									</Row>
								)
							})}
							
						</ContentCardBody>
					)}
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='12 12 3 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									disabled={(this.props.formularioValues.id && this.props.formularioValues.id_status != 1 
										&& this.props.formularioValues.id_status != 2 
										&& !acessoLogistica) || this.props.aguardando}
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 2 2'>
								<Button
									text='Imprimir Individual'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.imprimir(this.props.formularioValues, false)} />
							</Grid>
							<Grid cols='6 6 2 2'>
								<Button
									text='Imprimir Agrupado'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.imprimir(this.props.formularioValues, true)} />
							</Grid>
							<Grid cols='6 6 2 2'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

					<ContentCardBody>
					{
						(unidadeSelecionada && itensMapa.length > 0) &&
						<Map 
							rota={this.state.rota}
							rotaCarregada={this.state.rotaCarregada}
							origem={{ ...unidadeSelecionada, nome: unidadeSelecionada.valor }}
							destino={{ ...unidadeSelecionada, nome: unidadeSelecionada.valor }}
							itens={itensMapa.map(item => ({
								id: item.id,
								nome: item.endereco ? item.endereco.valor : 'Sem endereço',
								latitude: item.endereco ? item.endereco.latitude : 0,
								longitude: item.endereco ? item.endereco.longitude : 0
							}))}
							pontos={this.props.listaEndereco.filter(endereco => !itensMapa.filter(mapa => mapa.endereco && mapa.id == endereco.id).length)
								.map(item => ({
								id: item.id,
								nome: `${item.razao_social} - ${item.id_petroshow}\n${item.valor}\n\nCPF/CNPJ: ${item.cnpj || item.cpf || ''}\nTelefone: ${item.telefone || ''}\nCelular: ${item.celular || ''}\nÚltimo Preço S10: ${item.ultimo_preco_s10 || ''}\nÚltimo Preço S500: ${item.ultimo_preco_s500 || ''}\nÚltima Compra: ${item.dias_ultima_compra == null ? '-' : item.dias_ultima_compra} ${item.dias_ultima_compra == null ? '' : (item.dias_ultima_compra == 1 ? 'dia' : 'dias')}\nLocalização: ${item.latitude || ''}, ${item.longitude || ''}`,
								latitude: item.latitude,
								longitude: item.longitude,
								icone: `${item.dias_ultima_compra == null || item.dias_ultima_compra > 30 ? 'marker-vermelho' : (
									item.dias_ultima_compra > 7 ? 'marker-amarelo' : (
										'marker-verde'
									)
								)}${!codigoVendedor || codigoVendedor == item.codigo_vendedor ? '' : '-tag'}`
							}))}
							onClickPonto={id => {
								console.log(id);
								
								let endereco = this.props.listaEndereco.filter(endereco => endereco.id == id)[0];
								
								if (endereco) {
									let lista = (this.props.formularioValues.itens || []).map(item => item);
			
									//1 - OLEO DIESEL B S10 ADITIVA / 3 - OLEO DIESEL B S500 ADITIVADO			
									if (endereco.dias_ultima_compra == endereco.dias_ultima_compra_s10 && endereco.quantidade_s10 > 0) {

										let politicaSelecionada = this.props.listaPoliticaPreco.filter(politica => 
											politica.id_unidade == this.props.formularioValues.id_unidade
											&& politica.id_produto == 1)[0];

										lista.push({
											idAux: new Date().getTime(), 
											ativo: true, 
											ordem: lista.length + 1,
											id_cliente: endereco.id_cliente,
											id_endereco: endereco.id,
											id_produto: 1,
											quantidade: FormatUtils.formatarValorTela(endereco.quantidade_s10, 2),
											preco_unitario_bruto: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_bruto, 4) : null,
											preco_unitario_base: politicaSelecionada ? FormatUtils.formatarValorTela(parseFloat(politicaSelecionada.preco) - parseFloat(politicaSelecionada.preco_frete), 4) : null,
											preco_unitario: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco, 4) : 0,
											preco_unitario_frete: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_frete, 4) : null,
										}, true);
									}
									if (endereco.dias_ultima_compra == endereco.dias_ultima_compra_s500 && endereco.quantidade_s500 > 0) {

										let politicaSelecionada = this.props.listaPoliticaPreco.filter(politica => 
											politica.id_unidade == this.props.formularioValues.id_unidade
											&& politica.id_produto == 3)[0];

										lista.push({
											idAux: new Date().getTime(), 
											ativo: true, 
											ordem: lista.length + 1,
											id_cliente: endereco.id_cliente,
											id_endereco: endereco.id,
											id_produto: 3,
											quantidade: FormatUtils.formatarValorTela(endereco.quantidade_s500, 2),
											preco_unitario_bruto: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_bruto, 4) : null,
											preco_unitario_base: politicaSelecionada ? FormatUtils.formatarValorTela(parseFloat(politicaSelecionada.preco) - parseFloat(politicaSelecionada.preco_frete), 4) : null,
											preco_unitario: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco, 4) : 0,
											preco_unitario_frete: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_frete, 4) : null,
										});
									}
									if (endereco.quantidade_s10 <= 0 && endereco.quantidade_s500 <= 0) {
										lista.push({
											idAux: new Date().getTime(), 
											ativo: true, 
											ordem: lista.length + 1,
											id_cliente: endereco.id_cliente,
											id_endereco: endereco.id
										});
									}									
									
									this.props.initForm({
										...this.props.formularioValues,
										itens: lista
									});
								}
							}}
							setErro={erro => {
								this.setState({
									...this.state,
									rotaCarregada: null,
									rota: null,
									erroRota: true
								});
							}}
							setRota={rota => {
								this.setState({
									...this.state,
									rotaCarregada: rota.rotaCarregada,
									rota: {
										origem: rota ? rota.origem : null,
										destino: rota ? rota.destino : null,
										itens: rota ? rota.itens || [] : [],
										waypoints: rota ? rota.waypoints || [] : [],
										link_google_maps: rota ? rota.link_google_maps : null,
										distancia: rota ? rota.distancia : null,
										passos_localizacao: rota ? rota.passos_localizacao || [] : null,
										pontos: rota ? rota.pontos || [] : [],
										overview_polyline: rota ? rota.overview_polyline : null
									},
									erroRota: false
								});
							}} />}

						{this.state.erroRota &&
						<div class="alert alert-danger alert-dismissible">
							<h5><i class="icon fas fa-ban"></i> Não foi possível montar a rota!</h5>
							<h6>Verifique as Latitudes e longitudes</h6>

							{unidadeSelecionada && 
							<p><a href={`https://www.google.com/maps/place/${unidadeSelecionada.latitude},${unidadeSelecionada.longitude}`} target='_blank'>{unidadeSelecionada.valor}: {unidadeSelecionada.latitude}, {unidadeSelecionada.longitude}</a></p>}

							{itensMapa.map(item => (
								<p key={item.id}><a href={`https://www.google.com/maps/place/${item.endereco ? item.endereco.latitude : ''},${item.endereco ? item.endereco.longitude : ''}`} target='_blank'>{item.endereco ? item.endereco.valor : 'Sem endereço'}: {item.endereco ? item.endereco.latitude : ''}, {item.endereco ? item.endereco.longitude : ''}</a></p>
							))}
						</div>
						}
					</ContentCardBody>

				</Form>
			</ContentCard>
		)
    }

	modalVendaPerdidaItem() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Informe se foi venda perdida</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' 
								onClick={() => this.setState({ ...this.state, itemVendaPerdida: null })} >
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Row>
								
								<LabelAndCheckbox
									label='Venda Perdida' 
									placeholder='Informe'
									cols='12 12 12 12'
									value={this.state.itemVendaPerdida.venda_perdida}
									onChange={data => {
										this.setState({
											...this.state,
											itemVendaPerdida: {
												...this.state.itemVendaPerdida,
												venda_perdida: !this.state.itemVendaPerdida.venda_perdida
											}
										});
									}} />

								<LabelAndInput
									label='Concorrente' 
									placeholder='Informe o concorrente'
									cols='12 12 12 12'
									value={this.state.itemVendaPerdida.concorrente}
									onChange={data => {
										this.setState({
											...this.state,
											itemVendaPerdida: {
												...this.state.itemVendaPerdida,
												concorrente: data.target.value
											}
										});
									}} />

								<LabelAndInputNumber
									label='Preço do Concorrente' 
									placeholder='Informe o preço'
									cols='12 12 12 12'
									casas={2}
									value={this.state.itemVendaPerdida.preco_concorrente}
									onChange={data => {
										this.setState({
											...this.state,
											itemVendaPerdida: {
												...this.state.itemVendaPerdida,
												preco_concorrente: data.target.value
											}
										});
									}} />

							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.props.initForm({
										...this.props.formularioValues,
										itens: this.props.formularioValues.itens.map(itemAux => {
											if (itemAux.id ? this.state.itemVendaPerdida.id == itemAux.id : this.state.itemVendaPerdida.idAux == itemAux.idAux) {
												return {
													...itemAux,
													ativo: false,
													venda_perdida: this.state.itemVendaPerdida.venda_perdida || false,
													concorrente: this.state.itemVendaPerdida.concorrente,
													preco_concorrente: FormatUtils.formatarValorBanco(this.state.itemVendaPerdida.preco_concorrente, 4)
												}
											} else {
												return itemAux;
											}
										})
									});

									this.setState({ ...this.state, itemVendaPerdida: null });
								}}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

	modalVendaPerdida() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Venda Perdida</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' 
								onClick={() => this.setState({ ...this.state, modalVendaPerdida: false })} >
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Row>
								
								<LabelAndInput
									label='Concorrente' 
									placeholder='Informe o concorrente'
									cols='12 12 12 12'
									value={this.props.formularioValues.concorrente}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											concorrente: data.target.value
										});
									}} />

								<LabelAndInputNumber
									label='Preço S10 do Concorrente' 
									placeholder='Informe o preço'
									cols='12 12 12 12'
									casas={2}
									value={this.props.formularioValues.preco_concorrente_s10}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											preco_concorrente_s10: data.target.value
										});
									}} />
									
								<LabelAndInputNumber
									label='Preço S500 do Concorrente' 
									placeholder='Informe o preço'
									cols='12 12 12 12'
									casas={2}
									value={this.props.formularioValues.preco_concorrente_s500}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											preco_concorrente_s500: data.target.value
										});
									}} />

							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.props.salvar({
										...this.props.formularioValues,
										id_status: 3, //Rejeitado
										venda_perdida: true,
										preco_concorrente_s10: FormatUtils.formatarValorBanco(this.props.formularioValues.preco_concorrente_s10, 2),
										preco_concorrente_s500: FormatUtils.formatarValorBanco(this.props.formularioValues.preco_concorrente_s500, 2)
									});
									this.setState({ ...this.state, modalVendaPerdida: false });
								}}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

PedidoForm = reduxForm({form: 'pedidoForm', destroyOnUnmount: false})(PedidoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	formularioValues: getFormValues('pedidoForm')(state),
	aguardando: state.pedido.aguardando,
	registro: state.pedido.registro,
	listaDistribuidora: state.pedido.listaDistribuidora,
	listaPoliticaPreco: state.pedido.listaPoliticaPreco,
	listaUnidade: state.pedido.listaUnidade,
	listaMotorista: state.pedido.listaMotorista,
	listaVeiculo: state.pedido.listaVeiculo,
	listaCliente: state.pedido.listaCliente,
	listaEndereco: state.pedido.listaEndereco,
	listaProduto: state.pedido.listaProduto,
	listaCustoPrazo: state.pedido.listaCustoPrazo,
	listaStatus: state.pedido.listaStatus
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, imprimir
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoForm);
