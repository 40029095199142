const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    aguardando: false,
    lista: [], 
    listaTipo: [],
    listaCompartimentos: [
        { id: 0, valor: 0 }, { id: 1, valor: 1 }, { id: 2, valor: 2 }, { id: 3, valor: 3 }, { id: 4, valor: 4 }, { id: 5, valor: 5 }, { id: 6, valor: 6 }, 
        { id: 7, valor: 7 }, { id: 8, valor: 8 }, { id: 9, valor: 9 }, { id: 10, valor: 10 }, { id: 11, valor: 11 }, { id: 12, valor: 12 }
    ],
    listaDashboard: [],
	listaDashboardKmRodado: [],
	listaEntrada: [],
	listaSaida: [],
	listaTransferencia: [],
	listaUnidade: [],
	listaPosicaoVeiculo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VEICULO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        
        case 'VEICULO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};


        case 'VEICULO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'VEICULO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};
        
        case 'VEICULO_DASHBOARD_LISTADO':
            return { ...state, listaDashboard: action.payload.data };

		case 'VEICULO_DASHBOARD_KM_RODADO_LISTADO':
            return { ...state, listaDashboardKmRodado: action.payload.data };

        case 'VEICULO_DASHBOARD_ENTRADA_LISTADO':
			return { ...state, listaEntrada: action.payload.data };

		case 'VEICULO_DASHBOARD_SAIDA_LISTADO':
			return { ...state, listaSaida: action.payload.data };

		case 'VEICULO_DASHBOARD_TRANSFERENCIA_LISTADO':
			return { ...state, listaTransferencia: action.payload.data };
		
		case 'VEICULO_DASHBOARD_POSICAO_LISTADO':
			return { ...state, listaPosicaoVeiculo: action.payload.data };

        case 'VEICULO_UNIDADE_SELECT_LISTADO':
			return { ...state, listaUnidade: action.payload.data.filter(item => !item.logistica_direta) };

        case 'VEICULO_MOTORISTA_SELECT_LISTADO':
			return { ...state, listaMotorista: action.payload.data };

        case 'VEICULO_REBOQUE_SELECT_LISTADO':
			return { ...state, listaReboque: action.payload.data };

        default:
            return state;
    }
}
