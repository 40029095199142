
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	lista: [],
	filtro: {
		data: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_unidade: null,
		id_produto: null,
		ordem: 'preco_30_frete'
	},
	listaUnidade: [],
	listaProduto: [],
	listaDistribuidora: [],
	listaCidade: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
	
		case 'PRECO_GERAL_FILTRO':
            return { ...state,
				filtro: action.payload
			};

        case 'PRECO_GERAL_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'PRECO_GERAL_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

        case 'PRECO_GERAL_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

        case 'PRECO_GERAL_DISTRIBUIDORA_SELECT_LISTADO':
            return {
				...state,
				listaDistribuidora: action.payload.data
			};

        case 'PRECO_GERAL_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

        default:
            return state;
    }
}
