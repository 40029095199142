import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './checklistActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import DateFormat from '../common/dateFormat/DateFormat';

class ChecklistForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
								
							{(this.props.formularioValues.perguntas || []).map((pergunta, iPer) => (
								<Grid cols='12 12 12 12' key={pergunta.id}>
									<ContentCard style={{ backgroundColor: iPer % 2 == 0 ? '#e0dada' : '#f7f2f2' }}>
										<ContentCardBody>
											<Row>
												<Select
													name='id_tipo'
													options={this.props.listaTipoPergunta}
													label='Tipo'
													cols='12 12 3 2'
													placeholder='Selecione o tipo'
													readOnly={readOnly}
													value={pergunta.id_tipo}
													onChange={value => {
														this.props.initForm({
															...this.props.formularioValues,
															perguntas: (this.props.formularioValues.perguntas || []).map(item => {
																if (item.id == pergunta.id) {
																	return {
																		...item,
																		id_tipo: value,
																		alternativas: value == 3 ? [{}] : null
																	};
																} else {
																	return item;
																}
															})
														});
													}} />

												<LabelAndInput
													name='pergunta'
													label='Pergunta' placeholder='Informe a pergunta'
													cols='12 12 7 9'
													readOnly={readOnly}
													value={pergunta.nome}
													onChange={data => {
														this.props.initForm({
															...this.props.formularioValues,
															perguntas: (this.props.formularioValues.perguntas || []).map(item => {
																if (item.id == pergunta.id) {
																	return {
																		...item,
																		nome: data.target.value
																	};
																} else {
																	return item;
																}
															})
														});
													}} />
													<button type={'button'} 
														className={`btn btn-flat btn-outline-danger`}
														style={{ marginTop: 28, marginBottom: 16 }}
														onClick={() => {
															this.props.initForm({
																...this.props.formularioValues,
																perguntas: (this.props.formularioValues.perguntas || []).filter(item => item.id != pergunta.id)
															});
														}} >
														<i className={'fas fa-trash-alt'}></i>
													</button>

													{pergunta.alternativas && (
														<>
															<Grid cols='12 12 12 12'>
																<div class="d-flex justify-content-between align-items-center border-bottom mb-3">
																	
																</div>
															</Grid>
															{pergunta.alternativas.map((alternativa, iAlt) => (
																<>
																	<LabelAndInput key={alternativa.id}
																		name='alternativa'
																		label={`Opção ${iAlt + 1}`} placeholder='Informe a opção'
																		cols='10 10 10 11'
																		readOnly={readOnly}
																		value={alternativa.nome}
																		onChange={data => {
																			this.props.initForm({
																				...this.props.formularioValues,
																				perguntas: (this.props.formularioValues.perguntas || []).map(item => {
																					if (item.id == pergunta.id) {
																						return {
																							...item,
																							alternativas: pergunta.alternativas.map(altItem => {
																								if (altItem.id == alternativa.id) {
																									return {
																										...altItem,
																										nome: data.target.value
																									}
																								} else {
																									return altItem;
																								}
																							})
																						}
																					} else {
																						return item;
																					}
																				})
																			});
																		}} />
																		
																	<button type={'button'} 
																		className={`btn btn-flat btn-outline-danger`}
																		style={{ marginTop: 28, marginBottom: 16 }}
																		onClick={() => {
																			this.props.initForm({
																				...this.props.formularioValues,
																				perguntas: (this.props.formularioValues.perguntas || []).map(item => {
																					if (item.id == pergunta.id) {
																						return {
																							...item,
																							alternativas: pergunta.alternativas.filter(altItem => altItem.id != alternativa.id)
																						}
																					} else {
																						return item;
																					}
																				})
																			});
																		}} >
																		<i className={'fas fa-trash-alt'}></i>
																	</button>

																</>
															))}
															<Grid cols='12 6 4 3'>
																<Button
																	text='Adicionar Opção'
																	type={'primary'}
																	icon={'fa fa-chevron-left'}
																	event={() => {
																		this.props.initForm({
																			...this.props.formularioValues,
																			perguntas: (this.props.formularioValues.perguntas || []).map(item => {
																				if (item.id == pergunta.id) {
																					let alternativas = pergunta.alternativas.map(altItem => altItem);
																					alternativas.push({ id: new Date().getTime() });
																					return {
																						...item,
																						alternativas: alternativas
																					}
																				} else {
																					return item;
																				}
																			})
																		});
																	}} />
															</Grid>
														</>
													)}												
											</Row>
										</ContentCardBody>
									</ContentCard>
								</Grid>
							))}

							<Grid cols='12 12 12 12'>
								<Button
									text='Adicionar Pergunta'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => {
										let perguntas = (this.props.formularioValues.perguntas || []).map(item => item);
										perguntas.push({ id: new Date().getTime() });
										this.props.initForm({
											...this.props.formularioValues,
											perguntas: perguntas
										});
									}} />
							</Grid>
								
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

ChecklistForm = reduxForm({form: 'checklistForm', destroyOnUnmount: false})(ChecklistForm);
const mapStateToProps = state => ({
	sessao: state.auth.checklist,
	formularioValues: getFormValues('checklistForm')(state),
	registro: state.checklist.registro,
	listaTipoPergunta: state.checklist.listaTipoPergunta
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistForm);
