const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaUnidade: [],
    listaProduto: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TANQUE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TANQUE_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'TANQUE_UNIDADE_SELECT_LISTADO':
			return {
				...state,
				listaUnidade: action.payload.data
            };

        case 'TANQUE_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
			};

        default:
            return state;
    }
}
