import React from 'react'

export default props => (
    <li className='nav-item has-treeview menu-open'>
        <a className="nav-link" onClick={props.onClick} style={{cursor: 'pointer'}} >
            <i className={`nav-icon ${props.icon}`}></i>
            <p>{props.label}</p>
        </a>
        <ul className='nav' style={{ display: props.display ? 'block' : 'none' }} >
            {props.children}
        </ul>
    </li>
)