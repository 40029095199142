const INITIAL_STATE = {
	aguardando: false,
	listaDiario: [],
	listaMensal: [],
	listaAnual: [],
	listaUnidade: [],
	listaProduto: []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_PEDIDO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'DASHBOARD_PEDIDO_DIARIO_LISTADO':
			return { ...state, listaDiario: action.payload.data };
		
		case 'DASHBOARD_PEDIDO_MENSAL_LISTADO':
			return { ...state, listaMensal: action.payload.data };

		case 'DASHBOARD_PEDIDO_ANUAL_LISTADO':
			return { ...state, listaAnual: action.payload.data };
		
		case 'DASHBOARD_PEDIDO_UNIDADE_SELECT_LISTADO':
			return { ...state, listaUnidade: action.payload.data };
		
		case 'DASHBOARD_PEDIDO_PRODUTO_SELECT_LISTADO':
			return { ...state, listaProduto: action.payload.data };
			
        default:
            return state;
    }
}
