const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCarreta: [
		{ id: '1', valor: '1' }, { id: '2', valor: '2' }
	]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VEICULO_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'VEICULO_TIPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};
			
        default:
            return state;
    }
}
