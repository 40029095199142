import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/entrada/listarSaldosTanques`);
    return {
        type: 'DASHBOARD_LISTADO',
        payload: request
    };
}

export function getListaTerceiros() {
    const request = axios.get(`${consts.API_URL}/entrada/listarSaldosTerceiros`);
    return {
        type: 'DASHBOARD_TERCEIRO_LISTADO',
        payload: request
    };
}

export function getListaEntradas() {
    const request = axios.get(`${consts.API_URL}/entrada/listarDashboard`);
    return {
        type: 'DASHBOARD_ENTRADA_LISTADO',
        payload: request
    };
}

export function getListaSaidas() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboard`);
    return {
        type: 'DASHBOARD_SAIDA_LISTADO',
        payload: request
    };
}


export function getListaTransferencias() {
    const request = axios.get(`${consts.API_URL}/transferencia/listarDashboard`);
    return {
        type: 'DASHBOARD_TRANSFERENCIA_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'DASHBOARD_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'DASHBOARD_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPosicaoVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/posicao`);
    return {
        type: 'DASHBOARD_VEICULO_POSICAO_LISTADO',
        payload: request
    };
}

export function getListaManual() {
    const request = axios.get(`${consts.API_URL}/estoqueManual`);
    return {
        type: 'DASHBOARD_ESTOQUE_MANUAL_LISTADO',
        payload: request
    };
}