import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setError, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_MOTORISTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'META_MOTORISTA_FILTRO',
        payload: {
			filtro: filtro 
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('metaMotoristaForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/metaMotorista?competencia=${getState().metaMotorista.filtro.competencia}`).then(resp => {
			dispatch({
				type: 'META_MOTORISTA_LISTADO',
				payload: resp
			});
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaMotorista`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaMotorista`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaMotorista?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/apontamentoGrupo/listarSelect`);
    return {
        type: 'META_MOTORISTA_GRUPO_SELECT_LISTADO',
        payload: request
    };
}

export function importarTelemetria(tabela, competencia) {

	const listaApontamentos = [
		// { nome: 'Sigest Diário não preenchido', nivel: 'Leve II', pontos: 3 },
		{ nome: 'PRÉ-INFRAÇÃO DE EXCESSO DE VELOCIDADE COM CHUVA', nivel: 'Leve I', pontos: 1 },
		{ nome: 'PRÉ-INFRAÇÃO DE EXCESSO DE VELOCIDADE SECO', nivel: 'Leve I', pontos: 1 },
		{ nome: 'EXCESSO VELOCIDADE TRECHO RODOVIARIO COM CHUVA', nivel: 'Grave I', pontos: 15 },
		{ nome: 'EXCESSO VELOCIDADE TRECHO RODOVIARIO SECO', nivel: 'Grave I', pontos: 15 },
		{ nome: 'FREADA BRUSCA', nivel: 'Leve II', pontos: 3 },
		{ nome: 'ARRANCADA BRUSCA', nivel: 'Leve I', pontos: 1 },
		{ nome: 'BANGUELA', nivel: 'Leve II', pontos: 3 },
		{ nome: 'FORÇA G LATERAL FRACA', nivel: 'Leve I', pontos: 1 },
		{ nome: 'FORÇA G LATERAL MÉDIA', nivel: 'Média I', pontos: 8 },
		{ nome: 'FORÇA G LATERAL FORTE', nivel: 'Gravíssima', pontos: 100 },
		{ nome: 'EXCESSO DE ROTAÇÃO', nivel: 'Leve II', pontos: 3 }
	];
    return async (dispatch, getState) => {

		let listaImportada = [];
		for (var i = 0; i < tabela.length; i++) {

			let item = tabela[i];

			if (i > 0 && item && item[0]) {				

				let registro = {
					dataEmissao: `${item[0].split('/')[2]}/${item[0].split('/')[1].padStart(2, '0')}/${item[0].split('/')[0].padStart(2, '0')}`,
					placa: item[1],
					motorista: String(item[2] || '').toLocaleUpperCase(),
					evento: item[3],
					velocidade: item[4],
					hodometro: item[5],
					duracao: item[6],
					apontamento: listaApontamentos.filter(apontamento => apontamento.nome == item[3])[0]
				};

				if (registro.dataEmissao) {
					
					let dataAux = new Date(`${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`);
					dataAux.setMonth(dataAux.getMonth() - 1);
					let dataInicial = `${dataAux.getFullYear()}/${String(dataAux.getMonth() + 1).padStart(2, '0')}/21`;
					let dataFinal = `${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`;

					if (registro.dataEmissao >= dataInicial && registro.dataEmissao <= dataFinal) {
						listaImportada.push(registro);
					}
				}
			}
		}

		let listaMetas = [];

		await axios.get(`${consts.API_URL}/metaMotorista?competencia=${competencia}`).then(resp => {
			listaMetas = resp.data;
		}).catch(e => {
			setError('Competência não informada');
		});

		listaMetas = listaMetas.map(registro => {
			
			let pontos = 0;
			let faltaGrave = 0;
			let apontamentosEncontrados = listaApontamentos.filter(apontamento => apontamento.pontos < 100).map(apontamento => ({ nome: apontamento.nome, quantidade: 0 }));
			let apontamentosGravesEncontrados = listaApontamentos.filter(apontamento => apontamento.pontos >= 100).map(apontamento => ({ nome: apontamento.nome, quantidade: 0 }));

			listaImportada.filter(item => item.motorista == registro.nome_motorista).forEach(item => {
				
				pontos += item.apontamento ? (item.apontamento.pontos || 0) : 0;
				apontamentosEncontrados = apontamentosEncontrados.map(apontamento => {
					if (item.apontamento && apontamento.nome == item.apontamento.nome) {
						return {
							...apontamento,
							quantidade: apontamento.quantidade + 1
						};
					} else {
						return apontamento;
					}
				});

				if (item.apontamento && (item.apontamento.pontos || 0) >= 100) {

					faltaGrave++;
					apontamentosGravesEncontrados = apontamentosGravesEncontrados.map(apontamento => {
						if (item.apontamento && apontamento.nome == item.apontamento.nome) {
							return {
								...apontamento,
								quantidade: apontamento.quantidade + 1
							};
						} else {
							return apontamento;
						}
					});

				}
				
			});
			
			return {
				...registro,
				telemetria: pontos > 100 ? 100 : pontos,
				falta_grave_telemetria: faltaGrave * 100,
				telemetria_itens: apontamentosEncontrados,
				falta_grave_telemetria_itens: apontamentosGravesEncontrados,
				id_usuario_alteracao: getState().auth.usuario.id
			}
		});

		// console.log(listaMetas);
		await axios.put(`${consts.API_URL}/metaMotorista/importacao`, { lista: listaMetas }).then(resp => {
			
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function importarApontamento(tabela, competencia) {

	const listaApontamentos = [
		{ setor: 'RH', nome: 'Advertência', nivel: 'Grave II', pontos: 20 },
		{ setor: 'RH', nome: 'Suspensão', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'RH', nome: 'Papeletas - Entrega fora do prazo', nivel: 'Leve II', pontos: 3 },
		{ setor: 'RH', nome: 'Papeletas - Rasuras', nivel: 'Leve I', pontos: 1 },
		{ setor: 'RH', nome: 'Tacógrafo - Entrega fora do prazo', nivel: 'Leve II', pontos: 3 },
		{ setor: 'RH', nome: 'Tacógrafo - Rasuras', nivel: 'Leve I', pontos: 1 },
		{ setor: 'RH', nome: 'Relatório de viagem e abastecimento - Entrega fora do prazo', nivel: 'Leve II', pontos: 3 },
		{ setor: 'RH', nome: 'Relatório de viagem e abastecimento - Rasuras', nivel: 'Leve II', pontos: 3 },
		{ setor: 'RH', nome: 'Documentos profissionais - CNH, MOPP, NR20 e NR35 - vencidos', nivel: 'Média II', pontos: 10 },
		{ setor: 'RH', nome: 'Documentos profissionais - CNH, MOPP, NR20 e NR35 - desatualizados', nivel: 'Média II', pontos: 10 },
		{ setor: 'RH', nome: 'Atestado', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Manutenção', nome: 'Acidente Controlável', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'Manutenção', nome: 'Perda de pneu/peças por omissão, imperícia ou imprudência', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Manutenção', nome: 'Falha na conservação e limpeza da cabine', nivel: 'Leve II', pontos: 3 },
		{ setor: 'Manutenção', nome: 'Não realizar "Volta Olímpica" antes de iniciar viagem', nivel: 'Média I', pontos: 8 },
		{ setor: 'Manutenção', nome: 'Não passar pela rampa de inspeção ao final da viagem', nivel: 'Média I', pontos: 8 },
		{ setor: 'Manutenção', nome: 'Prenchimento incompleto relatório Sigest de manutenção', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Descumprimento de ordem ou recusa de viagem', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Falta de produto (descarga e entrega)', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Logística', nome: 'Derramamento', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'IQA - Reporte de quase acidente', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Deixou de informar final de carregamento', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Trafegar sem a documentação correta do veículo e carga', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Preenchimento incompleto Check list diário Sigest', nivel: 'Leve II', pontos: 3 },
		{ setor: 'Logística', nome: 'Desvio de rota sem justificativa Leve', nivel: 'Leve II', pontos: 3 },
		{ setor: 'Logística', nome: 'Desvio de rota sem justificativa Intermediário', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Logística', nome: 'Desvio de rota sem justificativa Grave', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'Logística', nome: 'Violação de Jornada/descanço/interstício/refeição', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Logística', nome: 'Lançamento errado/falta de lançamento macro de jornada', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Não realizou login no Tablet', nivel: 'Média I', pontos: 8 },
		{ setor: 'Manutenção', nome: 'Não apresentou-se descansado/tranquilo', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Não realizou consulta e estudo do rotograma antes da viagem', nivel: 'Média I', pontos: 8 },
		{ setor: 'Manutenção', nome: 'Não Realizou ajustes de bancos, espelhos e encosto de cabeça', nivel: 'Leve II', pontos: 3 },
		{ setor: 'Logística', nome: 'Não retorno ligações ou mensagens dos setores da empresa nos momentos que estiver fora da direção', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Uso do celular enquando dirigindo', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'Manutenção', nome: 'Alimentou ou fumou enquano dirigindo', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Carona', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'Logística', nome: 'Sem cinto de segurança', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Manutenção', nome: 'Sem uniforme', nivel: 'Leve II', pontos: 3 },
		{ setor: 'Manutenção', nome: 'Falta de EPI', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Manutenção', nome: 'Ingestão de álcool e/ou drogas (em paradas intermediárias para descanso ou almoço)', nivel: 'Gravíssima', pontos: 100 },
		{ setor: 'Manutenção', nome: 'Não reporte de manutenção', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Não Dirigir com as duas mãos no volante na posição 9:15', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Não trafegar pela direita em vias duplas e respeito à velocidade da pista', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Desrespeitar a distância de seguimento', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Ultrapassagem de forma insegura', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Não facilitar a ultrapassagem de terceiros/ ser cortês no trânsito', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Não realizar as sinalizações antecipadamente (seta, etc)', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Solicita auxílio para manobra em clientes e marcha ré', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Desrespeitas regras de semáforo', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Aciona o freio de mão/calça o CT ao estacionar', nivel: 'Média I', pontos: 8 },
		{ setor: 'Logística', nome: 'Estacionar em local inapropriado/não permitido', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Não informar situação atual e localidade ao final de cada demanda no grupo de whatsapp', nivel: 'Média I', pontos: 8 },
		{ setor: 'RH', nome: 'DSR entre 24he 35,5h', nivel: 'Média I', pontos: 8 },
		{ setor: 'RH', nome: 'DSR abaixo de 24h', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Logística', nome: 'Não comunicação sobre práticas inseguras/quase acidentes', nivel: 'Grave I', pontos: 15 },
		{ setor: 'Logística', nome: 'Não identificação do condutor no Computador de Bordo', nivel: 'Média I', pontos: 8 },
		{ setor: 'RH', nome: 'Limite operacional violado até 5 min', nivel: 'Leve I', pontos: 1 },
		{ setor: 'RH', nome: 'Limite operacional violado até 5 a 10 min', nivel: 'Média I', pontos: 8 },
		{ setor: 'RH', nome: 'Limite operacional violado acima de 10 min', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Desrespeitoso ou agressivo verbalmente', nivel: 'Média II', pontos: 10 },
		{ setor: 'Logística', nome: 'Cometeu agressão física', nivel: 'Grave II', pontos: 20 },
		{ setor: 'Logística', nome: 'Parada não autorizada', nivel: 'Média II', pontos: 10 }
	];
    return async (dispatch, getState) => {

		let listaImportada = [];
		for (var i = 0; i < tabela.length; i++) {

			let item = tabela[i];

			if (i > 0 && item && item[0]) {				

				let registro = {
					id: item[0],
					data: `${item[1].split('/')[2]}/${item[1].split('/')[1].padStart(2, '0')}/${item[1].split('/')[0].padStart(2, '0')}`,
					gerencia: item[2],
					motorista: String(item[3] || '').toLocaleUpperCase(),
					placa: item[4],
					apontamento: item[5],
					responsavel: item[6],
					observacao: item[7],
					documento: item[8],
					apontamento: listaApontamentos.filter(apontamento => apontamento.nome == item[11])[0]
				};

				if (registro.data) {
					
					let dataAux = new Date(`${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`);
					dataAux.setMonth(dataAux.getMonth() - 1);
					let dataInicial = `${dataAux.getFullYear()}/${String(dataAux.getMonth() + 1).padStart(2, '0')}/21`;
					let dataFinal = `${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`;

					if (registro.data >= dataInicial && registro.data <= dataFinal) {
						listaImportada.push(registro);
					}
				}
			}
		}

		let listaMetas = [];

		await axios.get(`${consts.API_URL}/metaMotorista?competencia=${competencia}`).then(resp => {
			listaMetas = resp.data;
		}).catch(e => {
			setError('Competência não informada');
		});

		listaMetas = listaMetas.map(registro => {
			
			let pontos = 0;
			let faltaGrave = 0;
			let apontamentosItens = [];
			let apontamentosGravesItens = [];
			
			listaImportada.filter(item => item.motorista == registro.nome_motorista).forEach(item => {
				if (item.apontamento && (item.apontamento.pontos || 0) < 100) {
					
					pontos += item.apontamento ? (item.apontamento.pontos || 0) : 0;
					apontamentosItens.push({
						data: registro.data,
						data_formatada: DateFormat.formatarDataSqlParaTela(item.data),
						nome: item.apontamento.nome
					});

				} else {
					
					faltaGrave++;
					apontamentosGravesItens.push({
						data: registro.data,
						data_formatada: DateFormat.formatarDataSqlParaTela(item.data),
						nome: item.apontamento.nome
					});

				}
			});
		
			return {
				...registro,
				apontamentos: pontos,
				falta_grave_apontamento: faltaGrave * 100,
				apontamento_itens: apontamentosItens,
				falta_grave_apontamento_itens: apontamentosGravesItens,
				id_usuario_alteracao: getState().auth.usuario.id
			}
		});

		await axios.put(`${consts.API_URL}/metaMotorista/importacao`, { lista: listaMetas }).then(resp => {
			
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function importarSigest(tabela, competencia) {

    return async (dispatch, getState) => {

		let listaImportada = [];
		for (var i = 0; i < tabela.length; i++) {

			let item = tabela[i];

			if (i > 0 && item && item[0]) {				

				let registro = {
					cpf: item[0],
					motorista: String(item[1] || '').toLocaleUpperCase(),
					operacao: item[2],
					realizados: parseInt(item[3] || 0),
					esperados: parseInt(item[4]|| 0)
				};
				
				listaImportada.push(registro);
			}
		}

		let listaMetas = [];

		await axios.get(`${consts.API_URL}/metaMotorista?competencia=${competencia}`).then(resp => {
			listaMetas = resp.data;
		}).catch(e => {
			setError('Competência não informada');
		});

		listaMetas = listaMetas.map(registro => {
			
			let naoRealizados = 0;
			listaImportada.filter(item => item.motorista == registro.nome_motorista).forEach(item => {
				naoRealizados += item.esperados - item.realizados;
			});
			
			return {
				...registro,
				sigest: naoRealizados * 3, /* SIGEST DIÁRIO multiplica por 3 (planilha Lista de Apontamentos)*/
				sigest_nao_realizado: naoRealizados,
				id_usuario_alteracao: getState().auth.usuario.id
			}
		});

		await axios.put(`${consts.API_URL}/metaMotorista/importacao`, { lista: listaMetas }).then(resp => {
			
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function importarTruckPag(tabela, competencia) {

    return async (dispatch, getState) => {

		let listaImportada = [];
		for (var i = 0; i < tabela.length; i++) {

			let item = tabela[i];

			if (i > 0 && item && item[0]) {
				let registro = {
					placa: item[0],
					transacao: item[1],
					data: `${item[2].split('/')[2]}/${item[2].split('/')[1].padStart(2, '0')}/${item[2].split('/')[0].padStart(2, '0')}`,
					hora: item[3],
					estabelecimento: item[4],
					cidade: item[5],
					motorista: String(item[6] || '').toLocaleUpperCase(),
					hodometro: parseInt(String(item[7])),
					quantidade: parseFloat(String(item[8] || '').replace(',', '.') || 0),
					kmRodados: parseFloat(String(item[14] || '').replace(',', '.') || 0),
					media: parseFloat(String(item[15] || '').replace(',', '.') || 0),
					rsKm: parseFloat(String(item[16] || '').replace('R$', '').replace(',', '.') || 0)
				};

				if (registro.data) {

					let dataAux = new Date(`${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`);
					dataAux.setMonth(dataAux.getMonth() - 1);
					let dataInicial = `${dataAux.getFullYear()}/${String(dataAux.getMonth() + 1).padStart(2, '0')}/21`;
					let dataFinal = `${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`;

					if (registro.data >= dataInicial && registro.data <= dataFinal) {	
						listaImportada.push(registro);
					}
				}
			}
		}

		let listaParametroConsumo = [];
		await axios.get(`${consts.API_URL}/veiculoTipoConsumo`).then(resp => {
			listaParametroConsumo = resp.data;
		}).catch(e => {
			setError('Parâmetros de consumo não localizados');
		});

		let listaVeiculo = [];
		await axios.get(`${consts.API_URL}/veiculo`).then(resp => {
			listaVeiculo = resp.data;
		}).catch(e => {
			setError('Veículos não localizados');
		});

		let listaMetas = [];
		await axios.get(`${consts.API_URL}/metaMotorista?competencia=${competencia}`).then(resp => {
			listaMetas = resp.data;
		}).catch(e => {
			setError('Competência não informada');
		});

		listaMetas = listaMetas.map(registro => {

			let valorKm = 0;
			let valorMedia = 0;
			let kmRodado = 0;
			let mediaKm = 0;
			let quantidade = 0;
			const multiploKm = 1;

			console.log(`--------------------${registro.nome_motorista}----------------------------`);
						
			listaImportada.filter(item => item.motorista == registro.nome_motorista).forEach(item => {
				console.log(item.quantidade);
				
				let veiculo = listaVeiculo.filter(veiculo => veiculo.placa == item.placa)[0];

				if (!veiculo) {
					console.log(`VEICULO: ${item.placa}`);
				}

				if (veiculo) {
					let parametro = listaParametroConsumo.filter(parametro => parametro.id_veiculo_tipo == veiculo.id_tipo)[0];

					if (parametro) {
						valorKm += item.kmRodados * parseFloat(parametro.premio || 0);
						
						let mediaAbastecida = item.quantidade > 0 && item.kmRodados > 0 ? item.kmRodados / item.quantidade : 0;

						let pontosMedia = ((1 / parseFloat(parametro.meta || 0)) - (1 / mediaAbastecida)) * item.kmRodados * parseFloat(parametro.meta || 0);
						let kmEconomizado = pontosMedia * multiploKm;				
						valorMedia += kmEconomizado * parseFloat(parametro.premio || 0);

						kmRodado += item.kmRodados;
						quantidade += item.quantidade;
						mediaKm += item.media * item.kmRodados;
					} else {
						console.log(`VEICULO: ${item.placa}`);
					}
				}
			});		

			console.log(`${registro.nome_motorista}: ${kmRodado}km / ${quantidade}L = ${kmRodado / quantidade}km/l`);

			return {
				...registro,
				valor_km: valorKm || 0,
				media: valorMedia > 0 ? valorMedia : 0,
				km_rodado: kmRodado,
				media_realizada: quantidade > 0 && kmRodado > 0 ? kmRodado / quantidade : 0,
				id_usuario_alteracao: getState().auth.usuario.id
			};
		});

		await axios.put(`${consts.API_URL}/metaMotorista/importacao`, { lista: listaMetas }).then(resp => {
			
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function importarRH(tabela, competencia) {

    return async (dispatch, getState) => {

		let listaImportada = [];
		for (var i = 0; i < tabela.length; i++) {

			let item = tabela[i];

			if (i > 0 && item && item[0] && item[1]) {
				let registro = {
					data: `${item[1].split('/')[2]}/${item[1].split('/')[1].padStart(2, '0')}/${item[1].split('/')[0].padStart(2, '0')}`,
					motorista: String(item[2] || '').toLocaleUpperCase(),
					tipo: (item[4] || '').trim()
				};

				if (registro.data) {

					let dataAux = new Date(`${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`);
					dataAux.setMonth(dataAux.getMonth() - 1);
					let dataInicial = `${dataAux.getFullYear()}/${String(dataAux.getMonth() + 1).padStart(2, '0')}/21`;
					let dataFinal = `${String(competencia).substring(0, 4)}/${String(competencia).substring(4, 6)}/20`;

					if (registro.data >= dataInicial && registro.data <= dataFinal) {	
						listaImportada.push(registro);
					}
				}
			}
		}

		let listaMetas = [];
		await axios.get(`${consts.API_URL}/metaMotorista?competencia=${competencia}`).then(resp => {
			listaMetas = resp.data;
		}).catch(e => {
			setError('Competência não informada');
		});

		listaMetas = listaMetas.map(registro => {
			
			let qtdFolga = 0;
			let qtdTrabalho = 0;
			listaImportada.filter(item => item.motorista == registro.nome_motorista).forEach(item => {
				
				if (item.tipo == 'Folga' || item.tipo == 'Férias' || item.tipo == 'Folga/Fora' || item.tipo == 'ATESTADO') {
					qtdFolga++;
				} else {
					qtdTrabalho++;
				}

			});

			let qtdConsiderar = 30 - qtdFolga;

			console.log(`${registro.nome_motorista} - ${qtdTrabalho} >= ${qtdConsiderar}`);

			return {
				...registro,
				bonificacao_sigest: qtdTrabalho >= qtdConsiderar ? 100 : 0,
				id_usuario_alteracao: getState().auth.usuario.id
			};
		});



		await axios.put(`${consts.API_URL}/metaMotorista/importacao`, { lista: listaMetas }).then(resp => {
			
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setModoTela('lista'));
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'META_MOTORISTA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'META_MOTORISTA_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function imprimir(registro) {
	return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/metaMotorista/imprimir`, {
            ...registro,
            datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
        })
		.then(resp => {
			window.open(`https://api-valedaserra.infinitum-ti.com/${resp.data}`, '_blank');
			// window.open(`http://localhost:2408/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}