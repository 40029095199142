
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	lista: [],
	filtro: {
		id_distribuidora: null,
		id_produto: null
	},
	listaDistribuidora: [],
	listaProduto: []
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
	
		case 'ANALISE_CURVA_PRECO_FILTRO':
            return { ...state,
				filtro: action.payload
			};

        case 'ANALISE_CURVA_PRECO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'ANALISE_CURVA_PRECO_DISTRIBUIDORA_SELECT_LISTADO':
            return {
				...state,
				listaDistribuidora: action.payload.data
			};

        case 'ANALISE_CURVA_PRECO_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

        default:
            return state;
    }
}
