import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Endereco from './endereco/endereco';

import {
	setModoTela, initForm
} from './clienteActions';

class ClienteForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.endereco || this.props.excluir ? 'readOnly' : '';

		if (!this.props.endereco) {
			return (
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='id_petroshow'
									component={LabelAndInputNumber}
									label='ID Petroshow' placeholder='Informe o ID'
									cols='12 6 4 3'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='razao_social'
									component={LabelAndInput}
									label='Razão Social' placeholder='Informe a razão social'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='nome_fantasia'
									component={LabelAndInput}
									label='Nome Fantasia' placeholder='Informe o nome fantasia'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='id_tipo'
									component={Select}
									options={this.props.listaTipo}
									label='Tipo'
									cols='12 12 12 12'
									placeholder='Selecione o tipo'
									readOnly={readOnly} />

								{this.props.formularioValues.id_tipo == 1 ? (
									<Field
										name='cpf'
										component={LabelAndInputMask}
										label='CPF' placeholder='Informe o CPF'
										cols='12 12 12 12'
										mask='999.999.999-99'
										readOnly={readOnly} />
								) : (
									<Field
										name='cnpj'
										component={LabelAndInputMask}
										label='CNPJ' placeholder='Informe o CNPJ'
										cols='12 12 12 12'
										mask='99.999.999/9999-99'
										readOnly={readOnly} />
								)}

								<Field
									name='inscricao_estadual'
									component={LabelAndInput}
									label='Inscrição Estadual' placeholder='Informe a inscrição estadual'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								<Select
									name='dias_cobranca'
									options={Array.from(Array(30).keys()).map(item => ({ id: item + 1, valor: String(item + 1).padStart(2, '0') }))}
									label='Dias para Cobrança'
									cols='12 12 12 12'
									placeholder='Selecione os dias'
									multiple={true}
									readOnly={readOnly}
									value={(this.props.formularioValues.dias_cobranca || []).map(item => ({ value: item.id, label: String(item).padStart(2, '0') }))}
									onChange={value => {
										this.props.initForm({
											...this.props.formularioValues,
											dias_cobranca: value.map(item => item.value)
										});
									}} />
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			)
		} else {

			return (
				<>
					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>
								<Row>
									<Field
										name='id_petroshow'
										component={LabelAndInputNumber}
										label='ID Petroshow' placeholder='Informe o ID'
										cols='12 3 2 2'
										casas={0}
										readOnly={'readOnly'} />

									<Field
										name='razao_social'
										component={LabelAndInput}
										label='Razão Social' placeholder='Informe a razão social'
										cols='12 5 5 5'
										readOnly={'readOnly'} />

									<Field
										name='nome_fantasia'
										component={LabelAndInput}
										label='Nome Fantasia' placeholder='Informe o nome fantasia'
										cols='12 4 5 5'
										readOnly={'readOnly'} />
								</Row>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 6 6'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
					
					<Endereco id_cliente={this.props.formularioValues.id} />

				</>
			);
		}
    }


}

ClienteForm = reduxForm({form: 'clienteForm', destroyOnUnmount: false})(ClienteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('clienteForm')(state),
	registro: state.cliente.registro,
	listaTipo: state.cliente.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
