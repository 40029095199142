import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente } from '../../auth/authActions';
import DateFormat from '../dateFormat/DateFormat';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false, relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) };
	}
	changeOpen() {
		this.setState({ 
			...this.state,
			open: !this.state.open 
		});
	}

	componentWillMount() {
		setInterval(() => {
			this.setState({
				...this.state,
				relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
			});
		}, 30000)
	}

	render() {
		const { nome, login } = this.props.usuario;

		return (
			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-clock" style={{ marginRight: 4 }}></i>{this.state.relogio}
					</a>
				</li>
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-user"></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">Perfil</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-muted text-sm">{nome}</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> Usuário
						<span className="float-right text-muted text-sm">{login}</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" onClick={this.props.logout}
							className="btn btn-block btn-primary btn-sm">Sair</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, setCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
