const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaUnidade: [],
    listaProduto: [],
    listaTanque: [],
    listaCliente: [],
    listaMotorista: [],
    listaVeiculo: [],
    listaTipo: [
        { id: 1, valor: 'Empréstimo Interno' },
        { id: 2, valor: 'Empréstimo Terceiro - Entrada' },
        { id: 3, valor: 'Empréstimo Terceiro - Saída' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TRANSFERENCIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TRANSFERENCIA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'TRANSFERENCIA_UNIDADE_SELECT_LISTADO':
			return {
				...state,
				listaUnidade: action.payload.data
            };

        case 'TRANSFERENCIA_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
            };

        case 'TRANSFERENCIA_TANQUE_SELECT_LISTADO':
			return {
				...state,
				listaTanque: action.payload.data
            };

        case 'TRANSFERENCIA_CLIENTE_SELECT_LISTADO':
			return {
				...state,
				listaCliente: action.payload.data
            };

        case 'TRANSFERENCIA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
            };

        case 'TRANSFERENCIA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };
                      
        default:
            return state;
    }
}
