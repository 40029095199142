const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaGrupo: [],
    listaCompetencia: [
        { id: '202401', valor: '01/2024' }, { id: '202402', valor: '02/2024' }, { id: '202403', valor: '03/2024' }, { id: '202404', valor: '04/2024' },
        { id: '202405', valor: '05/2024' }, { id: '202406', valor: '06/2024' }, { id: '202407', valor: '07/2024' }, { id: '202408', valor: '08/2024' },
        { id: '202409', valor: '09/2024' }, { id: '202410', valor: '10/2024' }, { id: '202411', valor: '11/2024' }, { id: '202412', valor: '12/2024' },
        { id: '202501', valor: '01/2025' }, { id: '202502', valor: '02/2025' }, { id: '202503', valor: '03/2025' }, { id: '202504', valor: '04/2025' },
        { id: '202505', valor: '05/2025' }, { id: '202506', valor: '06/2025' }, { id: '202507', valor: '07/2025' }, { id: '202508', valor: '08/2025' },
        { id: '202509', valor: '09/2025' }, { id: '202510', valor: '10/2025' }, { id: '202511', valor: '11/2025' }, { id: '202512', valor: '12/2025' },
        { id: '202601', valor: '01/2026' }, { id: '202602', valor: '02/2026' }, { id: '202603', valor: '03/2026' }, { id: '202604', valor: '04/2026' },
        { id: '202605', valor: '05/2026' }, { id: '202606', valor: '06/2026' }, { id: '202607', valor: '07/2026' }, { id: '202608', valor: '08/2026' },
        { id: '202609', valor: '09/2026' }, { id: '202610', valor: '10/2026' }, { id: '202611', valor: '11/2026' }, { id: '202612', valor: '12/2026' }
    ],
    listaMotorista: [],
    listaVeiculo: [],
    filtro: {
        competencia: `${new Date().getFullYear()}${String(new Date().getMonth()).padStart(2, '0')}`
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_MOTORISTA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_MOTORISTA_FILTRO':
            return { 
                ...state,
				filtro: action.payload.filtro
			};

        case 'META_MOTORISTA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_MOTORISTA_GRUPO_SELECT_LISTADO':
			return {
				...state,
				listaGrupo: action.payload.data
			};

        case 'META_MOTORISTA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

        case 'META_MOTORISTA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
			};

        default:
            return state;
    }
}
