import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './prospeccaoEventoForm';
import DateFormat from '../../common/dateFormat/DateFormat';
import SelectAlteracao from '../../common/form/selectAlteracao';
import LabelAndTextarea from '../../common/form/labelAndTextarea';

import { setModoTela, initForm, salvar, excluir, getLista, getListaPedido } from './prospeccaoEventoActions';
import FormatUtils from '../../common/formatUtils/FormatUtils';

class ProspeccaoEvento extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista(this.props.id_prospeccao);
		this.props.getListaPedido(this.props.id_prospeccao);
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'prospeccaoEvento' ? (
						<Form prospeccaoEvento onSubmit={this.props.salvar} />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = this.props.lista.map(item => ({
			...item,
			tipoRegistro: 'EVENTO'
		}));
		this.props.listaPedido.forEach(item => {
			lista.push({
				...item,
				descricao: ``
					+ `Giro: ${item.giro}\n`
					+ `Produto: ${item.nome_produto}\n`
					+ `Quantidade: ${FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)} L\n`
					+ (item.concorrente ? `Concorrente: ${item.concorrente}\n` : '')
					+ (item.preco_concorrente ? `Preço Concorrente: ${item.preco_concorrente}\n` : ''),
				nome_tipo_retorno: (
					item.venda_perdida ? 'Venda Perdida' : (
						item.id_status == 1 || item.id_status == 2 ? 'Giro Aberto' : ( //Digitando, Aguardando Aprovação
							item.id_status == 3 ? 'Giro não Aprovado' : ( //Rejeitado
								item.id_status == 4 || item.id_status == 5 || item.id_status == 6 || item.id_status == 7 ? 'Venda Fechada' : '' //Aprovado, Logística Parcial. Logística Finalizada, Finalizado Base
							)
						)
					)
				),
				tipoRegistro: 'PEDIDO'
			});
		});

		lista.sort((a, b) => a.datahora_inclusao > b.datahora_inclusao ? -1 : 1);

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar Evento'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_prospeccao: this.props.id_prospeccao, id_tipo_contato: 5 });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th alignCenter>Evento</Th>
								<Th alignCenter>Retorno</Th>
								<Th alignCenter>Vendedor</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>
										<LabelAndTextarea
											label={null} placeholder='Informe a descrição'
											cols='12 12 12 12'
											row={this.state.linhaSelecionada == item.id ? 5 : 1}
											readOnly={'readOnly'}
											value={item.descricao}
											onChange={() => {}} />
									</Td>
									<Td alignCenter>{item.nome_tipo_retorno}</Td>
									<Td alignCenter>{item.tipoRegistro == 'EVENTO' ? item.nome_usuario_alteracao : item.nome_usuario_inclusao}</Td>
									<Td alignRight minWidth={150}>
										{item.tipoRegistro == 'EVENTO' &&
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													formulario_venda_perdida: {
														...(item.formulario_venda_perdida || {}),
														quantidade: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.quantidade, 0) : null,
														proposta: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.proposta, 4) : null,
														prazo: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.prazo, 0) : null,
														valor_fechado: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.valor_fechado, 4) : null
													}
												});
												this.props.initForm({
													...item,
													formulario_venda_perdida: {
														...(item.formulario_venda_perdida || {}),
														quantidade: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.quantidade, 0) : null,
														proposta: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.proposta, 4) : null,
														prazo: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.prazo, 0) : null,
														valor_fechado: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.valor_fechado, 4) : null
													}
												});
											}} />}
										{item.tipoRegistro == 'EVENTO' &&
										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													formulario_venda_perdida: {
														...(item.formulario_venda_perdida || {}),
														quantidade: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.quantidade, 0) : null,
														proposta: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.proposta, 4) : null,
														prazo: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.prazo, 0) : null,
														valor_fechado: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.valor_fechado, 4) : null
													}
												});
												this.props.initForm({
													...item,
													formulario_venda_perdida: {
														...(item.formulario_venda_perdida || {}),
														quantidade: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.quantidade, 0) : null,
														proposta: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.proposta, 4) : null,
														prazo: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.prazo, 0) : null,
														valor_fechado: item.formulario_venda_perdida ? FormatUtils.formatarValorTela(item.formulario_venda_perdida.valor_fechado, 4) : null
													}
												});
											}} />}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.prospeccaoEvento.modoTela,
	lista: state.prospeccaoEvento.lista,
	listaPedido: state.prospeccaoEvento.listaPedido
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaPedido }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProspeccaoEvento);
