
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	filtro: {
		data: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ADICIONAL_DIARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		
		case 'ADICIONAL_DIARIO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'ADICIONAL_DIARIO_FILTRO':
            return { ...state,
				filtro: action.payload
			};

        case 'ADICIONAL_DIARIO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};


        default:
            return state;
    }
}
