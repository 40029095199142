import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaVendedorForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';

import { setModoTela, initForm, salvar, excluir, getLista, getListaVendedor } from './metaVendedorActions';

class MetaVendedor extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaVendedor();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							options={listaCompetencia}
							label='Competência'
							cols='12 4 4 3'
							placeholder='Selecione a competência'
							value={this.state.competencia}
							onChange={value => this.setState({ ...this.state, competencia: value })} />
							
						<Select
							options={this.props.listaVendedor}
							label='Vendedor'
							cols='12 8 8 9'
							placeholder='Selecione o vendedor'
							value={this.state.id_vendedor}
							onChange={value => this.setState({ ...this.state, id_vendedor: value })} />
					</Row>

					<Table responsive>
						<THead>
							<Tr>
								<Th>Competência</Th>
								<Th>Vendedor</Th>
								<Th alignCenter>Volume</Th>
								<Th alignCenter>Margem Global</Th>
								<Th alignCenter>Inadimplência Global</Th>
								<Th alignCenter>Prazo Global</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.competencia && item.competencia != this.state.competencia) {
									return false;
								}
								if (this.state.id_vendedor && item.id_vendedor != this.state.id_vendedor) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{FormatUtils.formatarCompetenciaTela(item.competencia)}</Td>
									<Td>{item.nome_vendedor}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.volume, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.margem, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.inadimplencia, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.prazo, 2)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													volume: FormatUtils.formatarValorTela(item.volume, 2),
													margem: FormatUtils.formatarValorTela(item.margem, 2),
													inadimplencia: FormatUtils.formatarValorTela(item.inadimplencia, 2),
													prazo: FormatUtils.formatarValorTela(item.prazo, 2),
												});
												this.props.initForm({
													...item,
													volume: FormatUtils.formatarValorTela(item.volume, 2),
													margem: FormatUtils.formatarValorTela(item.margem, 2),
													inadimplencia: FormatUtils.formatarValorTela(item.inadimplencia, 2),
													prazo: FormatUtils.formatarValorTela(item.prazo, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item,
													volume: FormatUtils.formatarValorTela(item.volume, 2),
													margem: FormatUtils.formatarValorTela(item.margem, 2),
													inadimplencia: FormatUtils.formatarValorTela(item.inadimplencia, 2),
													prazo: FormatUtils.formatarValorTela(item.prazo, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.metaVendedor,
	modoTela: state.metaVendedor.modoTela,
	lista: state.metaVendedor.lista,
	listaVendedor: state.metaVendedor.listaVendedor
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaVendedor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendedor);
