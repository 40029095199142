import React from 'react'
import { getPreciseDistance, getCenter } from "geolib";
import {
	GoogleMap,
	Marker,
	LoadScript,
	StandaloneSearchBox,
	DirectionsRenderer,
	useJsApiLoader
} from '@react-google-maps/api';
//https://github.com/fcbergmann/my-map/blob/main/src/pages/MapPage.tsx
import {
	geocodeByPlaceId
} from 'react-places-autocomplete';

import Row from '../layout/row';
import Grid from '../layout/grid';
import Button from '../button/button';
import { getDistance } from 'geolib';
import LabelAndInput from '../form/labelAndInput';
import axios from 'axios';
import { setError } from '../toast/toast';
import markerAzul from '../../assets/mapa/marker_azul.png';
import markerAzulTag from '../../assets/mapa/marker_azul_tag.png';
import markerVerde from '../../assets/mapa/marker_verde.png';
import markerVerdeTag from '../../assets/mapa/marker_verde_tag.png';
import markerAmarelo from '../../assets/mapa/marker_amarelo.png';
import markerAmareloTag from '../../assets/mapa/marker_amarelo_tag.png';
import markerLaranja from '../../assets/mapa/marker_laranja.png';
import markerLaranjaTag from '../../assets/mapa/marker_laranja_tag.png';
import markerVermelho from '../../assets/mapa/marker_vermelho.png';
import markerVermelhoTag from '../../assets/mapa/marker_vermelho_tag.png';
import markerHome from '../../assets/mapa/marker_home.png';
import markerVenda from '../../assets/mapa/marker_venda.png';
import SelectDefault from '../form/selectDefault';

export default props => {

	const [directionsRef, setDirectionsRef] = React.useState(null);
	const [places, setPlaces] = React.useState([]);

	const [raio, setRaio] = React.useState(10000);

	const containerStyle = {
		width: '100%',
		height: window.innerHeight * 0.80
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAK578qzVAohLyd3UEMQzUbDYExVITWloE'
	});

	const icones = [
		{ nome: 'marker-azul', icone: markerAzul },
		{ nome: 'marker-azul-tag', icone: markerAzulTag },
		{ nome: 'marker-verde', icone: markerVerde },
		{ nome: 'marker-verde-tag', icone: markerVerdeTag },
		{ nome: 'marker-amarelo', icone: markerAmarelo },
		{ nome: 'marker-amarelo-tag', icone: markerAmareloTag },
		{ nome: 'marker-laranja', icone: markerLaranja },
		{ nome: 'marker-laranja-tag', icone: markerLaranjaTag },
		{ nome: 'marker-vermelho', icone: markerVermelho },
		{ nome: 'marker-vermelho-tag', icone: markerVermelhoTag },
		{ nome: 'marker-home', icone: markerHome },
		{ nome: 'marker-venda', icone: markerVenda }
	];

	let calcularRota = async (itens) => {

		try {

			// eslint-disable-next-line no-undef
			const DirectionsService = new google.maps.DirectionsService();

			let result = await DirectionsService.route({
				origin: { lat: parseFloat(props.origem.latitude), lng: parseFloat(props.origem.longitude) },
				destination: { lat: parseFloat(props.destino.latitude), lng: parseFloat(props.destino.longitude) },
				waypoints: itens ? itens.map(item => ({ location: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, stopover: false })) : (props.rota && props.rota.waypoints ? props.rota.waypoints : []).map(waypoint => ({ location: { lat: waypoint.latitude, lng: waypoint.longitude }, stopover: true })),
				optimizeWaypoints: true,
				travelMode: 'DRIVING',
			});

			if (result.status == 'OK') {

				let waypoints = [];

				for (var i = 1; i < result.geocoded_waypoints.length - 1; i++) {
					let waypoint = result.geocoded_waypoints[i];
					await geocodeByPlaceId(waypoint.place_id).then(results => {
						waypoints.push({ latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() });
					}).catch(error => {
						console.log(error);		
					});
				}

				let link = `https://www.google.com/maps/dir/?api=1&origin=${props.origem.latitude},${props.origem.longitude}&destination=${props.destino.latitude},${props.destino.longitude}&waypoints=${waypoints.map(waypoint => `${waypoint.latitude},${waypoint.longitude}`).join('|')}&travelmode=driving`;

				let pontos = [];
				let passosLatLong = [];

				for (var i = 0; i < result.routes[0].legs.length; i++) {
					let leg = result.routes[0].legs[i];

					for (var j = 0; j < leg.steps.length; j++) {
						let item = leg.steps[j];

						const pontoA = { latitude: item.start_location.lat(), longitude: item.start_location.lng() };
						const pontoB = { latitude: item.end_location.lat(), longitude: item.end_location.lng() };

						const distancia = getPreciseDistance({ lat: pontoA.latitude, lng: pontoA.longitude }, { lat: pontoB.latitude, lng: pontoB.longitude });

						passosLatLong.push(pontoA);

						let centro1 = null;
						let centro2 = null;
						let centro3 = null;
						let centro4 = null;
						let centro5 = null;
						let centro6 = null;
						let centro7 = null;
						let centro8 = null;
						let centro9 = null;
						let centro10 = null;
						let centro11 = null;
						let centro12 = null;
						let centro13 = null;
						let centro14 = null;
						let centro15 = null;

						if (distancia > 10000) {
							centro1 = getCenter([pontoA, pontoB]);
							passosLatLong.push(centro1);
						}

						if (distancia > 20000) {
							centro2 = getCenter([pontoA, centro1]);
							passosLatLong.push(centro2);
							centro3 = getCenter([centro1, pontoB]);
							passosLatLong.push(centro3);
						}

						if (distancia > 40000) {
							centro4 = getCenter([pontoA, centro2]);
							passosLatLong.push(centro4);
							centro5 = getCenter([centro2, centro1]);
							passosLatLong.push(centro5);

							centro6 = getCenter([centro1, centro3]);
							passosLatLong.push(centro6);
							centro7 = getCenter([centro3, pontoB]);
							passosLatLong.push(centro7);
						}

						if (distancia > 100000) {
							centro8 = getCenter([pontoA, centro4]);
							passosLatLong.push(centro9);

							centro9 = getCenter([centro4, centro2]);
							passosLatLong.push(centro9);

							centro10 = getCenter([centro2, centro5]);
							passosLatLong.push(centro10);

							centro11 = getCenter([centro5, centro1]);
							passosLatLong.push(centro11);

							centro12 = getCenter([centro1, centro6]);
							passosLatLong.push(centro12);

							centro13 = getCenter([centro6, centro3]);
							passosLatLong.push(centro13);

							centro14 = getCenter([centro3, centro7]);
							passosLatLong.push(centro14);

							centro15 = getCenter([centro7, pontoB]);
							passosLatLong.push(centro15);
						}

						passosLatLong.push(pontoB);

						let pontoAux = (props.pontos || []).filter(pontoAux => !pontos.filter(ponto => ponto.id == pontoAux.id).length);

						for (var k = 0; k < pontoAux.length; k++) {

							let ponto = pontoAux[k];
							if (ponto.latitude && ponto.longitude) {

								let distanciaOrigem = getDistance({ latitude: item.start_location.lat(), longitude: item.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
								let distanciaDestino = getDistance({ latitude: item.end_location.lat(), longitude: item.end_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
								let distanciaCentro1 = centro1 ? getDistance({ latitude: centro1.latitude, longitude: centro1.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro2 = centro2 ? getDistance({ latitude: centro2.latitude, longitude: centro2.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro3 = centro3 ? getDistance({ latitude: centro3.latitude, longitude: centro3.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro4 = centro4 ? getDistance({ latitude: centro4.latitude, longitude: centro4.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro5 = centro5 ? getDistance({ latitude: centro5.latitude, longitude: centro5.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro6 = centro6 ? getDistance({ latitude: centro6.latitude, longitude: centro6.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro7 = centro7 ? getDistance({ latitude: centro7.latitude, longitude: centro7.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro8 = centro8 ? getDistance({ latitude: centro8.latitude, longitude: centro8.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro9 = centro9 ? getDistance({ latitude: centro9.latitude, longitude: centro9.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro10 = centro10 ? getDistance({ latitude: centro10.latitude, longitude: centro10.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro11 = centro11 ? getDistance({ latitude: centro11.latitude, longitude: centro11.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro12 = centro12 ? getDistance({ latitude: centro12.latitude, longitude: centro12.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro13 = centro13 ? getDistance({ latitude: centro13.latitude, longitude: centro13.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro14 = centro14 ? getDistance({ latitude: centro14.latitude, longitude: centro14.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								let distanciaCentro15 = centro15 ? getDistance({ latitude: centro15.latitude, longitude: centro15.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
								
								if (distanciaOrigem < raio || distanciaDestino < raio 
									|| (distanciaCentro1 && distanciaCentro1 < raio)
									|| (distanciaCentro2 && distanciaCentro2 < raio)
									|| (distanciaCentro3 && distanciaCentro3 < raio)
									|| (distanciaCentro4 && distanciaCentro4 < raio)
									|| (distanciaCentro5 && distanciaCentro5 < raio)
									|| (distanciaCentro6 && distanciaCentro6 < raio)
									|| (distanciaCentro7 && distanciaCentro7 < raio)
									|| (distanciaCentro8 && distanciaCentro8 < raio)
									|| (distanciaCentro9 && distanciaCentro9 < raio)
									|| (distanciaCentro10 && distanciaCentro10 < raio)
									|| (distanciaCentro11 && distanciaCentro11 < raio)
									|| (distanciaCentro12 && distanciaCentro12 < raio)
									|| (distanciaCentro13 && distanciaCentro13 < raio)
									|| (distanciaCentro14 && distanciaCentro14 < raio)
									|| (distanciaCentro15 && distanciaCentro15 < raio)) {

									if (!pontos.filter(item => item.id == ponto.id)[0]) {
										pontos.push({ ...ponto });
									}
								}
							}
						}
					}
				}

				props.setRota({
					rotaCarregada: result,
					origem: { nome: props.origem.nome, latitude: props.origem.latitude, longitude: props.origem.longitude },
					destino: { nome: props.destino.nome, latitude: props.destino.latitude, longitude: props.destino.longitude },
					itens: (props.itens || []).map(item => ({ nome: item.nome, latitude: item.latitude, longitude: item.longitude })),
					waypoints: waypoints,
					link_google_maps: link,
					distancia: result.routes[0].legs[0].distance,
					passos_localizacao: !itens && props.rota ? props.rota.passos_localizacao : passosLatLong,
					pontos: pontos,
					overview_polyline: result.routes[0].overview_polyline
				});

			} else {			
				props.setErro();
			}
		} catch(ex) {
			props.setErro();			
		}
	}

	if (props.rota && !props.rotaCarregada) {
		calcularRota();
	}

	if (isLoaded) {
		return (
			<>
				<Row alignCenter style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<SelectDefault 
						options={[{ id: 10000, valor: '10 km' }, { id: 25000, valor: '25 km' }, { id: 50000, valor: '50km' }, { id: 100000, valor: '100 km' }, { id: 150000, valor: '150 km' }, { id: 200000, valor: '200 km' }]}
						label='Clientes Próximos'
						cols='12 6 4 3'
						placeholder='Selecione o raio'
						readOnly={null}
						value={raio}
						onChange={data => {
							setRaio(data.target.value || 0);
						}} />

					<Grid cols='12 6 4 3' style={{ marginTop: 14 }}>
						<Button
							text='Montar Rota'
							type={'primary'}
							icon={'fa fa-chevron-left'}
							event={() => {
								calcularRota(props.itens || []);
							}} />
					</Grid>
				</Row>
				
				{!!props.rota && 
				<Row>
					<LabelAndInput
						label='Link Google Maps' placeholder=''
						cols='12 8 10 10'
						value={props.rota ? props.rota.link_google_maps : null}
						onChange={() => { }} />
					
					<LabelAndInput
						label='Distância (km)' placeholder=''
						cols='12 4 2 2'
						value={props.rota && props.rota.distancia ? props.rota.distancia.text : ''}
						onChange={() => { }} />
				</Row>}

				{props.rotaCarregada ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						zoom={10}
						onLoad={map => {

						}}
						onClick={(e) => {
							console.log(e);
							
						}} >

						{false && props.rota && props.rota.origem &&
							<Marker
								position={{ lat: parseFloat(props.rota.origem.latitude), lng: parseFloat(props.rota.origem.longitude) }}
								options={{
									label: {
										text: props.rota.origem.nome,
										className: 'map-marker'
									},
									icon: {
										url: markerHome
									}
								}} />}

						{false && props.rota && props.rota.itens ? props.rota.itens.map((item, i) => {

							return (
								<Marker key={i}
									position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
									
									options={{
										label: {
											text: item.nome,
											className: 'map-marker'
										},
										icon: {
											url: markerVenda
										}
									}} />
							);
						}) : null}

						{props.rota && props.rota.pontos ? props.rota.pontos.map((item, i) => {

							let icone = icones.filter(itemIcone => itemIcone.nome == item.icone)[0] ? icones.filter(itemIcone => itemIcone.nome == item.icone)[0].icone : null;
							
							return (
								<Marker key={item.id}
									position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
									options={{
										label: {
											text: '.',
											className: 'map-marker'
										},
										title: `${item.nome}`,
										icon: {
											url: icone
										},
										click: () => {
											console.log('ex');											
										},
										onClick: () => {
											console.log('ex');											
										}
									}}
									onClick={() => props.onClickPonto(item.id)} />
							);
						}) : null}

						{props.rotaCarregada ? (
							<DirectionsRenderer
								directions={props.rotaCarregada}
								ref={ref => setDirectionsRef(ref)}
								options={{
									suppressMarkers: true,
									draggable: true
								}}
								onDirectionsChanged={async (e, a) => {

									if (directionsRef && directionsRef.state && directionsRef.state.directionsRenderer && directionsRef.state.directionsRenderer.directions
										&& directionsRef.state.directionsRenderer.directions && directionsRef.state.directionsRenderer.directions.geocoded_waypoints.length != props.rotaCarregada.geocoded_waypoints.length) {

										let waypoints = [];

										for (var i = 1; i < directionsRef.state.directionsRenderer.directions.geocoded_waypoints.length -1; i++) {
											let waypoint = directionsRef.state.directionsRenderer.directions.geocoded_waypoints[i];
											await geocodeByPlaceId(waypoint.place_id).then(results => {
												if (waypoints.length < 8) {
													waypoints.push({ latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() });
												} else {
													setError('Você não pode mais selecionar pontos.');
												}
											}).catch(error => console.error(error));
										}

										let link = `https://www.google.com/maps/dir/?api=1&origin=${props.origem.latitude},${props.origem.longitude}&destination=${props.destino.latitude},${props.destino.longitude}&waypoints=${waypoints.map(waypoint => `${waypoint.latitude},${waypoint.longitude}`).join('|')}&travelmode=driving`;

										let pontos = [];
										let passosLatLong = [];

										for (var i = 0; i < directionsRef.state.directionsRenderer.directions.routes[0].legs.length; i++) {
											let leg = directionsRef.state.directionsRenderer.directions.routes[0].legs[i];
											
											for (var j = 0; j < leg.steps.length; j++) {
												let item = leg.steps[j];									

												const pontoA = { latitude: item.start_location.lat(), longitude: item.start_location.lng() };
												const pontoB = { latitude: item.end_location.lat(), longitude: item.end_location.lng() };
												passosLatLong.push(pontoA);
												passosLatLong.push(pontoB);												
											}
										}

										props.setRota({
											rotaCarregada: directionsRef.state.directionsRenderer.directions,
											origem: { nome: props.origem.nome, latitude: props.origem.latitude, longitude: props.origem.longitude },
											destino: { nome: props.destino.nome, latitude: props.destino.latitude, longitude: props.destino.longitude },
											waypoints: waypoints,
											itens: (props.itens || []).map(item => ({ nome: item.nome, latitude: item.latitude, longitude: item.longitude })),
											link_google_maps: link,
											distancia: directionsRef.state.directionsRenderer.directions.routes[0].legs[0].distance,
											passos_localizacao: passosLatLong,
											pontos: pontos,
											overview_polyline: directionsRef.state.directionsRenderer.directions.routes[0].overview_polyline
										});
									}
								}} />
						) : null}

					</GoogleMap>
				) : null}
			</>
		);
	} else {
		return null;
	}
}
