import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import ColorUtils from '../common/colorUtils/ColorUtils';

import { getLista, setFiltro, getListaUnidade, getListaProduto, getListaDistribuidora, getListaCidade } from './precoGeralActions';

class PrecoGeral extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaUnidade();
		this.props.getListaProduto();
		this.props.getListaDistribuidora();
		this.props.getListaCidade();
    }

    render() {

		let lista = this.props.lista.filter(item => {
			if (this.props.filtro.id_distribuidora && item.id_distribuidora != this.props.filtro.id_distribuidora) {
				return false;
			}
			if (this.props.filtro.id_cidade && item.id_cidade != this.props.filtro.id_cidade) {
				return false;
			}
			return true;
		});
console.log(lista);

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data' placeholder='Informe a data'
							cols='6 6 4 2'
							mask='99/99/9999'
							value={this.props.filtro.data}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data: data.target.value });
								this.props.getLista();
							}} />

						<Select
							label='Unidade' placeholder='Selecione a unidade'
							cols='6 6 4 3'
							options={this.props.listaUnidade}
							value={this.props.filtro.id_unidade}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_unidade: value });
								this.props.getLista();
							}} />

						<Select
							label='Produto' placeholder='Selecione o produto'
							cols='6 6 4 3'
							options={this.props.listaProduto}
							value={this.props.filtro.id_produto}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_produto: value });
								this.props.getLista();
							}} />

						<Select
							label='Distribuidora' placeholder='Selecione o distribuidora'
							cols='6 6 4 2'
							options={this.props.listaDistribuidora}
							value={this.props.filtro.id_distribuidora}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_distribuidora: value });
							}} />

						<Select
							label='Cidade' placeholder='Selecione o cidade'
							cols='6 6 4 2'
							options={this.props.listaCidade}
							value={this.props.filtro.id_cidade}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_cidade: value });
							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}>Distribuidora</Th>
								<Th width={200}>Cidade</Th>
								<Th width={100} alignCenter>Preço Original 
									<i class='fas fa-sort-amount-down'
										style={{ marginLeft: 4, color: this.props.filtro.ordem == 'preco_dia' ? null : '#d3d3d3' }}
										onClick={() => {
											this.props.setFiltro({ ...this.props.filtro, ordem: 'preco_dia' });
											this.props.getLista();
										}}>
									</i>
								</Th>
								<Th width={100} alignCenter>Preço 30 dias 
									<i class='fas fa-sort-amount-down'
										style={{ marginLeft: 4, color: this.props.filtro.ordem == 'preco_30' ? null : '#d3d3d3' }}
										onClick={() => {
											this.props.setFiltro({ ...this.props.filtro, ordem: 'preco_30' });
											this.props.getLista();
										}}>
									</i>
								</Th>
								<Th width={100} alignCenter>Preço 30 dias + frete
									<i class='fas fa-sort-amount-down'
										style={{ marginLeft: 4, color: this.props.filtro.ordem == 'preco_30_frete' ? null : '#d3d3d3' }}
										onClick={() => {
											this.props.setFiltro({ ...this.props.filtro, ordem: 'preco_30_frete' });
											this.props.getLista();
										}}>
									</i>
								</Th>
								<Th width={100} alignCenter>Diferença</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map((item, i) => {
								
								let melhor = lista[0];

								return (
									<Tr key={item.id} >
										<Td minWidth={200}>{item.nome_distribuidora}</Td>
										<Td minWidth={200}>{item.nome_cidade}</Td>
										<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.preco_dia, 4)}</Td>
										<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.preco_30, 4)}</Td>
										<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.preco_30_frete, 4)}</Td>
										<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(melhor ? parseFloat(item.preco_30_frete || 0) - parseFloat(melhor.preco_30_frete || 0) : 0, 4)}</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	lista: state.precoGeral.lista,
	filtro: state.precoGeral.filtro,
	listaUnidade: state.precoGeral.listaUnidade,
	listaProduto: state.precoGeral.listaProduto,
	listaDistribuidora: state.precoGeral.listaDistribuidora,
	listaCidade: state.precoGeral.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, setFiltro, getListaUnidade, getListaProduto, getListaDistribuidora, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrecoGeral);
