import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_PEDIDO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaDiario() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboardPedido?tipo=diario`);
    return {
        type: 'DASHBOARD_PEDIDO_DIARIO_LISTADO',
        payload: request
    };
}

export function getListaMensal() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboardPedido?tipo=mensal`);
    return {
        type: 'DASHBOARD_PEDIDO_MENSAL_LISTADO',
        payload: request
    };
}

export function getListaAnual() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboardPedido?tipo=anual`);
    return {
        type: 'DASHBOARD_PEDIDO_ANUAL_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'DASHBOARD_PEDIDO_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'DASHBOARD_PEDIDO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}