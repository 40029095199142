import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './entradaForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, carregar, descarregar, excluir, 
	getLista, getListaProduto, getListaUnidade, getListaVeiculo, 
	getListaMotorista, getListaDistribuidora, getListaTanque,
	getListaPedido
} from './entradaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class Entrada extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 604800000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaProduto();
		this.props.getListaUnidade();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
		this.props.getListaDistribuidora();
		this.props.getListaTanque();
		this.props.getListaPedido();

		let idInterval = setInterval(() => {
			this.props.getLista();
			this.props.getListaProduto();
			this.props.getListaUnidade();
			this.props.getListaVeiculo();
			this.props.getListaMotorista();
			this.props.getListaDistribuidora();
			this.props.getListaTanque();
			this.props.getListaPedido();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
	}

    render() {
        return (
            <div>
                <Content>

					{this.props.listaVeiculo.filter(item => item.documentacao_vencida).length > 0 && 
					<Row>
						{this.props.listaVeiculo.filter(item => item.documentacao_vencida).map(item => (
							<Grid cols='12 6 4 4'>
								<div class="alert alert-danger alert-dismissible">
									<h5><i class="icon fas fa-ban"></i> {item.valor}</h5>
									<h6>Problemas na documentação!</h6>
								</div>
							</Grid>
						))}
					</Row>}

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let listaExportacao = [];
		this.props.lista.forEach(item => {
			(item.itens || []).forEach(entradaItem => {
				listaExportacao.push({
					...item,
					id: `${item.id}-${entradaItem.id}`,					
					datahora_programacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_programacao),
					datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
					datahora_previsao_chegada: item.datahora_previsao_chegada ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada) : null,
					datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
					nome_produto: entradaItem.nome_produto,
					quantidade: FormatUtils.formatarValorTela(entradaItem.quantidade, 2)
				});
			});
		});
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({
										entrada: true, 
										ponto_atendimento: false, 
										vendedor: false, 
										datahora_previsao_chegada: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) 
									});
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Entradas'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={listaExportacao.map(item => ({
									...item,
								}))} name='Entradas'>
									<ExcelColumn label='Data Hora' value='datahora_programacao' />
									<ExcelColumn label='Tipo' value='nome_tipo' />
									<ExcelColumn label='Unidade' value='nome_unidade' />
									<ExcelColumn label='Distribuidora' value='nome_distribuidora' />
									<ExcelColumn label='Produto' value='nome_produto' />
									<ExcelColumn label='Quantidade' value='quantidade' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Veículo' value='placa_veiculo' />
									<ExcelColumn label='Número da Nota' value='numero_nfe' />
									<ExcelColumn label='Carga' value='datahora_carga' />
									<ExcelColumn label='Previsão Descarga' value='datahora_previsao_chegada' />
									<ExcelColumn label='Descarga' value='datahora_descarga' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Data Hora</Th>
								<Th colspan={1}>Tipo</Th>
								<Th colspan={1}>Unidade</Th>
								<Th colspan={1}>Distribuidora</Th>
								<Th colspan={1}>Motorista</Th>
								<Th colspan={1}>Veículo</Th>
								<Th colspan={1} alignCenter>Número da Nota</Th>
								<Th colspan={1} alignCenter>Carga</Th>
								<Th colspan={1} alignCenter>Previsão Descarga</Th>
								<Th colspan={1} alignCenter>Descarga</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_tipo} ${item.nome_unidade || ''} ${item.nome_distribuidora || ''} ${item.nome_motorista || ''} ${item.numero_nfe || ''} ${item.placa_veiculo || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.state.dataInicial
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora_programacao)) < DateFormat.formatarDataTelaParaSql(this.state.dataInicial)) {
									return false;
								}
								if (this.state.dataFinal
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora_programacao)) > DateFormat.formatarDataTelaParaSql(this.state.dataFinal)) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_programacao)}</Td>
									<Td>{item.nome_tipo}</Td>
									<Td>{item.nome_unidade}</Td>
									<Td>{item.nome_distribuidora}</Td>
									<Td>{item.nome_motorista}</Td>
									<Td>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.numero_nfe}</Td>
									<Td alignCenter>{item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : (
										<ButtonTable
											type={'success'}
											icon={'fas fa-check'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.carregar(item);
											}} />
									)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada)}</Td>
									<Td alignCenter>{
										item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) 
										: (item.datahora_carga ? (
											<ButtonTable
												type={'success'}
												icon={'fas fa-check'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.descarregar(item);
												}} />
										) : null)}
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_previsao_chegada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada),
													itens: (item.itens || []).map(entradaItem => ({
														...entradaItem,
														quantidade: FormatUtils.formatarValorTela(entradaItem.quantidade, 2)
													}))
												});
												this.props.initForm({
													...item,
													datahora_previsao_chegada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada),
													itens: (item.itens || []).map(entradaItem => ({
														...entradaItem,
														quantidade: FormatUtils.formatarValorTela(entradaItem.quantidade, 2)
													}))
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_previsao_chegada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada),
													itens: (item.itens || []).map(entradaItem => ({
														...entradaItem,
														quantidade: FormatUtils.formatarValorTela(entradaItem.quantidade, 2)
													}))
												});
												this.props.initForm({
													...item,
													datahora_previsao_chegada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada),
													itens: (item.itens || []).map(entradaItem => ({
														...entradaItem,
														quantidade: FormatUtils.formatarValorTela(entradaItem.quantidade, 2)
													}))
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.entrada,
	modoTela: state.entrada.modoTela,
	lista: state.entrada.lista,
    listaVeiculo: state.entrada.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, carregar, descarregar, excluir, 
	getLista, getListaProduto, getListaUnidade, getListaVeiculo, 
	getListaMotorista, getListaDistribuidora, getListaTanque,
	getListaPedido
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Entrada);
