import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VEICULO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('veiculoForm', registro)
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'VEICULO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/veiculo`);
    return {
        type: 'VEICULO_LISTADO',
        payload: request
    };
}

export function getListaDashboard() {
    
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/veiculo/listarDashboard`).then(resp => {
            dispatch({
                type: 'VEICULO_DASHBOARD_LISTADO',
                payload: resp
            });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
            dispatch(setAguardando(false));
		});
    };
}

export function getListaDashboardKmRodado(dataInicial, dataFinal) {
    
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        dataInicial = DateFormat.formatarDataTelaParaSql(dataInicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataInicial);
        dataFinal = DateFormat.formatarDataTelaParaSql(dataFinal) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(dataFinal);
		axios.get(`${consts.API_URL}/veiculo/listarDashboardKmRodado?data_inicial=${dataInicial}&data_final=${dataFinal}`).then(resp => {
            dispatch({
                type: 'VEICULO_DASHBOARD_KM_RODADO_LISTADO',
                payload: resp
            });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
            dispatch(setAguardando(false));
		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/veiculo`, {
				...registro,
				capacidade: FormatUtils.formatarValorBanco(registro.capacidade, 0),
				compartimentos: (registro.compartimentos || []).map(compartimento => FormatUtils.formatarValorBanco(compartimento, 0)),
                cnpj: FormatUtils.removerMascara(registro.cnpj),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/veiculo`, {
				...registro,
				capacidade: FormatUtils.formatarValorBanco(registro.capacidade, 0),
				compartimentos: (registro.compartimentos || []).map(compartimento => FormatUtils.formatarValorBanco(compartimento, 0)),
                cnpj: FormatUtils.removerMascara(registro.cnpj),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {				
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/veiculo?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function alterarConfiguracao(registro) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		if (!registro.id_conf) {
	        axios.post(`${consts.API_URL}/veiculoConfiguracao`, {
				...registro,
                id_unidade: registro.id_unidade_conf,
                id_veiculo: registro.id,
                id_motorista: registro.id_motorista_conf,
                id_carreta1: registro.id_carreta1_conf,
                id_carreta2: registro.id_carreta2_conf,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDashboard());
			}).catch(e => {
				setErroAPI(e);
                dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/veiculoConfiguracao`, {
				id: registro.id_conf,
                id_unidade: registro.id_unidade_conf,
                id_veiculo: registro.id_veiculo_conf,
                id_motorista: registro.id_motorista_conf,
                id_carreta1: registro.id_carreta1_conf,
                id_carreta2: registro.id_carreta2_conf,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {				
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaDashboard());
			}).catch(e => {
				setErroAPI(e);
                dispatch(setAguardando(false));
			});
		}
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/veiculoTipo/listarSelect`);
    return {
        type: 'VEICULO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEntradas() {
    const request = axios.get(`${consts.API_URL}/entrada/listarDashboard`);
    return {
        type: 'VEICULO_DASHBOARD_ENTRADA_LISTADO',
        payload: request
    };
}

export function getListaSaidas() {
    const request = axios.get(`${consts.API_URL}/saida/listarDashboard`);
    return {
        type: 'VEICULO_DASHBOARD_SAIDA_LISTADO',
        payload: request
    };
}


export function getListaTransferencias() {
    const request = axios.get(`${consts.API_URL}/transferencia/listarDashboard`);
    return {
        type: 'VEICULO_DASHBOARD_TRANSFERENCIA_LISTADO',
        payload: request
    };
}

export function getListaPosicaoVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/posicao`);
    return {
        type: 'VEICULO_DASHBOARD_POSICAO_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'VEICULO_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'VEICULO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaReboque() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'VEICULO_REBOQUE_SELECT_LISTADO',
        payload: request
    };
}

