import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setFiltro(filtro) {
    return {
        type: 'PRECO_GERAL_FILTRO',
        payload: filtro
    };
}


export function getLista() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/precoDia/listarGeral?
			data=${DateFormat.formatarDataTelaParaSql(getState().precoGeral.filtro.data)}
			&id_unidade=${getState().precoGeral.filtro.id_unidade || 0}
			&id_produto=${getState().precoGeral.filtro.id_produto || 0}
			&ordem=${getState().precoGeral.filtro.ordem}`)
		.then(resp => {
			dispatch({
		        type: 'PRECO_GERAL_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'PRECO_GERAL_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'PRECO_GERAL_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaDistribuidora() {
    const request = axios.get(`${consts.API_URL}/distribuidora/listarSelect`);
    return {
        type: 'PRECO_GERAL_DISTRIBUIDORA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect?selecionadas=true`);
    return {
        type: 'PRECO_GERAL_CIDADE_SELECT_LISTADO',
        payload: request
	};
}