import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ENDERECO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('enderecoForm', registro)
    ];
}

export function getLista(id_cliente) {
    const request = axios.get(`${consts.API_URL}/endereco?id_cliente=${id_cliente}`);
    return {
        type: 'ENDERECO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/endereco`, {
				...registro,
				telefone: String(registro.telefone || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				celular: String(registro.celular || '').split("(").join("").replace(")", "").replace(" ", "").replace(" ", "").replace("-", ""),
				id_petroshow: FormatUtils.formatarValorBanco(registro.id_petroshow, 0),
				capacidade_tanque: FormatUtils.formatarValorBanco(registro.capacidade_tanque, 0),
				intervalo_compra: FormatUtils.formatarValorBanco(registro.intervalo_compra, 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_cliente));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/endereco`, {
				...registro,
				telefone: String(registro.telefone || '').split("(").join("").replace(")", "").replace(" ", "").replace("-", ""),
				celular: String(registro.celular || '').split("(").join("").replace(")", "").replace(" ", "").replace(" ", "").replace("-", ""),
				id_petroshow: FormatUtils.formatarValorBanco(registro.id_petroshow, 0),
				capacidade_tanque: FormatUtils.formatarValorBanco(registro.capacidade_tanque, 0),
				intervalo_compra: FormatUtils.formatarValorBanco(registro.intervalo_compra, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_cliente));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/endereco?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_cliente));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'ENDERECO_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeculoTipo() {
    const request = axios.get(`${consts.API_URL}/veiculoTipo/listarSelect`);
    return {
        type: 'ENDERECO_VEICULO_TIPO_SELECT_LISTADO',
        payload: request
    };
}
