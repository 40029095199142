import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './motoristaActions';

class MotoristaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='cpf'
								component={LabelAndInputMask}
								label='CPF' placeholder='Informe o CPF'
								cols='12 12 12 12'
								mask='999.999.999-99'
								readOnly={readOnly} />

							<Field
								name='celular'
								component={LabelAndInputMask}
								label='Celular' placeholder='Informe o Celular'
								cols='12 12 12 12'
								mask='(99) 9 9999-9999'
								readOnly={readOnly} />

							<Field
								name='categoria'
								component={Select}
								options={this.props.listaCategoria}
								label='Categoria'
								cols='12 12 12 12'
								placeholder='Selecione a categoria'
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

MotoristaForm = reduxForm({form: 'motoristaForm', destroyOnUnmount: false})(MotoristaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('motoristaForm')(state),
	registro: state.motorista.registro,
	listaCategoria: state.motorista.listaCategoria
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MotoristaForm);
