import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';

import { setModoTela, initForm } from './metaVendaActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import FormatUtils from '../common/formatUtils/FormatUtils';

class MetaVendaForm extends Component {

	state = {
		semestre: 1
	}

    componentWillMount() {
	}
	
	recalcular(registro) {

		const percentualVolume = parseFloat(FormatUtils.formatarValorBanco(registro.percentual_volume, 2) || 0);

		const pesoA1 = parseFloat(FormatUtils.formatarValorBanco(registro.peso_a1, 2) || 0);
		const pesoA2 = parseFloat(FormatUtils.formatarValorBanco(registro.peso_a2, 2) || 0);
		const pesoA3 = parseFloat(FormatUtils.formatarValorBanco(registro.peso_a3, 2) || 0);
		const pesoA4 = parseFloat(FormatUtils.formatarValorBanco(registro.peso_a4, 2) || 0);
		const pesoTotal = pesoA1 + pesoA2 + pesoA3 + pesoA4;

		this.props.initForm({
			...registro,
			meses: registro.meses.map(mes => {

				let volumeTotal = parseFloat((parseFloat(FormatUtils.formatarValorBanco(mes.volume_base, 0) || 0)
						* parseFloat(FormatUtils.formatarValorBanco(registro.peso_volume, 3) || 0)).toFixed(0));
				volumeTotal = (volumeTotal + (volumeTotal * percentualVolume) / 100).toFixed(0);				
				
				const volumeA1 = (((pesoA1 * volumeTotal) / pesoTotal)).toFixed(0);
				// const margemA1 = parseFloat(FormatUtils.formatarValorBanco(mes.margem_a1, 3));
				// margemA1 = parseFloat((margemA1 + (margemA1 * percentualMargem) / 100).toFixed(3));
				// const inadimplenciaA1 = parseFloat(FormatUtils.formatarValorBanco(mes.inadimplencia_a1, 3));
				// inadimplenciaA1 = parseFloat((inadimplenciaA1 + (inadimplenciaA1 * percentualInadimplencia) / 100).toFixed(3));
				// const prazoA1 = parseFloat(FormatUtils.formatarValorBanco(mes.prazo_a1, 3));
				// prazoA1 = parseFloat((prazoA1 + (prazoA1 * percentualPrazo) / 100).toFixed(3));

				const volumeA2 = (((pesoA2 * volumeTotal) / pesoTotal)).toFixed(0);
				// const margemA2 = parseFloat(FormatUtils.formatarValorBanco(mes.margem_a1, 3));
				// margemA2 = parseFloat((margemA2 + (margemA2 * percentualMargem) / 100).toFixed(3));
				// const inadimplenciaA2 = parseFloat(FormatUtils.formatarValorBanco(mes.inadimplencia_a1, 3));
				// inadimplenciaA2 = parseFloat((inadimplenciaA2 + (inadimplenciaA2 * percentualInadimplencia) / 100).toFixed(3));
				// const prazoA2 = parseFloat(FormatUtils.formatarValorBanco(mes.prazo_a1, 3));
				// prazoA2 = parseFloat((prazoA2 + (prazoA2 * percentualPrazo) / 100).toFixed(3));

				const volumeA3 = (((pesoA3 * volumeTotal) / pesoTotal)).toFixed(0);
				// const margemA3 = parseFloat(FormatUtils.formatarValorBanco(mes.margem_a1, 3));
				// margemA3 = parseFloat((margemA3 + (margemA3 * percentualMargem) / 100).toFixed(3));
				// const inadimplenciaA3 = parseFloat(FormatUtils.formatarValorBanco(mes.inadimplencia_a1, 3));
				// inadimplenciaA3 = parseFloat((inadimplenciaA3 + (inadimplenciaA3 * percentualInadimplencia) / 100).toFixed(3));
				// const prazoA3 = parseFloat(FormatUtils.formatarValorBanco(mes.prazo_a1, 3));
				// prazoA3 = parseFloat((prazoA3 + (prazoA3 * percentualPrazo) / 100).toFixed(3));

				let volumeA4 = 0;
				if (pesoA4 > 0) {
					volumeA4 = (((pesoA4 * volumeTotal) / pesoTotal)).toFixed(0);
				}
								
				return {
					...mes,
					volume_total: FormatUtils.formatarValorTela(volumeTotal, 0),

					volume_a1: FormatUtils.formatarValorTela(volumeA1, 0),
					// margem_a1: FormatUtils.formatarValorTela(margemA1, 3),
					// inadimplencia_a1: FormatUtils.formatarValorTela(inadimplenciaA1, 3),
					// prazo_a1: FormatUtils.formatarValorTela(prazoA1, 1),

					volume_a2: FormatUtils.formatarValorTela(volumeA2, 0),
					// margem_a2: FormatUtils.formatarValorTela(margemA2, 3),
					// inadimplencia_a2: FormatUtils.formatarValorTela(inadimplenciaA2, 3),
					// prazo_a2: FormatUtils.formatarValorTela(prazoA2, 1),

					volume_a3: FormatUtils.formatarValorTela(volumeA3, 0),
					// margem_a3: FormatUtils.formatarValorTela(margemA3, 3),
					// inadimplencia_a3: FormatUtils.formatarValorTela(inadimplenciaA3, 3),
					// prazo_a3: FormatUtils.formatarValorTela(prazoA3, 1),
					volume_a4: pesoA4 > 0 ? FormatUtils.formatarValorTela(volumeA4, 0) : mes.volume_a4,
				};
			})
		})
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaExecicio = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			listaExecicio.push({
				id: i,
				valor: i
			});		
		}

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		let pesoA4 = parseFloat(FormatUtils.formatarValorBanco(this.props.formularioValues.peso_a4, 2) || 0);

		let volumeTotalSemestre = 0;
		let margemGlobalSemestre = 0;
		let inadimplenciaGlobalSemestre = 0;
		let prazoGlobalSemestre = 0;
		let volumeA1Semestre = 0;
		let margemA1Semestre = 0;
		let inadimplenciaA1Semestre = 0;
		let prazoA1Semestre = 0;
		let volumeA2Semestre = 0;
		let margemA2Semestre = 0;
		let inadimplenciaA2Semestre = 0;
		let prazoA2Semestre = 0;
		let volumeA3Semestre = 0;
		let margemA3Semestre = 0;
		let inadimplenciaA3Semestre = 0;
		let prazoA3Semestre = 0;
		let volumeA4Semestre = 0;
		let margemA4Semestre = 0;
		let inadimplenciaA4Semestre = 0;
		let prazoA4Semestre = 0;

		this.props.formularioValues.meses.filter(item => 
			item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
		).map(item => {
			const volume = parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 4) || 0);
			volumeTotalSemestre += volume;
			margemGlobalSemestre += parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4) || 0) * volume;
			inadimplenciaGlobalSemestre += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4) || 0) * volume;
			prazoGlobalSemestre += parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4) || 0) * volume;
			volumeA1Semestre += parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 4) || 0);
			margemA1Semestre += parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4) || 0) * volume;
			inadimplenciaA1Semestre += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4) || 0) * volume;
			prazoA1Semestre += parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4) || 0) * volume;
			volumeA2Semestre += parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 4) || 0);
			margemA2Semestre += parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4) || 0) * volume;
			inadimplenciaA2Semestre += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4) || 0) * volume;
			prazoA2Semestre += parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4) || 0) * volume;
			volumeA3Semestre += parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 4) || 0);
			margemA3Semestre += parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4) || 0) * volume;
			inadimplenciaA3Semestre += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4) || 0) * volume;
			prazoA3Semestre += parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4) || 0) * volume;
			volumeA4Semestre += parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 4) || 0);
			margemA4Semestre += parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4) || 0) * volume;
			inadimplenciaA4Semestre += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4) || 0) * volume;
			prazoA4Semestre += parseFloat(FormatUtils.formatarValorBanco(item.prazo_a4, 4) || 0) * volume;
		});

		margemGlobalSemestre = volumeTotalSemestre > 0 ? margemGlobalSemestre / volumeTotalSemestre : 0;
		inadimplenciaGlobalSemestre = volumeTotalSemestre > 0 ? inadimplenciaGlobalSemestre / volumeTotalSemestre : 0;
		prazoGlobalSemestre = volumeTotalSemestre > 0 ? prazoGlobalSemestre / volumeTotalSemestre : 0;
		margemA1Semestre = volumeTotalSemestre > 0 ? margemA1Semestre / volumeTotalSemestre : 0;
		inadimplenciaA1Semestre = volumeTotalSemestre > 0 ? inadimplenciaA1Semestre / volumeTotalSemestre : 0;
		prazoA1Semestre = volumeTotalSemestre > 0 ? prazoA1Semestre / volumeTotalSemestre : 0;
		margemA2Semestre = volumeTotalSemestre > 0 ? margemA2Semestre / volumeTotalSemestre : 0;
		inadimplenciaA2Semestre = volumeTotalSemestre > 0 ? inadimplenciaA2Semestre / volumeTotalSemestre : 0;
		prazoA2Semestre = volumeTotalSemestre > 0 ? prazoA2Semestre / volumeTotalSemestre : 0;
		margemA3Semestre = volumeTotalSemestre > 0 ? margemA3Semestre / volumeTotalSemestre : 0;
		inadimplenciaA3Semestre = volumeTotalSemestre > 0 ? inadimplenciaA3Semestre / volumeTotalSemestre : 0;
		prazoA3Semestre = volumeTotalSemestre > 0 ? prazoA3Semestre / volumeTotalSemestre : 0;
		margemA4Semestre = volumeTotalSemestre > 0 ? margemA4Semestre / volumeTotalSemestre : 0;
		inadimplenciaA4Semestre = volumeTotalSemestre > 0 ? inadimplenciaA4Semestre / volumeTotalSemestre : 0;
		prazoA4Semestre = volumeTotalSemestre > 0 ? prazoA4Semestre / volumeTotalSemestre : 0;

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='exercicio'
								component={Select}
								options={listaExecicio}
								label='Exercício'
								cols='12 12 3 2'
								placeholder='Selecione o exercício'
								readOnly={readOnly} />
							
							<Field
								name='gerente'
								component={LabelAndInput}
								options={listaExecicio}
								label='Gerente'
								cols='12 12 9 10'
								placeholder='Selecione o gerente'
								readOnly={readOnly} />
						</Row>
						<Row>

							<LabelAndInputNumber
								name='peso_volume'
								label='Peso do Volume' placeholder='Informe o peso para o volume'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.peso_volume}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										peso_volume: data.target.value
									})
								}} />
								
							<LabelAndInputNumber
								name='percentual_volume'
								label='Percentual do Volume' placeholder='Informe o percentual para o volume'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.percentual_volume}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										percentual_volume: data.target.value
									});
								}} />
																
							<LabelAndInputNumber
								name='peso_a1'
								label='Peso A1' placeholder='Informe o peso'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.peso_a1}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										peso_a1: data.target.value
									})
								}} />
								
							<LabelAndInputNumber
								name='peso_a2'
								label='Peso A2' placeholder='Informe o peso'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.peso_a2}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										peso_a2: data.target.value
									})
								}} />
								
							<LabelAndInputNumber
								name='peso_a3'
								label='Peso A3' placeholder='Informe o peso'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.peso_a3}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										peso_a3: data.target.value
									})
								}} />

							<LabelAndInputNumber
								name='peso_a4'
								label='Peso A4' placeholder='Informe o peso'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly}
								value={this.props.formularioValues.peso_a4}
								onChange={data => {
									this.recalcular({
										...this.props.formularioValues,
										peso_a4: data.target.value
									})
								}} />
								
							<Field
								name='nome_a1'
								component={LabelAndInput}
								label='Nome A1' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
							<Field
								name='nome_a2'
								component={LabelAndInput}
								label='Nome A2' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
							<Field
								name='nome_a3'
								component={LabelAndInput}
								label='Nome A3' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={readOnly} />
							
							<Field
								name='nome_a4'
								component={LabelAndInput}
								label='Nome A4' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={readOnly} />
								
						</Row>

						<Row>
							<div class="btn-group">
								<button 
									type='button'
									class={`btn btn-${this.state.semestre == 1 ? 'info' : 'default'}`} style={{ width: 150 }}
									onClick={() => this.setState({ ...this.state, semestre: 1 })}>
									1° Semestre
								</button>
								<button
									type='button'
									class={`btn btn-${this.state.semestre == 2 ? 'info' : 'default'}`}
									style={{ width: 150 }}
									onClick={() => this.setState({ ...this.state, semestre: 2 })}>
									2° Semestre
								</button>
							</div>
						</Row>

						<Row>
							<Table responsive>
								<THead>
									<Tr>
										<Th colspan={2}>Mês</Th>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Th key={item.mes} alignCenter>{item.mes}</Th>
										))}
										<Th alignCenter>Total</Th>
									</Tr>
								</THead>
								<TBody>
									<Tr backgroundColor={'#dbdbdb'}>
										<Td colspan={2}>Volume Base</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_base}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {																	
																	return {
																		...mes,
																		volume_base: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
										</Td>
									</Tr>
									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Volume Total</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={'readOnly'}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_total}
													onChange={data => {
														
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={0} 
												fixedDecimalScale={0} 
												value={FormatUtils.formatarValorTela(volumeTotalSemestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Margem Global</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.margem_global}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {																	
																	return {
																		...mes,
																		margem_global: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3}
												value={FormatUtils.formatarValorTela(margemGlobalSemestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Inadimplência Global</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.inadimplencia_global}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		inadimplencia_global: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3}
												value={FormatUtils.formatarValorTela(inadimplenciaGlobalSemestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Prazo Médio Global</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={1} 
													fixedDecimalScale={1} 
													value={item.prazo_global}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		prazo_global: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1}
												value={FormatUtils.formatarValorTela(prazoGlobalSemestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>

									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Ebitda</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={2} 
													fixedDecimalScale={2} 
													value={item.ebitda}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		ebitda: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1}
												value={''}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>

									<Tr backgroundColor='#dfe2f5'>
										<Td colspan={2}>Faturamento</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={2} 
													fixedDecimalScale={2} 
													value={item.faturamento}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		faturamento: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1}
												value={''}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									
									{/*
									A1
									*/}

									<Tr backgroundColor='#fff'>
										<Td rowspan={4}>A1</Td>
										<Td>Volume</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={'readOnly'}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_a1}
													onChange={data => {
														
													}} />
												</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={0} 
												fixedDecimalScale={0} 
												value={FormatUtils.formatarValorTela(volumeA1Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Margem</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.margem_a1}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		margem_a1: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(margemA1Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Inadimplência</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.inadimplencia_a1}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		inadimplencia_a1: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(inadimplenciaA1Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Prazo Médio</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={1} 
													fixedDecimalScale={1} 
													value={item.prazo_a1}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		prazo_a1: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1} 
												value={FormatUtils.formatarValorTela(prazoA1Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>

									{/*
									A2
									*/}

									<Tr backgroundColor='#e6e6e6'>
										<Td rowspan={4}>A2</Td>
										<Td>Volume</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={'readOnly'}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_a2}
													onChange={data => {
														
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={0} 
												fixedDecimalScale={0} 
												value={FormatUtils.formatarValorTela(volumeA2Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#e6e6e6'>
										<Td>Margem</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.margem_a2}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		margem_a2: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(margemA2Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#e6e6e6'>
										<Td>Inadimplência</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.inadimplencia_a2}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		inadimplencia_a2: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(inadimplenciaA2Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#e6e6e6'>
										<Td>Prazo Médio</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={1} 
													fixedDecimalScale={1} 
													value={item.prazo_a2}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		prazo_a2: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1} 
												value={FormatUtils.formatarValorTela(prazoA2Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>

									{/*
									A3
									*/}

									<Tr backgroundColor='#fff'>
										<Td rowspan={4}>A3</Td>
										<Td>Volume</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={'readOnly'}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_a3}
													onChange={data => {
														
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={0} 
												fixedDecimalScale={0} 
												value={FormatUtils.formatarValorTela(volumeA3Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Margem</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.margem_a3}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		margem_a3: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(margemA3Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Inadimplência</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.inadimplencia_a3}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		inadimplencia_a3: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(inadimplenciaA3Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Prazo Médio</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={1} 
													fixedDecimalScale={1} 
													value={item.prazo_a3}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		prazo_a3: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1} 
												value={FormatUtils.formatarValorTela(prazoA3Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>

									{/*
									A4
									*/}

									<Tr backgroundColor='#fff'>
										<Td rowspan={4}>A4</Td>
										<Td>Volume</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={pesoA4 > 0 ? 'readOnly' : readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={0} 
													fixedDecimalScale={0} 
													value={item.volume_a4}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		volume_a4: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={0} 
												fixedDecimalScale={0} 
												value={FormatUtils.formatarValorTela(volumeA4Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Margem</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.margem_a4}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		margem_a4: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(margemA4Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Inadimplência</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={3} 
													fixedDecimalScale={3} 
													value={item.inadimplencia_a4}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		inadimplencia_a4: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={3} 
												fixedDecimalScale={3} 
												value={FormatUtils.formatarValorTela(inadimplenciaA4Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
									<Tr backgroundColor='#fff'>
										<Td>Prazo Médio</Td>
										{this.props.formularioValues.meses.filter(item => 
											item.mes >= (this.state.semestre == 1 ? 1 : 7) && item.mes <= (this.state.semestre == 1 ? 6 : 12)
										).map(item => (
											<Td key={item.mes} alignCenter>
												<NumberFormat
													style={{ textAlign: 'center' }}
													className='form-control'
													readOnly={readOnly}
													decimalSeparator={","} 
													thousandSeparator={'.'}
													decimalScale={1} 
													fixedDecimalScale={1} 
													value={item.prazo_a4}
													onChange={data => {
														this.recalcular({
															...this.props.formularioValues,
															meses: this.props.formularioValues.meses.map(mes => {
																if (mes.mes == item.mes) {
																	return {
																		...mes,
																		prazo_a4: data.target.value
																	};
																} else {
																	return mes;
																}
															})
														});
													}} />
											</Td>
										))}
										<Td alignCenter>
											<NumberFormat
												style={{ textAlign: 'center' }}
												className='form-control'
												readOnly={'readOnly'}
												decimalSeparator={","} 
												thousandSeparator={'.'}
												decimalScale={1} 
												fixedDecimalScale={1} 
												value={FormatUtils.formatarValorTela(prazoA4Semestre, 4)}
												onChange={data => {
													
												}} />
										</Td>
									</Tr>
								</TBody>
							</Table>

						</Row>
					</ContentCardBody>
					
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
					
				</Form>
			</ContentCard>
        )
    }


}

MetaVendaForm = reduxForm({form: 'metaVendaForm', destroyOnUnmount: false})(MetaVendaForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaVenda,
	formularioValues: getFormValues('metaVendaForm')(state),
	registro: state.metaVenda.registro,
	listaVenda: state.metaVenda.listaVenda
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaForm);
