import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { getListaDiario, getListaMensal, getListaAnual, getListaUnidade, getListaProduto } from './dashboardPedidoActions';

import contentCardBody from '../common/template/contentCardBody';

class DashboardPedido extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getListaUnidade();
		this.props.getListaProduto();
		this.props.getListaDiario();
		this.props.getListaMensal();
		this.props.getListaAnual();
    }

    render() {

		let totalDiarioPedido = 0;
		let totalDiarioFaturado = 0;
		let totalMensalPedido = 0;
		let totalMensalFaturado = 0;
		let totalAnualPedido = 0;
		let totalAnualFaturado = 0;
		let listaTotalizadorDiario = [];
		let listaTotalizadorMensal = [];
		let listaTotalizadorAnual = [];

        return (
			<>
				<Content>
					<ContentCard>
						<ContentCardHeader>
							<label>Giros - Diário</label>
						</ContentCardHeader>
						<contentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignLeft width={100}>Unidade</Th>
										<Th alignCenter width={100}>Produto</Th>
										<Th alignRight width={80}>Pedidos</Th>
										<Th alignRight width={80}>Faturados</Th>
									</Tr>
								</THead>
								{this.props.listaUnidade.filter(unidade => !unidade.valor.toUpperCase().includes('DIRETA')).map((unidade, uniPos) => {
									let totalPedido = 0;
									let totalFaturado = 0;

									return (
										<TBody key={unidade.id} backgroundColor={uniPos %2 == 0 ? '#f2f2f0' : null}>
											{this.props.listaProduto.map((produto, i) => {

												let faturar = this.props.listaDiario.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'À FATURAR')[0];
												let faturado = this.props.listaDiario.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'FATURADO')[0];

												if (!listaTotalizadorDiario.filter(totalizador => totalizador.id == unidade.id).length) {
													listaTotalizadorDiario.push({
														...unidade,
														pedido: parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
														faturado: parseFloat(faturado ? faturado.quantidade : 0)
													})
												} else {
													listaTotalizadorDiario = listaTotalizadorDiario.map(totalizador => {
														if (totalizador.id == unidade.id) {
															return {
																...totalizador,
																pedido: totalizador.pedido + parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
																faturado: totalizador.faturado + parseFloat(faturado ? faturado.quantidade : 0)
															};
														} else {
															return totalizador;
														}
													});
												}

												totalPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalFaturado += parseFloat(faturado ? faturado.quantidade : 0);
												totalDiarioPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalDiarioFaturado += parseFloat(faturado ? faturado.quantidade : 0);

												return (
													<Tr key={produto.id}>
														{i == 0 && <Td alignLeft width={100} rowspan={3}>{unidade.valor}</Td>}
														<Td alignCenter width={100} backgroundColor={produto.id == 1 ? '#FFFDD0' : '#f2c2b8'}>{produto.valor}</Td>													
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
													</Tr>
												);
											})}
										</TBody>
									);
								})}	
								<TBody>
									{listaTotalizadorDiario.map(totalizador => (
										<Tr key={totalizador.id}>
											<Td alignRight width={100} colspan={2} bold>{totalizador.valor}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.pedido, 2)}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.faturado, 2)}</Td>
										</Tr>
									))}
									<Tr backgroundColor={'#f2f2f0'}>
										<Td alignRight width={100} colspan={2} bold fontSize={16}>TOTAL</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalDiarioPedido, 2)}</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalDiarioFaturado, 2)}</Td>
									</Tr>
								</TBody>
							</Table>			
						</contentCardBody>
					</ContentCard>
				</Content>


				<Content>
					<ContentCard>
						<ContentCardHeader>
							<label>Giros - Mensal</label>
						</ContentCardHeader>
						<contentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignLeft width={100}>Unidade</Th>
										<Th alignCenter width={100}>Produto</Th>
										<Th alignRight width={80}>Pedidos</Th>
										<Th alignRight width={80}>Faturados</Th>
									</Tr>
								</THead>
								{this.props.listaUnidade.filter(unidade => !unidade.valor.toUpperCase().includes('DIRETA')).map((unidade, uniPos) => {
									let totalPedido = 0;
									let totalFaturado = 0;

									return (
										<TBody key={unidade.id} backgroundColor={uniPos %2 == 0 ? '#f2f2f0' : null}>
											{this.props.listaProduto.map((produto, i) => {

												let faturar = this.props.listaMensal.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'À FATURAR')[0];
												let faturado = this.props.listaMensal.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'FATURADO')[0];

												if (!listaTotalizadorMensal.filter(totalizador => totalizador.id == unidade.id).length) {
													listaTotalizadorMensal.push({
														...unidade,
														pedido: parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
														faturado: parseFloat(faturado ? faturado.quantidade : 0)
													})
												} else {
													listaTotalizadorMensal = listaTotalizadorMensal.map(totalizador => {
														if (totalizador.id == unidade.id) {
															return {
																...totalizador,
																pedido: totalizador.pedido + parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
																faturado: totalizador.faturado + parseFloat(faturado ? faturado.quantidade : 0)
															};
														} else {
															return totalizador;
														}
													});
												}

												totalPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalFaturado += parseFloat(faturado ? faturado.quantidade : 0);
												totalMensalPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalMensalFaturado += parseFloat(faturado ? faturado.quantidade : 0);

												return (
													<Tr key={produto.id}>
														{i == 0 && <Td alignLeft width={100} rowspan={3}>{unidade.valor}</Td>}
														<Td alignCenter width={100} backgroundColor={produto.id == 1 ? '#FFFDD0' : '#f2c2b8'}>{produto.valor}</Td>													
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
													</Tr>
												);
											})}
										</TBody>
									);
								})}	
								<TBody>
									{listaTotalizadorMensal.map(totalizador => (
										<Tr key={totalizador.id}>
											<Td alignRight width={100} colspan={2} bold>{totalizador.valor}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.pedido, 2)}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.faturado, 2)}</Td>
										</Tr>
									))}
									<Tr backgroundColor={'#f2f2f0'}>
										<Td alignRight width={100} colspan={2} bold fontSize={16}>TOTAL</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalMensalPedido, 2)}</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalMensalFaturado, 2)}</Td>
									</Tr>
								</TBody>
							</Table>			
						</contentCardBody>
					</ContentCard>
				</Content>

				<Content>
					<ContentCard>
						<ContentCardHeader>
							<label>Giros - Anual</label>
						</ContentCardHeader>
						<contentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignLeft width={100}>Unidade</Th>
										<Th alignCenter width={100}>Produto</Th>
										<Th alignRight width={80}>Pedidos</Th>
										<Th alignRight width={80}>Faturados</Th>
									</Tr>
								</THead>
								{this.props.listaUnidade.filter(unidade => !unidade.valor.toUpperCase().includes('DIRETA')).map((unidade, uniPos) => {
									let totalPedido = 0;
									let totalFaturado = 0;

									return (
										<TBody key={unidade.id} backgroundColor={uniPos %2 == 0 ? '#f2f2f0' : null}>
											{this.props.listaProduto.map((produto, i) => {

												let faturar = this.props.listaAnual.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'À FATURAR')[0];
												let faturado = this.props.listaAnual.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'FATURADO')[0];

												if (!listaTotalizadorAnual.filter(totalizador => totalizador.id == unidade.id).length) {
													listaTotalizadorAnual.push({
														...unidade,
														pedido: parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
														faturado: parseFloat(faturado ? faturado.quantidade : 0)
													})
												} else {
													listaTotalizadorAnual = listaTotalizadorAnual.map(totalizador => {
														if (totalizador.id == unidade.id) {
															return {
																...totalizador,
																pedido: totalizador.pedido + parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0),
																faturado: totalizador.faturado + parseFloat(faturado ? faturado.quantidade : 0)
															};
														} else {
															return totalizador;
														}
													});
												}

												totalPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalFaturado += parseFloat(faturado ? faturado.quantidade : 0);
												totalAnualPedido += parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0);
												totalAnualFaturado += parseFloat(faturado ? faturado.quantidade : 0);

												return (
													<Tr key={produto.id}>
														{i == 0 && <Td alignLeft width={100} rowspan={3}>{unidade.valor}</Td>}
														<Td alignCenter width={100} backgroundColor={produto.id == 1 ? '#FFFDD0' : '#f2c2b8'}>{produto.valor}</Td>													
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturar ? faturar.quantidade : 0) + parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
														<Td alignRight width={100}>{FormatUtils.formatarValorTelaDecimal(parseFloat(faturado ? faturado.quantidade : 0), 2)}</Td>
													</Tr>
												);
											})}
										</TBody>
									);
								})}	
								<TBody>
									{listaTotalizadorAnual.map(totalizador => (
										<Tr key={totalizador.id}>
											<Td alignRight width={100} colspan={2} bold>{totalizador.valor}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.pedido, 2)}</Td>
											<Td alignRight width={100} bold>{FormatUtils.formatarValorTelaDecimal(totalizador.faturado, 2)}</Td>
										</Tr>
									))}
									<Tr backgroundColor={'#f2f2f0'}>
										<Td alignRight width={100} colspan={2} bold fontSize={16}>TOTAL</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalAnualPedido, 2)}</Td>
										<Td alignRight width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalAnualFaturado, 2)}</Td>
									</Tr>
								</TBody>
							</Table>			
						</contentCardBody>
					</ContentCard>
				</Content>		
			</>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboardPedido.aguardando,
	listaDiario: state.dashboardPedido.listaDiario,
	listaMensal: state.dashboardPedido.listaMensal,
	listaAnual: state.dashboardPedido.listaAnual,
	listaUnidade: state.dashboardPedido.listaUnidade,
	listaProduto: state.dashboardPedido.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaDiario, getListaMensal, getListaAnual, getListaUnidade, getListaProduto
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPedido)
