import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
	getLista
} from './dashboardClienteActions';

import contentCardBody from '../common/template/contentCardBody';

class Dashboard extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getLista();
    }

    render() {

        return (
			<Content>
				<label>Pedidos</label>

				{this.props.lista.map(pedido => {

					let corCard = pedido.nome_status == 'Entregue' ? '#03ab33' : (pedido.nome_status == 'Em Entrega' ? '#0469cf' : '#d99c02');

					return (
						<ContentCard key={pedido.id_item}>
							<ContentCardHeader style={{ backgroundColor: corCard }}>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ fontSize: 10, fontWeight: 'bold', color: '#ffffff' }}>
										Pedido
									</div>
									<div style={{ fontSize: 10, fontWeight: 'bold', color: '#ffffff', textAlign: 'right' }}>
										Status
									</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div style={{ fontSize: 12, fontWeight: 'bold', color: '#ffffff' }}>
										{DateFormat.formatarDataSqlParaTela(pedido.data_pedido)}
									</div>
									<div style={{ fontSize: 12, fontWeight: 'bold', color: '#ffffff', textAlign: 'right' }}>
										{pedido.nome_status}
									</div>
								</div>
							</ContentCardHeader>
							<contentCardBody>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Produto
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Quantidade
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_produto}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												{FormatUtils.formatarValorTelaDecimal(pedido.quantidade, 0)} L
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Motorista
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Placa
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_motorista || 'Não Informado'}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												{pedido.placa_veiculo}
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Representante Comercial
											</div>
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Contato
											</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.nome_vendedor}
											</div>
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												<a href={`https://api.whatsapp.com/send/?phone=5555991735243&text&type=phone_number&app_absent=0`} target='blank'><i class='fab fa-whatsapp' style={{ marginRight: 4 }}></i>(55) 9 9999-9999</a>
											</div>
										</div>
									</Grid>
								</Row>
								<Row style={{ padding: 8 }}>
									<Grid cols='12 12 12 12'>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 10 }}>
												Ordem da Entrega
											</div>
											{(pedido.nome_status == 'Em Entrega' && pedido.latitude && pedido.longitude) &&
											<div style={{ fontSize: 10, textAlign: 'right' }}>
												Localização da Entrega
											</div>}
										</div>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ fontSize: 12 }}>
												{pedido.ordem}ª entrega
											</div>
											{(pedido.nome_status == 'Em Entrega' && pedido.latitude && pedido.longitude) &&
											<div style={{ fontSize: 12, textAlign: 'right' }}>
												<a href={`https://www.google.com/maps/place/${pedido.latitude},${pedido.longitude}`} target='blank'><i class='fas fa-map-marker-alt' style={{ marginRight: 4 }}></i>Ver no Mapa</a>
											</div>}
										</div>
									</Grid>
								</Row>
							</contentCardBody>
						</ContentCard>
					);
				})}
			</Content>
        )
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboardCliente.aguardando,
	lista: state.dashboardCliente.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
