import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaMotoristaForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar, excluir, setFiltro, imprimir,
	getLista, getListaGrupo, getListaMotorista, getListaVeiculo, importarTelemetria, importarApontamento, importarTruckPag, importarSigest, importarRH
} from './metaMotoristaActions';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

class MetaMotorista extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

	componentDidMount() {

		const fileSelectorTelemetria = document.createElement('input');
		fileSelectorTelemetria.setAttribute('type', 'file');
		fileSelectorTelemetria.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarTelemetria(tabela, this.props.filtro.competencia);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorTelemetria = fileSelectorTelemetria;

		const fileSelectorApontamento = document.createElement('input');
		fileSelectorApontamento.setAttribute('type', 'file');
		fileSelectorApontamento.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarApontamento(tabela, this.props.filtro.competencia);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorApontamento = fileSelectorApontamento;

		const fileSelectorSigest = document.createElement('input');
		fileSelectorSigest.setAttribute('type', 'file');
		fileSelectorSigest.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarSigest(tabela, this.props.filtro.competencia);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorSigest = fileSelectorSigest;

		const fileSelectorRH = document.createElement('input');
		fileSelectorRH.setAttribute('type', 'file');
		fileSelectorRH.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarRH(tabela, this.props.filtro.competencia);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorRH = fileSelectorRH;

		const fileSelectorTruckPag = document.createElement('input');
		fileSelectorTruckPag.setAttribute('type', 'file');
		fileSelectorTruckPag.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarTruckPag(tabela, this.props.filtro.competencia);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorTruckPag = fileSelectorTruckPag;

	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaGrupo();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
    }


    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 3 2'>
							<Button
								text='Adicionar Motorista'
								type={'success'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({
										competencia: this.props.filtro.competencia || `${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}`
									});
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar Telemetria'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelectorTelemetria.click()
									// }
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar Apontamentos'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelectorApontamento.click()
									// }
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar Sigest'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelectorSigest.click()
									// }
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar RH'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelectorRH.click()
									// }
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar TruckPag'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.fileSelectorTruckPag.click();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							options={this.props.listaCompetencia}
							label='Competência'
							cols='12 6 4 3'
							placeholder='Selecione a competência'
							value={this.props.filtro.competencia}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									competencia: value
								});
								this.props.getLista();
							}} />
							
						<Grid cols='6 6 3 2' style={{ marginTop: 28 }}>
							<ExcelFile
								filename={'Meta do Motorista'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={this.props.lista.map(item => ({
									...item,
									apontamentos: FormatUtils.formatarValorTela(item.apontamentos, 0),
									sigest: FormatUtils.formatarValorTela(item.sigest, 0),
									telemetria: FormatUtils.formatarValorTela(item.telemetria, 0),
									faltas: FormatUtils.formatarValorTela(parseFloat(item.falta_grave_apontamento) + parseFloat(item.falta_grave_telemetria), 0),
									km_rodado: FormatUtils.formatarValorTela(item.km_rodado, 0),
									media_realizada: FormatUtils.formatarValorTela(item.media_realizada, 2),
									valor_km: FormatUtils.formatarValorTela(item.valor_km, 2),
									total_pontos: FormatUtils.formatarValorTela(item.total_pontos, 2),
									valor_garantido: FormatUtils.formatarValorTela(item.valor_garantido, 2),
									adicional_desc_pontos: FormatUtils.formatarValorTela(item.adicional_desc_pontos, 2),
									media: FormatUtils.formatarValorTela(item.media, 2),
									bonificacao_sigest: FormatUtils.formatarValorTela(item.bonificacao_sigest, 2),
									premio: FormatUtils.formatarValorTela(item.premio, 2)
								}))} name='Meta do Motorista'>
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Apontamentos' value='apontamentos' />
									<ExcelColumn label='Sigest' value='sigest' />
									<ExcelColumn label='Telemetria' value='telemetria' />
									<ExcelColumn label='Falta Grave' value='faltas' />
									<ExcelColumn label='Km Rodado' value='km_rodado' />
									<ExcelColumn label='Média Km/L' value='media_realizada' />
									<ExcelColumn label='Valor KM' value='valor_km' />
									<ExcelColumn label='Total Pontos' value='total_pontos' />
									<ExcelColumn label='Valor Garantido' value='valor_garantido' />
									<ExcelColumn label='Adicional Desc. Pontos' value='adicional_desc_pontos' />
									<ExcelColumn label='Valor Média' value='media' />
									<ExcelColumn label='Valor SIGEST' value='bonificacao_sigest' />
									<ExcelColumn label='Prêmio Receber' value='premio' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>		
								
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Motorista</Th>
								<Th alignCenter>Apontamentos</Th>
								<Th alignCenter>Sigest</Th>
								<Th alignCenter>Telemetria</Th>
								<Th alignCenter>Falta Grave</Th>
								<Th alignCenter>Km Rodado</Th>
								<Th alignCenter>Média Km/L</Th>
								<Th alignCenter>Valor KM</Th>
								<Th alignCenter>Total Pontos</Th>
								<Th alignCenter>Valor Garantido</Th>
								<Th alignCenter>Adicional Desc. Pontos</Th>
								<Th alignCenter>Valor Média</Th>
								<Th alignCenter>Valor SIGEST</Th>
								<Th alignCenter>Prêmio Receber</Th>
								<Th alignCenter></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_motorista}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_motorista}</Td>
									<Td alignCenter
										title={(item.apontamento_itens || []).map(apontamento => `${apontamento.data_formatada} - ${apontamento.nome}`).join('\n')}
										>{FormatUtils.formatarValorTela(item.apontamentos, 0)}</Td>
									<Td alignCenter
										title={`Dias não realizados: ${parseInt(item.sigest_nao_realizado || 0)}`}
										>{FormatUtils.formatarValorTela(item.sigest, 0)}</Td>
									<Td alignCenter
										title={(item.telemetria_itens || []).filter(apontamento => apontamento.quantidade > 0).map(apontamento => `${apontamento.nome} - ${apontamento.quantidade} ${apontamento.quantidade > 1 ? 'eventos' : 'evento'}`).join('\n')}
										>{FormatUtils.formatarValorTela(item.telemetria, 0)}</Td>
									<Td alignCenter
										title={((item.falta_grave_apontamento_itens || []).length + (item.falta_grave_telemetria_itens || []).filter(apontamento => apontamento.quantidade > 0).length) == 0 
											? null : (
											'APONTAMENTOS\n'
											+ (item.falta_grave_apontamento_itens || []).map(apontamento => `${apontamento.data_formatada} - ${apontamento.nome}`).join('\n')
											+ '\n\nTELEMETRIA\n'
											+ (item.falta_grave_telemetria_itens || []).filter(apontamento => apontamento.quantidade > 0).map(apontamento => `${apontamento.nome} - ${apontamento.quantidade} ${apontamento.quantidade > 1 ? 'eventos' : 'evento'}`).join('\n')
										)}
										>{FormatUtils.formatarValorTela(parseFloat(item.falta_grave_apontamento) + parseFloat(item.falta_grave_telemetria), 0)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.km_rodado, 0)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.media_realizada, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.valor_km, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.total_pontos, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.valor_garantido, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.adicional_desc_pontos, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.media, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.bonificacao_sigest, 2)}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.premio, 2)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-pdf'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.imprimir({
													...item,
													competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
													sigest_nao_realizado: parseInt(item.sigest_nao_realizado || 0),
													sigest: FormatUtils.formatarValorTela(item.sigest, 2),
													valor_km: FormatUtils.formatarValorTelaDecimal(item.valor_km, 2),
													km_rodado: FormatUtils.formatarValorTelaDecimal(item.km_rodado, 0),
													media_realizada: FormatUtils.formatarValorTela(item.media_realizada, 2),
													media: FormatUtils.formatarValorTelaDecimal(item.media, 2),
													total_pontos: FormatUtils.formatarValorTelaDecimal(item.total_pontos, 0),
													valor_garantido: FormatUtils.formatarValorTela(item.valor_garantido, 2),
													adicional_desc_pontos: FormatUtils.formatarValorTela(item.adicional_desc_pontos, 2),
													desconto_pontos: FormatUtils.formatarValorTela(parseFloat(item.valor_garantido) - parseFloat(item.adicional_desc_pontos), 2),
													media: FormatUtils.formatarValorTela(item.media, 2),
													bonificacao_sigest: FormatUtils.formatarValorTela(item.bonificacao_sigest, 2),
													premio: FormatUtils.formatarValorTela(item.premio, 2)
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.metaMotorista,
	modoTela: state.metaMotorista.modoTela,
	filtro: state.metaMotorista.filtro,
	lista: state.metaMotorista.lista,
	listaCompetencia: state.metaMotorista.listaCompetencia
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, setFiltro, imprimir,
	getLista, getListaGrupo, getListaMotorista, getListaVeiculo, importarTelemetria, importarApontamento, importarTruckPag, importarSigest, importarRH
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaMotorista);
