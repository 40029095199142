const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaSetor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'APONTAMENTO_GRUPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'APONTAMENTO_GRUPO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'APONTAMENTO_GRUPO_SETOR_SELECT_LISTADO':
			return {
				...state,
				listaSetor: action.payload.data
            };

        default:
            return state;
    }
}
