import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTela, initForm
} from './veiculoTipoActions';

class MotoristaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='reboque'
								component={LabelAndCheckbox}
								label='Reboque' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							{!this.props.formularioValues.reboque ? (
								<Field
									name='carretas'
									component={Select}
									options={this.props.listaCarreta}
									label='Carretas'
									cols='12 12 12 12'
									placeholder='Selecione as carretas'
									readOnly={readOnly} />
							) : null}

							<Field
								name='preco_km'
								component={LabelAndInputNumber}
								label='Preço por Km' placeholder='Informe o preço'
								cols='12 12 12 12'
								casas={4}
								readOnly={readOnly} />

							<Field
								name='distancia_minima'
								component={LabelAndInputNumber}
								label='Distância Mínima' placeholder='Informe a distância'
								cols='12 12 12 12'
								casas={0}
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

MotoristaForm = reduxForm({form: 'veiculoTipoForm', destroyOnUnmount: false})(MotoristaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('veiculoTipoForm')(state),
	registro: state.veiculoTipo.registro,
	listaCarreta: state.veiculoTipo.listaCarreta
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MotoristaForm);
