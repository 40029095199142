import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './prospeccaoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import { setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaCidade, getListaAtividade, getListaUsuario } from './prospeccaoActions';

class Prospeccao extends Component {

	state = {
		linhaSelecionada: null
	}

	componentDidMount() {

	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCidade();
		this.props.getListaAtividade();
		this.props.getListaUsuario();
		this.props.getListaProduto();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'prospeccaoEvento' ? (
						<Form prospeccaoEvento onSubmit={this.props.salvar} />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_tipo: 2 });
								}} />
						</Grid>
					</Row>
					<Row>
						<LabelAndCheckbox
							label='Apenas cadastrados hoje'
							cols='12 12 12 12'
							placeholder=''
							style={{ marginTop: 20 }}
							value={this.state.cadastradoHoje}
							onChange={data => {
								this.setState({
									...this.state,
									cadastradoHoje: !this.state.cadastradoHoje
								})								
							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					{/* <input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} /> */}
					<Table responsive>
						<THead>
							<Tr>
								<Th width={10}></Th>
								<Th width={200}>Razão Social</Th>
								<Th width={200}>Nome Fantasia</Th>
								<Th width={200}>Endereço</Th>
								<Th width={200}>Contato</Th>
								<Th width={200}>Vendedor</Th>
								<Th width={200}>Inclusão</Th>
								<Th width={200} alignCenter>Agenda</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							
							<Tr>
								<Th></Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarRazaoSocial}
										onChange={e => {
											this.setState({ ...this.state, pesquisarRazaoSocial: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarNomeFantasia}
										onChange={e => {
											this.setState({ ...this.state, pesquisarNomeFantasia: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarEndereco}
										onChange={e => {
											this.setState({ ...this.state, pesquisarEndereco: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarContato}
										onChange={e => {
											this.setState({ ...this.state, pesquisarContato: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarVendedor}
										onChange={e => {
											this.setState({ ...this.state, pesquisarVendedor: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarInclusao}
										onChange={e => {
											this.setState({ ...this.state, pesquisarInclusao: e.target.value });
										}} />
								</Th>
								<Th>
									<input className='form-control'
										placeholder='Pesquisar'
										type='text'
										value={this.state.pesquisarAgenda}
										onChange={e => {
											this.setState({ ...this.state, pesquisarAgenda: e.target.value });
										}} />
								</Th>
								<Th></Th>
							</Tr>

							{(this.props.lista.filter(item => !item.id_petroshow)).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.razao_social} ${item.nome_fantasia} ${item.endereco} ${item.nome_cidade || ''} 
										${item.nome_contato || ''} ${item.celular ? ' - ' : ''} ${item.celular} ${item.telefone ? ' - ' : ''} 
										${item.telefone} ${item.nome_usuario_inclusao} ${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}
										${DateFormat.formatarDataSqlParaTela(item.datahora_agenda)}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.state.cadastradoHoje) {
									return DateFormat.formatarDataSqlParaTela(item.datahora_inclusao) == DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual());
								}
								
								if (this.state.pesquisarRazaoSocial
									&& !(`${item.razao_social}`).toUpperCase().includes(this.state.pesquisarRazaoSocial.toUpperCase())) {
									return false;

								}
								if (this.state.pesquisarNomeFantasia
									&& !(`${item.nome_fantasia}`).toUpperCase().includes(this.state.pesquisarNomeFantasia.toUpperCase())) {
									return false;
									
								}
								if (this.state.pesquisarEndereco
									&& !(`${item.endereco} ${item.nome_cidade || ''}`).toUpperCase().includes(this.state.pesquisarEndereco.toUpperCase())) {
									return false;
									
								}
								if (this.state.pesquisarContato
									&& !(`${item.nome_contato || ''} ${item.celular ? ' - ' : ''} ${item.celular} ${item.telefone ? ' - ' : ''} ${item.telefone}`).toUpperCase().includes(this.state.pesquisarContato.toUpperCase())) {
									return false;
									
								}
								if (this.state.pesquisarVendedor
									&& !(`${item.nome_usuario_inclusao}`).toUpperCase().includes(this.state.pesquisarVendedor.toUpperCase())) {
									return false;
									
								}
								if (this.state.pesquisarInclusao
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}`).toUpperCase().includes(this.state.pesquisarInclusao.toUpperCase())) {
									return false;
									
								}
								if (this.state.pesquisarAgenda
									&& !(`${DateFormat.formatarDataSqlParaTela(item.datahora_agenda)}`).toUpperCase().includes(this.state.pesquisarAgenda.toUpperCase())) {
									return false;
								}

								/*
								//13- Fábio Henrique Araujo de Freitas
								if (this.props.sessao && this.props.sessao.id == 13) {
									if (![
										5515, 4387, 4795, 1358, 4487, 1003, 3949, 2805, 149, 4633, 686, 5011, 3420,
										951, 3391, 314, 3422, 522, 3914, 309, 3166, 673, 5262, 4182, 5225, 2045, 3420, 3994, 3700, 1900, 153, 3599, 4042, 4276, 986, 2432, 5453, 1492
									].includes(item.id_cidade)) {
										return false;
									}
								}

								//40- LUANNA CONCEICAO MARTINS SILVA
								if (this.props.sessao && this.props.sessao.id == 40) {
									if (![1009, 48, 1292, 3350, 1894, 4170, 4078].includes(item.id_cidade)) {
										return false;
									}
								}
								
								//37- Camila Saraiva
								if (this.props.sessao && this.props.sessao.id == 37) {
									if (![951, 3391, 314, 3422, 522, 3914, 309, 3166, 673, 5262, 4182, 5225, 2045, 3420, 3994, 3700, 1900, 153, 3599, 4042, 4276, 986, 2432, 5453, 1492].includes(item.id_cidade)) {
										return false;
									}
								}
								*/

								return true;
							}).map((item, i) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{i + 1}</Td>
									<Td>{item.razao_social}</Td>
									<Td>{item.nome_fantasia}</Td>
									<Td>{item.endereco || ''}{item.endereco && item.nome_cidade ? ' - ' : ''}{item.nome_cidade || ''}</Td>
									<Td>{item.nome_contato}{item.celular ? ' - ' : ''}{item.celular}{item.telefone ? ' - ' : ''}{item.telefone}</Td>
									<Td>{item.nome_usuario_inclusao}</Td>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.datahora_agenda)}</Td>
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-headset'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('prospeccaoEvento', {
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda),
													expectativa_anual_s10: FormatUtils.formatarValorTela(item.expectativa_anual_s10, 0),
													expectativa_anual_s500: FormatUtils.formatarValorTela(item.expectativa_anual_s500, 0)
												});
												this.props.initForm({
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda),
													expectativa_anual_s10: FormatUtils.formatarValorTela(item.expectativa_anual_s10, 0),
													expectativa_anual_s500: FormatUtils.formatarValorTela(item.expectativa_anual_s500, 0)
												});
											}} />
										
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda),
													expectativa_anual_s10: FormatUtils.formatarValorTela(item.expectativa_anual_s10, 0),
													expectativa_anual_s500: FormatUtils.formatarValorTela(item.expectativa_anual_s500, 0)
												});
												this.props.initForm({
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda),
													expectativa_anual_s10: FormatUtils.formatarValorTela(item.expectativa_anual_s10, 0),
													expectativa_anual_s500: FormatUtils.formatarValorTela(item.expectativa_anual_s500, 0)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda)
												});
												this.props.initForm({
													...item,
													id_tipo: item.cpf ? 1 : 2,
													datahora_agenda: DateFormat.formatarDataSqlParaTela(item.datahora_agenda)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.prospeccao.modoTela,
	lista: state.prospeccao.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaCidade, getListaAtividade, getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Prospeccao);
