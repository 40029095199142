import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CLIENTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CLIENTE_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('clienteForm', registro)
    ];
}

export function getLista(agenda) {
	if (!agenda) {
		const request = axios.get(`${consts.API_URL}/cliente${agenda ? '?agenda=true' : ''}`);
		return {
			type: 'CLIENTE_LISTADO',
			payload: request
		};
	} else {
		const request = axios.get(`${consts.API_URL}/cliente/crm`);
		return {
			type: 'CLIENTE_LISTADO',
			payload: request
		};
	}
}

export function initFormProspeccao(registro = {}) {
    return [
        initialize('prospeccaoForm', registro)
    ];
}

export function getProspeccao(idPetroshow) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/prospeccao?id_petroshow=${idPetroshow}`).then(result => {
			dispatch({
				type: 'CLIENTE_PROSPECCAO_CARREGADO',
				payload: result
			});
			dispatch(initFormProspeccao(result.data[0] ? { 
				...result.data[0], 
				id_tipo: result.data[0].cpf ? 1 : 2,
				datahora_agenda: DateFormat.formatarDataSqlParaTela(result.data[0].datahora_agenda),
				expectativa_anual_s10: FormatUtils.formatarValorTela(result.data[0].expectativa_anual_s10, 0),
				expectativa_anual_s500: FormatUtils.formatarValorTela(result.data[0].expectativa_anual_s500, 0),
				cadastroCliente: true
			} : {}));
			dispatch(setModoTela('prospeccao'));
		});
	}
}

export function getProspeccaoAgenda(idPetroshow) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/prospeccao?id_petroshow=${idPetroshow}`).then(result => {
			dispatch({
				type: 'CLIENTE_PROSPECCAO_CARREGADO',
				payload: result
			});
			dispatch(initFormProspeccao(result.data[0] ? { 
				...result.data[0], 
				id_tipo: result.data[0].cpf ? 1 : 2,
				datahora_agenda: DateFormat.formatarDataSqlParaTela(result.data[0].datahora_agenda),
				expectativa_anual_s10: FormatUtils.formatarValorTela(result.data[0].expectativa_anual_s10, 0),
				expectativa_anual_s500: FormatUtils.formatarValorTela(result.data[0].expectativa_anual_s500, 0),
				cadastroCliente: true
			} : {}));
			dispatch(setModoTela('prospeccaoEvento'));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/cliente`, {
				...registro,
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				cpf: String(registro.cpf || '').split(".").join("").replace(".", "").replace("-", ""),
				id_petroshow: FormatUtils.removerMascara(String(registro.id_petroshow || '')) || null,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cliente`, {
				...registro,
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				cpf: String(registro.cpf || '').split(".").join("").replace(".", "").replace("-", ""),
				id_petroshow: FormatUtils.removerMascara(String(registro.id_petroshow || '')) || null,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/cliente?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEndereco() {
    const request = axios.get(`${consts.API_URL}/endereco/listarSelect`);
    return {
        type: 'CLIENTE_ENDERECO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProspeccao() {
    const request = axios.get(`${consts.API_URL}/prospeccao`);
    return {
        type: 'CLIENTE_PROSPECCAO_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'CLIENTE_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'CLIENTE_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelectGeral`);
    return {
        type: 'CLIENTE_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'CLIENTE_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function importar(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let listaCliente = [];
		let listaEndereco = [];

		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			let cliente = {
				id_petroshow: String(item[2] || '').trim(),
				razao_social: String(item[3] || '').trim().substring(0, 299).trim(),
				nome_fantasia: String(item[4] || '').trim().substring(0, 299).trim(),
				cnpj: String(item[5] || '').trim().length == 14 ? item[5] : null,
				cpf: String(item[5] || '').trim().length == 14 ? null : item[5],
				id_tipo: String(item[5] || '').trim().length == 14 ? 2 : 1,
				inscricao_estadual: item[6],
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			};

			let endereco = {
				id_petroshow: null,
				id_petroshow_cliente: item[2],
				id_cliente: null,
				codigo_vendedor: item[8],
				endereco: String(item[16] || '').trim().substring(0, 299).trim(),
				bairro: '',
				numero: String(item[18] || '').trim().substring(0, 149).trim(),
				id_cidade: getState().endereco.listaCidade.filter(cidade => cidade.valor == String(item[14] || '').trim())[0] ? getState().endereco.listaCidade.filter(cidade => cidade.valor == String(item[14] || '').trim())[0].id : null,
				telefone: FormatUtils.removerMascara(String(item[10] || '')).trim().substring(0, 11).trim(),
				celular: FormatUtils.removerMascara(String(item[12] || '')).trim().substring(0, 11).trim(),
				latitude: String(item[19] || '').trim().substring(0, 19).trim(),
				longitude: String(item[20] || '').trim().substring(0, 19).trim(),
				croqui: null,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}

			if (!listaCliente.filter(cli => cli.id_petroshow == cliente.id_petroshow).length && cliente.id_petroshow 
					&& cliente.razao_social && cliente.nome_fantasia && (cliente.cpf || cliente.cnpj) && endereco.endereco) {
				listaCliente.push(cliente);
			}
			if (endereco.endereco) {
				listaEndereco.push(endereco);		
			}
			
		}

		await axios.post(`${consts.API_URL}/cliente/importar`, {
			lista: listaCliente
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		await axios.post(`${consts.API_URL}/endereco/importar`, {
			lista: listaEndereco
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setAguardando(false));
    };
}

export function getListaStatusVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/status`);
    return {
        type: 'CLIENTE_VEICULO_STATUS_LISTADO',
        payload: request
    };
}

export function getListaPosicaoVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/posicao`);
    return {
        type: 'CLIENTE_VEICULO_POSICAO_LISTADO',
        payload: request
    };
}

export function getListaTipoVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculoTipo/listarSelect`);
    return {
        type: 'CLIENTE_VEICULO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPontoMapa() {
    const request = axios.get(`${consts.API_URL}/pontoMapa`);
    return {
        type: 'CLIENTE_PONTO_MAPA_LISTADO',
        payload: request
    };
}

export function getProspeccaoAgendaHistorico(dataInicial, dataFinal) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/prospeccaoEvento/historico?
			data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}`).then(result => {
			dispatch({
				type: 'CLIENTE_PROSPECCAO_HISTORICO_LISTADO',
				payload: result
			});
		}).catch(ex => {
			console.log(ex);
		});
	}
}

export function initFormWhatsapp(registro = {}) {
    return [
        initialize('whatsappForm', registro)
    ];
}

export function salvarWhatsapp(registro) {
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/whatsapp`, {
			...registro,
			id_usuario_inclusao: getState().auth.usuario.id,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(true));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaPedidoHistorico(dataInicial, dataFinal) {
    const request = axios.get(`${consts.API_URL}/pedido/listarEventoProspeccaoData?
		data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}
		&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}`);
    return {
        type: 'CLIENTE_PROSPECCAO_HISTORICO_PEDIDO_LISTADO',
        payload: request
    };
}

export function imprimirCrmHistorico(cabecalho, paginas) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/prospeccaoEveto/gerarHistoricoPdf`, {
			cabecalho, paginas
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}

//USUÁRIO

export function initFormUsuario(registro = {}) {
    return [
        initialize('clienteUsuarioForm', registro)
    ];
}

export function getListaUsuarioCompleto() {
    const request = axios.get(`${consts.API_URL}/usuario?usuario=true`);
    return {
        type: 'CLIENTE_USUARIO_LISTADO',
        payload: request
    };
}

export function salvarUsuario(registro) {
    return (dispatch, getState) => {

		let senha = String(Math.random().toString(36).slice(-6)).toLocaleLowerCase();

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/usuario`, {
				...registro,
				senha: senha,
				administrador: true
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaUsuarioCompleto());
				dispatch(initFormUsuario({
					...registro,
					senha_gerada: senha
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/usuario`, {
				...registro,
				senha: senha
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaUsuarioCompleto());
				dispatch(initFormUsuario({
					...registro,
					senha_gerada: senha
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}