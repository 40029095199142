import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VEICULO_STATUS_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'VEICULO_STATUS_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/veiculo/telemetria`);
    return {
        type: 'VEICULO_STATUS_LISTADO',
        payload: request
    };
}

export function getListaStatus() {
    const request = axios.get(`${consts.API_URL}/veiculo/status`);
    return {
        type: 'VEICULO_STATUS_VIAGEM_LISTADO',
        payload: request
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/veiculoTipo/listarSelect`);
    return {
        type: 'VEICULO_STATUS_TIPO_SELECT_LISTADO',
        payload: request
    };
}
