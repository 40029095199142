import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './adicionalDiarioForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import ColorUtils from '../common/colorUtils/ColorUtils';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro } from './adicionalDiarioActions';

class AdicionalDiario extends Component {

	state = {
		linhaSelecionada: null,
		idAlteracao: null,
		dias_prazo: null,
		percentual_indice: null,
		indiceAlterado: false
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data' placeholder='Informe a data'
							cols='6 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data: data.target.value });
								this.props.getLista();
								this.setState({ ...this.state, idAlteracao: null, dias_prazo: null, percentual_indice: null, indiceAlterado: false });
							}} />

						{this.props.filtro.data && DateFormat.formatarDataTelaParaSql(this.props.filtro.data) 
							&& DateFormat.formatarDataTelaParaSql(this.props.filtro.data) == DateFormat.getDataAtual() ? (
							<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
								<Button
									text='Processar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({});
									}} />
							</Grid>
						) : null}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}>Unidade</Th>
								<Th width={200} alignCenter>Distribuidora</Th>
								<Th width={200} alignCenter>Cidade</Th>
								<Th width={100} alignCenter>Perc. Índice (igual para todos)</Th>
								<Th width={100} alignCenter>Dias Prazo</Th>
								<Th width={100} alignCenter>Perc. Fator Diário</Th>
								<Th width={100}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_unidade} ${item.nome_distribuidora || ''} ${item.nome_cidade || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id}
									backgroundColor={item.cor_distribuidora} color={ColorUtils.getCorFonte(item.cor_distribuidora)} >
									<Td minWidth={200}>{item.nome_unidade}</Td>
									<Td minWidth={200} alignCenter>{item.nome_distribuidora}</Td>
									<Td minWidth={200} alignCenter>{item.nome_cidade}</Td>
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.percentual_indice, 4)
									: (
										<LabelAndInputNumber
											placeholder='Informe o índice'
											cols='12 12 12 12'
											casas={4}
											value={this.state.percentual_indice}
											onChange={(data) => {
												this.setState({ ...this.state, percentual_indice: data.target.value, indiceAlterado: true })
											}} />
									)}</Td>
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.dias_prazo, 1)
									: (
										<LabelAndInputNumber
											placeholder='Informe os dias'
											cols='12 12 12 12'
											casas={1}
											value={this.state.dias_prazo}
											onChange={(data) => {
												this.setState({ ...this.state, dias_prazo: data.target.value })
											}} />
									)}</Td>
									<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.percentual_fator, 4)}</Td>
									<Td alignRight minWidth={100}>
										{!this.state.idAlteracao || item.id != this.state.idAlteracao ? (
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
												event={() => {
													this.setState({ 
														...this.state, 
														idAlteracao: item.id, 
														percentual_indice: FormatUtils.formatarValorTela(item.percentual_indice, 4),
														dias_prazo: FormatUtils.formatarValorTela(item.dias_prazo, 4),
														indiceAlterado: false
													});
												}} />
										) : (
											<>
												<ButtonTable
													type={'success'}
													icon={'fas fa-check'}
													visible={true}
													disabled={false}
													event={() => {
														this.props.salvar({ 
															...item,
															percentual_indice: this.state.percentual_indice,
															dias_prazo: this.state.dias_prazo,
															indiceAlterado: this.state.indiceAlterado
														});
														this.setState({ ...this.state, idAlteracao: null, dias_prazo: null, percentual_indice: null, indiceAlterado: false });
													}} />
													
												<ButtonTable
													type={'danger'}
													icon={'fas fa-times'}
													visible={true}
													disabled={false}
													event={() => {
														this.setState({ ...this.state, idAlteracao: null, dias_prazo: null, percentual_indice: null, indiceAlterado: false });
													}} />
											</>
										)}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.adicionalDiario.modoTela,
	lista: state.adicionalDiario.lista,
	filtro: state.adicionalDiario.filtro,
	aguardando: state.adicionalDiario.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdicionalDiario);
