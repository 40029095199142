import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './manutencaoPreventivaActions';

class ManutencaoForm extends Component {

    componentWillMount() {

		let initSeletorImagem = () => {
			const fileSelectorImagem = document.createElement('input');
			fileSelectorImagem.setAttribute('type', 'file');
			fileSelectorImagem.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.imagens || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							imagens: lista
						});

						initSeletorImagem();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagem = fileSelectorImagem;
		};

		initSeletorImagem();

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let tipo = this.props.listaTipo.filter(item => item.id == this.props.formularioValues.id_tipo)[0];

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
	
							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />

							<Field
								name='id_veiculo'
								component={Select}
								options={this.props.listaVeiculo}
								label='Veículo'
								cols='12 12 12 12'
								placeholder='Selecione o veículo'
								readOnly={readOnly} />

							<Field
								name='data_manutencao'
								component={LabelAndInputDate}
								label='Data da Manutenção' placeholder='Informe a data'
								cols='12 6 4 4'
								readOnly={readOnly} />
								
							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição do Problema' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />
							
							<Field
								name='descricao_produto'
								component={LabelAndInput}
								label='Especifição do Produto' placeholder='Informe a especificação'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{(tipo && tipo.periodico) &&
							<Field
								name='proxima_dia'
								component={Select}
								options={this.props.listaPeriodo}
								label='Período para a Próxima'
								cols='12 4 4 4'
								placeholder='Selecione o período'
								readOnly={readOnly} />}
								
							{(tipo && tipo.km) &&
							<Field
								name='km_atual'
								component={LabelAndInputNumber}
								label='Km Atual' placeholder='Informe o km'
								cols='12 4 4 4'
								readOnly={readOnly} />}
								
							{(tipo && tipo.km) &&
							<Field
								name='proxima_km'
								component={Select}
								options={this.props.listaKm}
								label='Km para a Próxima'
								cols='12 4 4 4'
								placeholder='Selecione a quilometragem'
								readOnly={readOnly} />}
						
						</Row>

						<Row>
							<Grid cols='12 4 4 4'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Imagens</label>
									</Grid>
									{(this.props.formularioValues.imagens || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`https://api-valedaserra.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															imagens: this.props.formularioValues.imagens.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>

							</Grid>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

ManutencaoForm = reduxForm({form: 'manutencaoPreventivaForm', destroyOnUnmount: false})(ManutencaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.manutencaoPreventiva,
	formularioValues: getFormValues('manutencaoPreventivaForm')(state),
	registro: state.manutencaoPreventiva.registro,
	listaTipo: state.manutencaoPreventiva.listaTipo,
    listaVeiculo: state.manutencaoPreventiva.listaVeiculo,
    listaPeriodo: state.manutencaoPreventiva.listaPeriodo,
    listaKm: state.manutencaoPreventiva.listaKm
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoForm);
