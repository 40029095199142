const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTipo: [{ id: 1, valor: 'Pessoa Física' }, { id: 2, valor: 'Pessoa Jurídica' }],
	listaEndereco: [],
	listaProspeccao: [],
	listaCidade: [],
	listaCliente: [],
	listaUnidade: [],
	listaUsuario: [],
	listaUsuarioCompleto: [],
	listaVeiculo: [],
	listaStatusVeiculo: [],
	listaTipoVeiculo: [],
	listaPontoMapa: [],
	listaPosicaoVeiculo: [],
	prospeccao: {},
	listaEventoHistorico: [],
	listaTipoRetorno: [
		{ id: 999, valor: 'Não Informado' },
		{ id: 6, valor: 'Giro Aberto' },
		{ id: 7, valor: 'Giro não Aprovado' },
		{ id: 1, valor: 'Venda Fechada' },
		{ id: 2, valor: 'Venda Perdida' },
		{ id: 3, valor: 'Não Respondido' },
		{ id: 4, valor: 'Manutenção de Contato' },
		{ id: 5, valor: 'Outro' },
	],
	listaPedidoHistorico: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CLIENTE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CLIENTE_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CLIENTE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CLIENTE_ENDERECO_SELECT_LISTADO':
            return {
				...state,
				listaEndereco: action.payload.data
			};

		case 'CLIENTE_PROSPECCAO_CARREGADO':
            return {
				...state,
				prospeccao: action.payload.data[0] || null
			};
		
		case 'CLIENTE_PROSPECCAO_LISTADO':
            return {
				...state,
				listaProspeccao: action.payload.data
			};

		case 'CLIENTE_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'CLIENTE_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'CLIENTE_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

		case 'CLIENTE_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
			};

		case 'CLIENTE_USUARIO_LISTADO':
			return {
				...state,
				listaUsuarioCompleto: action.payload.data
			};

		case 'CLIENTE_VEICULO_STATUS_LISTADO':
            return {
				...state,
				listaStatusVeiculo: action.payload.data
			};

		case 'CLIENTE_VEICULO_POSICAO_LISTADO':
			console.log(action.payload.data);
			
            return {
				...state,
				listaPosicaoVeiculo: action.payload.data
			};

		case 'CLIENTE_VEICULO_TIPO_SELECT_LISTADO':
            return {
				...state,
				listaTipoVeiculo: action.payload.data
			};
		
		case 'CLIENTE_PONTO_MAPA_LISTADO':
			return {
				...state,
				listaPontoMapa: action.payload.data
			};

		case 'CLIENTE_PROSPECCAO_HISTORICO_LISTADO':
			return {
				...state,
				listaEventoHistorico: action.payload.data
			};

		case 'CLIENTE_PROSPECCAO_HISTORICO_PEDIDO_LISTADO':
			return {
				...state,
				listaPedidoHistorico: action.payload.data
			};

        default:
            return state;
    }
}
