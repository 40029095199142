import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import ColorUtils from '../common/colorUtils/ColorUtils';

import { getLista, setFiltro, getListaDistribuidora, getListaProduto } from './analiseCurvaPrecoActions';

class AnaliseCurvaPreco extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaDistribuidora();
		this.props.getListaProduto();
    }

    render() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							label='Distribuidora' placeholder='Selecione a distribuidora'
							cols='6 6 4 3'
							options={this.props.listaDistribuidora}
							value={this.props.filtro.id_distribuidora}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_distribuidora: value });
								this.props.getLista();
							}} />

						<Select
							label='Produto' placeholder='Selecione o produto'
							cols='6 6 4 3'
							options={this.props.listaProduto}
							value={this.props.filtro.id_produto}
							onChange={value => {
								this.props.setFiltro({ ...this.props.filtro, id_produto: value });
								this.props.getLista();
							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

						<img src={`https://quickchart.io/chart?width=${window.innerWidth * 0.40}&height=150&c={
							type: 'line',
							data: {
								labels: ${JSON.stringify(this.props.lista.filter(item => parseFloat(item.preco) > 0 && parseFloat(item.preco) < 9).map(item => DateFormat.formatarDataSqlParaTela(item.data)))},
								datasets: [{
									label: 'Distribuidora',
									data: ${JSON.stringify(this.props.lista.filter(item => parseFloat(item.preco) > 0 && parseFloat(item.preco) < 9).map(item => item.preco))},
									fill: false,
									borderColor: 'green',
									borderWidth: 1,
									pointRadius: 1
								}]
							},
							options: {
								legend: {
									labels: {
										fontSize: 8,
										fontStyle: 'bold'
									}
								},
								scales: {
									yAxes: [{
										stacked: true,
										ticks: {
											min: 4,
											fontSize: 6,
											beginAtZero: false
										}
									}],
									xAxes: [{
										stacked: true,
										ticks: {
											fontSize: 6,
											beginAtZero: false
										}
									}]
								}
							}
						}`} />
					</div>
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}>Distribuidora</Th>
								<Th width={100} alignCenter>Data</Th>
								<Th width={100} alignCenter>Preço</Th>
								<Th width={100} alignCenter>Variação</Th>
								<Th width={100} alignCenter>Usuário</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map((item, i) => {
								
								let anterior = this.props.lista[i + 1];
								let variacao = anterior ? parseFloat(item.preco) - parseFloat(anterior.preco) : 0

								return (
									<Tr key={item.id} backgroundColor={variacao > 0 ? '#f77979' : (variacao < 0 ? '#95c985' : null)}>
										<Td minWidth={200}>{item.nome_distribuidora}</Td>
										<Td minWidth={100} alignCenter>{DateFormat.formatarDataSqlParaTela(item.data)}</Td>
										<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.preco, 4)}</Td>
										<Td minWidth={100} alignCenter><i style={{ marginRight: 10 }} class={`${variacao > 0 ? 'fas fa-long-arrow-alt-up' : (variacao < 0 ? 'fas fa-long-arrow-alt-down' : 'fas fa-equals')}`}></i> {FormatUtils.formatarValorTela(variacao > 0 ? variacao : variacao * -1, 4)}</Td>
										<Td minWidth={100} alignCenter>{item.nome_usuario_alteracao}</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	lista: state.analiseCurvaPreco.lista,
	filtro: state.analiseCurvaPreco.filtro,
	listaDistribuidora: state.analiseCurvaPreco.listaDistribuidora,
	listaProduto: state.analiseCurvaPreco.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, setFiltro, getListaDistribuidora, getListaProduto }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AnaliseCurvaPreco);
