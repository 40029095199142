import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VEICULO_DESPESA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'VEICULO_DESPESA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('veiculoDespesaForm', registro)
    ];
}

export function getLista(dataInicial, dataFinal) {
	const request = axios.get(`${consts.API_URL}/veiculoDespesa?data_inicial=${dataInicial}&data_final=${dataFinal}`);
	return {
		type: 'VEICULO_DESPESA_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/veiculoDespesa`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/veiculoDespesa`, {
				...registro,				
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/veiculoDespesa?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo() {
    const request = axios.get(`${consts.API_URL}/veiculoDespesaTipo/listarSelect`);
    return {
        type: 'VEICULO_DESPESA_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaItem() {
    const request = axios.get(`${consts.API_URL}/veiculoDespesaItem/listarSelect`);
    return {
        type: 'VEICULO_DESPESA_ITEM_SELECT_LISTADO',
        payload: request
    };
}

export function importar(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];

		let placa = '';
		let tipo = '';

		for (var i = 6; i < tabela.length; i++) {
			let item = tabela[i];

			if ((!placa || placa != item[0]) && item[0]) {
				placa = item[0];
				tipo = item[1];
			}

			if ((!tipo || tipo != item[1]) && item[1]) {
				tipo = item[1];
			}

			if (item[4]) {
				let registro = {
					datahora_despesa: DateFormat.getTimestampParaDataHora(new Date(item[3]).getTime()),
					placa_veiculo: placa,
					nome_tipo: tipo,
					nome_item: item[5] && item[5] != '(vazio)' ? item[5] : item[4],
					quantidade: item[6],
					valor_unitario: item[7],
					valor_total: item[8]
				};

				if (!registro.nome_item.includes('OLEO DIESEL B S500 ADITIVADO') 
					&& !registro.nome_item.includes('OLEO DIESEL B S10 ADITIVADO') 
					&& !registro.nome_item.includes('AR001 - ARLA 32')) {
					
					if (registro.datahora_despesa) {
						await axios.post(`${consts.API_URL}/veiculoDespesa`, {
							...registro,
							id_usuario_inclusao: getState().auth.usuario.id,
							id_usuario_alteracao: getState().auth.usuario.id
						}).then(resp => {
							console.log('Operação Realizada com sucesso.');
						}).catch(e => {
							console.log(e);
							console.log(registro);
						});
					}
				}
			}
		}
console.log('fim');
		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setAguardando(false));

    };
}
