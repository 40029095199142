import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

    return (dispatch, getState) => {
        axios.get(`${consts.API_URL}/pedido/cliente?id_cliente=${getState().auth.usuario.id_cliente}`).then(result => {
            dispatch({
                type: 'DASHBOARD_CLIENTE_LISTADO',
                payload: result
            });
        }).catch(ex => {
            setErroAPI(ex);
        });
    }
}