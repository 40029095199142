import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './metaVendedorActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

class MetaVendedorForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='competencia'
								component={Select}
								options={listaCompetencia}
								label='Competência'
								cols='12 12 12 12'
								placeholder='Selecione a competência'
								readOnly={readOnly} />

							<Field
								name='id_vendedor'
								component={Select}
								options={this.props.listaVendedor}
								label='Vendedor'
								cols='12 12 12 12'
								placeholder='Selecione o vendedor'
								readOnly={readOnly} />

							<Field
								name='volume'
								component={LabelAndInputNumber}
								label='Volume' placeholder='Informe o volume'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />
								
							<Field
								name='margem'
								component={LabelAndInputNumber}
								label='Margem Global' placeholder='Informe a margem'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />
								
							<Field
								name='inadimplencia'
								component={LabelAndInputNumber}
								label='Inadimplência Global' placeholder='Informe a inadimplencia'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />
								
							<Field
								name='prazo'
								component={LabelAndInputNumber}
								label='Prazo Global' placeholder='Informe o prazo'
								cols='12 12 12 12'
								casas={2}
								readOnly={readOnly} />
								
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

MetaVendedorForm = reduxForm({form: 'metaVendedorForm', destroyOnUnmount: false})(MetaVendedorForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaVendedor,
	formularioValues: getFormValues('metaVendedorForm')(state),
	registro: state.metaVendedor.registro,
	listaVendedor: state.metaVendedor.listaVendedor
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendedorForm);
