import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './limiteCreditoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCliente, getListaEndereco } from './limiteCreditoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class LimiteCredito extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaEndereco();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let lista = (this.props.lista).filter(item => {

			// if (!this.props.listaCliente.length || !this.props.listaEndereco.length) {
			// 	return false;
			// }
			if (this.state.pesquisar
				&& !(`${item.nome || item.nome_cliente}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});


		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						{((this.props.listaCliente.length > 0 && this.props.listaEndereco.length > 0)) &&
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_tipo: 1, id_tipo_pessoa: 1 });
								}} />
						</Grid>}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data</Th>
								<Th>Nome</Th>
								<Th alignCenter>Status</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => {

								let status = '';
								if (item.id_status_gerencia_geral) {
									status = item.id_status_gerencia_geral == 1 ? 'Aprovado' : 'Rejeitado';
								} else if (item.datahora_gerencia_comercial) {
									status = 'Aguardando Gerência Geral';
								} else if (item.datahora_financeiro) {
									status = 'Aguardando Gerência Comercial';
								} else if (item.datahora_abertura) {
									status = 'Aguardando Financeiro';
								} else {
									status = 'Digitando Cadastro';
								}

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
										<Td>{item.nome || item.nome_cliente}</Td>
										<Td alignCenter>{status}</Td>
										<Td alignRight minWidth={100}>																				
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														id_tipo: item.id_cliente ? 2 : 1,
														id_tipo_pessoa: (item.cpf_cnpj || '').length == 11 ? 1 : 2,
														volume_mes: FormatUtils.formatarValorTela(item.volume_mes, 2),
														valor_medio: FormatUtils.formatarValorTela(item.valor_medio, 2),
														limite_sugerido: FormatUtils.formatarValorTela(item.limite_sugerido, 2),
														limite_proposto: FormatUtils.formatarValorTela(item.limite_proposto, 2),
														prazo: FormatUtils.formatarValorTela(item.prazo, 0),
														area_frota_propria: FormatUtils.formatarValorTela(item.area_frota_propria, 0),
														area_frota_arrendada: FormatUtils.formatarValorTela(item.area_frota_arrendada, 0),
														area_soja: FormatUtils.formatarValorTela(item.area_soja, 0),
														area_milho: FormatUtils.formatarValorTela(item.area_milho, 0),
														area_algodao: FormatUtils.formatarValorTela(item.area_algodao, 0),
														area_pasto: FormatUtils.formatarValorTela(item.area_pasto, 0),
														area_outros: FormatUtils.formatarValorTela(item.area_outros, 0),
														limite_liberado: FormatUtils.formatarValorTela(item.limite_liberado, 2),
														limite_volume: FormatUtils.formatarValorTela(item.limite_volume, 2)
													});
													this.props.initForm({
														...item,
														id_tipo: item.id_cliente ? 2 : 1,
														id_tipo_pessoa: (item.cpf_cnpj || '').length == 11 ? 1 : 2,
														volume_mes: FormatUtils.formatarValorTela(item.volume_mes, 2),
														valor_medio: FormatUtils.formatarValorTela(item.valor_medio, 2),
														limite_sugerido: FormatUtils.formatarValorTela(item.limite_sugerido, 2),
														limite_proposto: FormatUtils.formatarValorTela(item.limite_proposto, 2),
														prazo: FormatUtils.formatarValorTela(item.prazo, 0),
														area_frota_propria: FormatUtils.formatarValorTela(item.area_frota_propria, 0),
														area_frota_arrendada: FormatUtils.formatarValorTela(item.area_frota_arrendada, 0),
														area_soja: FormatUtils.formatarValorTela(item.area_soja, 0),
														area_milho: FormatUtils.formatarValorTela(item.area_milho, 0),
														area_algodao: FormatUtils.formatarValorTela(item.area_algodao, 0),
														area_pasto: FormatUtils.formatarValorTela(item.area_pasto, 0),
														area_outros: FormatUtils.formatarValorTela(item.area_outros, 0),
														limite_liberado: FormatUtils.formatarValorTela(item.limite_liberado, 2),
														limite_volume: FormatUtils.formatarValorTela(item.limite_volume, 2)
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														id_tipo: item.id_cliente ? 2 : 1,
														id_tipo_pessoa: (item.cpf_cnpj || '').length == 11 ? 1 : 2,
														volume_mes: FormatUtils.formatarValorTela(item.volume_mes, 2),
														valor_medio: FormatUtils.formatarValorTela(item.valor_medio, 2),
														limite_sugerido: FormatUtils.formatarValorTela(item.limite_sugerido, 2),
														limite_proposto: FormatUtils.formatarValorTela(item.limite_proposto, 2),
														prazo: FormatUtils.formatarValorTela(item.prazo, 0),
														area_frota_propria: FormatUtils.formatarValorTela(item.area_frota_propria, 0),
														area_frota_arrendada: FormatUtils.formatarValorTela(item.area_frota_arrendada, 0),
														area_soja: FormatUtils.formatarValorTela(item.area_soja, 0),
														area_milho: FormatUtils.formatarValorTela(item.area_milho, 0),
														area_algodao: FormatUtils.formatarValorTela(item.area_algodao, 0),
														area_pasto: FormatUtils.formatarValorTela(item.area_pasto, 0),
														area_outros: FormatUtils.formatarValorTela(item.area_outros, 0),
														limite_liberado: FormatUtils.formatarValorTela(item.limite_liberado, 2),
														limite_volume: FormatUtils.formatarValorTela(item.limite_volume, 2)
													});
													this.props.initForm({
														...item,
														id_tipo: item.id_cliente ? 2 : 1,
														id_tipo_pessoa: (item.cpf_cnpj || '').length == 11 ? 1 : 2,
														volume_mes: FormatUtils.formatarValorTela(item.volume_mes, 2),
														valor_medio: FormatUtils.formatarValorTela(item.valor_medio, 2),
														limite_sugerido: FormatUtils.formatarValorTela(item.limite_sugerido, 2),
														limite_proposto: FormatUtils.formatarValorTela(item.limite_proposto, 2),
														prazo: FormatUtils.formatarValorTela(item.prazo, 0),
														area_frota_propria: FormatUtils.formatarValorTela(item.area_frota_propria, 0),
														area_frota_arrendada: FormatUtils.formatarValorTela(item.area_frota_arrendada, 0),
														area_soja: FormatUtils.formatarValorTela(item.area_soja, 0),
														area_milho: FormatUtils.formatarValorTela(item.area_milho, 0),
														area_algodao: FormatUtils.formatarValorTela(item.area_algodao, 0),
														area_pasto: FormatUtils.formatarValorTela(item.area_pasto, 0),
														area_outros: FormatUtils.formatarValorTela(item.area_outros, 0),
														limite_liberado: FormatUtils.formatarValorTela(item.limite_liberado, 2),
														limite_volume: FormatUtils.formatarValorTela(item.limite_volume, 2)
													});
												}} />
										</Td>
									</Tr>
								)
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.limiteCredito.modoTela,
	aguardando: state.limiteCredito.aguardando,
	lista: state.limiteCredito.lista,
	listaCliente: state.limiteCredito.listaCliente,
	listaEndereco: state.limiteCredito.listaEndereco
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, getListaCliente, getListaEndereco
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LimiteCredito);
