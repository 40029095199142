import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import ProspeccaoForm from '../prospeccao/prospeccaoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';

import { setModoTela, initForm, salvar, excluir, getLista, importar, getProspeccao, getProspeccaoAgenda, initFormWhatsapp, salvarWhatsapp } from './clienteActions';
import { getListaCidade } from './endereco/enderecoActions';
import {
	salvar as salvarProspeccao, getListaProduto as getListaProdutoProspeccao, getListaCidade as getListaCidadeProspeccao, 
	getListaAtividade as getListaAtividadeProspeccao, getListaUsuario as getListaUsuarioProspeccao 
} from '../prospeccao/prospeccaoActions';
import WhatsappForm from './whatsappForm';
import DateFormat from '../common/dateFormat/DateFormat';

class ClienteCRM extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0,
		cards: [
			{ id: 'sem_contato', nome: 'Sem Contato', primary: '#8c8c8c', secondary: '#ebe4e4', icone: 'fas fa-phone-slash' },
			{ id: 'nao_agendado', nome: 'Sem Agendamento', primary: '#c76d06', secondary: '#faf9f7', icone: 'far fa-calendar-times' },
			{ id: 'agendado', nome: 'Agendado', primary: '#e0aa07', secondary: '#faf9f7', icone: 'fas fa-calendar-check' },
			{ id: 'em_venda', nome: 'Cotação em Andamento', primary: '#444abd', secondary: '#faf9f7', icone: 'fas fa-clipboard-list' },
			{ id: 'venda', nome: 'Venda Realizada (7 dias)', primary: '#32a852', secondary: '#faf9f7', icone: 'fas fa-file-invoice-dollar' },
			{ id: 'venda_perdida', nome: 'Venda Perdida (7 dias)', primary: '#cc2525', secondary: '#f2e9e6', icone: 'fas fa-thumbs-down' }
		],
		cardSelecionados: ['nao_agendado', 'agendado', 'em_venda', 'venda', 'venda_perdida']
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importar(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {
        this.props.getLista(true);
		this.props.getListaCidade();
		this.props.getListaProdutoProspeccao();
		this.props.getListaCidadeProspeccao();
		this.props.getListaAtividadeProspeccao();
		this.props.getListaUsuarioProspeccao();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'endereco' ? (
						<Form endereco onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'prospeccao' ? (
						<ProspeccaoForm cliente onSubmit={this.props.salvarProspeccao} />
					) : null}
					{this.props.modoTela == 'prospeccaoEvento' ? (
						<ProspeccaoForm cliente prospeccaoEvento onSubmit={this.props.salvarProspeccao} />
					) : null}
					{this.props.modoTela == 'whatsapp' ? (
						<WhatsappForm cliente onSubmit={this.props.salvarWhatsapp} />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {
		
		let lista = this.props.lista.filter(item => {

			if (this.props.usuarioCarregado && this.props.usuarioCarregado.id == 37) { //Camila Saraiva
				// if (this.props.usuarioCarregado && this.props.usuarioCarregado.codigo 
				// 	&& !(item.vendedores || []).includes(this.props.usuarioCarregado.codigo)
				// 	&& !(item.vendedores || []).includes('8485')) { //Fabio Farias
				// 	return false;
				// }
			} else if (this.props.usuarioCarregado && this.props.usuarioCarregado.id == 13) { //Fabio Farias
				// if (this.props.usuarioCarregado && this.props.usuarioCarregado.codigo 
				// 	&& !(item.vendedores || []).includes(this.props.usuarioCarregado.codigo)
				// 	&& !(item.vendedores || []).includes('19592')) { //Camila Saraiva
				// 	return false;
				// }
			} else {
				if (this.props.usuarioCarregado && this.props.usuarioCarregado.codigo 
					&& !(item.vendedores || []).includes(this.props.usuarioCarregado.codigo)) {
					return false;
				}
			}

			if (this.state.id_cidade) {				
				if (!(item.cidades || []).includes(this.state.id_cidade)) {
					return false;
				}
			}

			if (this.state.pesquisar
				&& !(`${item.id_petroshow} ${item.razao_social} ${item.nome_fantasia || ''} ${item.cnpj || ''} ${item.cpf || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (this.state.id_status == 1 && item.datahora_agenda) { //Não Agendado
				return false;
			} else if (this.state.id_status ==  2 && (!item.datahora_agenda || item.dias_agenda < 0)) { // Agendamento OK
				return false;
			} else if (this.state.id_status == 3 && (!item.datahora_agenda || (item.dias_agenda >= 0 || item.dias_agenda < -7))) { //Agendamento Atrasado - até 7 dias
				return false;
			} else if (this.state.id_status == 4 && (!item.datahora_agenda || (item.dias_agenda >= -7 || item.dias_agenda < -15))) { //Agendamento Atrasado - de 8 até 15 dias
				return false;
			} else if (this.state.id_status == 5 && (!item.datahora_agenda || (item.dias_agenda >= -15 || item.dias_agenda < -30))) { //Agendamento Atrasado - de 16 até 30 dias
				return false;
			} else if (this.state.id_status == 6 && (!item.datahora_agenda || (item.dias_agenda >= -30 || item.dias_agenda < -60))) { //Agendamento Atrasado - de 31 até 60 dias
				return false;
			} else if (this.state.id_status == 7 && (!item.datahora_agenda || item.dias_agenda >= -60)) { //Agendamento Atrasado - mais de 60 dias
				return false;
			}

			if (this.state.id_ultimo_contato == 1 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato > 7)) { //Até 7 dias
				return false;
			} else if (this.state.id_ultimo_contato ==  2 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato <= 7 || item.dias_ultimo_cotato > 15)) { // De 8 até 15 dias
				return false;
			} else if (this.state.id_ultimo_contato == 3 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato <= 15 || item.dias_ultimo_cotato > 30)) { //De 16 até 30 dias
				return false;
			} else if (this.state.id_ultimo_contato == 4 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato <= 30 || item.dias_ultimo_cotato > 60)) { //De 31 até 60 dias
				return false;
			} else if (this.state.id_ultimo_contato == 5 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato <= 60 || item.dias_ultimo_cotato > 90)) { //De 61 até 90 dias
				return false;
			} else if (this.state.id_ultimo_contato == 6 && (!item.datahora_ultimo_contato || item.dias_ultimo_cotato <= 90)) { //Mais de 90 dias
				return false;
			} else if (this.state.id_ultimo_contato == 7 && item.datahora_ultimo_contato) { //Não contatado
				return false;
			}

			if (this.state.id_ultima_venda == 1 && (!item.data_ultima_venda || item.dias_ultima_venda > 7)) { //Até 7 dias
				return false;
			} else if (this.state.id_ultima_venda ==  2 && (!item.data_ultima_venda || item.dias_ultima_venda <= 7 || item.dias_ultima_venda > 15)) { // De 8 até 15 dias
				return false;
			} else if (this.state.id_ultima_venda == 3 && (!item.data_ultima_venda || item.dias_ultima_venda <= 15 || item.dias_ultima_venda > 30)) { //De 16 até 30 dias
				return false;
			} else if (this.state.id_ultima_venda == 4 && (!item.data_ultima_venda || item.dias_ultima_venda <= 30 || item.dias_ultima_venda > 60)) { //De 31 até 60 dias
				return false;
			} else if (this.state.id_ultima_venda == 5 && (!item.data_ultima_venda || item.dias_ultima_venda <= 60 || item.dias_ultima_venda > 90)) { //De 61 até 90 dias
				return false;
			} else if (this.state.id_ultima_venda == 6 && (!item.data_ultima_venda || item.dias_ultima_venda <= 90)) { //Mais de 90 dias
				return false;
			} else if (this.state.id_ultima_venda == 7 && item.data_ultima_venda) { //Não contatado
				return false;
			}

			return true;
		}).map(item => {

			let status = '';
			if (item.data_ultima_venda && item.dias_ultima_venda <= 7) {
				status = 'venda';
			} else if (item.data_ultima_venda_perdida && item.dias_ultima_venda_perdida <= 7) {
				status = 'venda_perdida';
			} else if (item.pedido_aberto) {
				status = 'em_venda';
			} else if (item.datahora_agenda) {
				status = 'agendado';
			} else if (!item.datahora_ultimo_contato) {
				status = 'sem_contato';
			} else {
				status = 'nao_agendado';
			}

			return {
				...item,
				status: status
			};
		})
		
		let listaCards = lista.map(item => item);
		
		lista = lista.filter(item => this.state.cardSelecionados.filter(status => status == item.status).length);

		let paginacao = [];

		for (let i = 0; i < lista.length; i += 500) {
			
			paginacao.push({
				inicio: i,
				fim: i + 499
			});
			
		}

		if (this.state.paginacaoIndice > 0 && !paginacao[this.state.paginacaoIndice]) {
			this.setState({
				...this.state,
				paginacaoIndice: 0
			});
		}

		return (
			<ContentCard>
				<ContentCardHeader>

					<Row alignCenter>
						{this.state.cards.map(item => {

							let cardSelecionados = this.state.cardSelecionados.map(card => card);
							let selecionado = this.state.cardSelecionados.filter(card => card == item.id).length;

							let backgroundColor = selecionado ? item.primary : item.secondary;
							let color = !selecionado ? item.primary : item.secondary;

							let quantidade = listaCards.filter(cliente => cliente.status == item.id).length;

							return (					
								<div id={item.id} class='col-xs-6 col-sm-6 col-md-4 col-lg-2' 
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (selecionado) {
											cardSelecionados = cardSelecionados.filter(card => card != item.id);
										} else {
											cardSelecionados.push(item.id);
										}
										this.setState({
											...this.state,
											cardSelecionados: cardSelecionados
										});
									}} >
									<div class='small-box'
										style={{
											backgroundColor: backgroundColor
										}}>
										<div class='inner'>
											<h5 style={{ textAlign: 'center', color: color, fontSize: 16 }}>{item.nome}</h5>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												<div style={{ fontWeight: 'bold', fontSize: 22, color: color }} title={''}>
													<i class={item.icone} style={{ fontSize: 22, marginRight: 8 }}></i>{quantidade}
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</Row>

					<Row>
						{/*<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_tipo: 2 });
								}} />
						</Grid>
						{this.props.listaCidade.length > 0 &&
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelector.click()
									}
								}} />
						</Grid>}*/}
						
						<Select
							options={[
								{ id: 1, valor: 'Não Agendado' }, { id: 2, valor: 'Agendamento OK' }, { id: 3, valor: 'Agendamento Atrasado - até 7 dias' }, 
								{ id: 4, valor: 'Agendamento Atrasado - de 8 até 15 dias' }, { id: 5, valor: 'Agendamento Atrasado - de 16 até 30 dias' },
								{ id: 6, valor: 'Agendamento Atrasado - de 31 até 60 dias' }, { id: 7, valor: 'Agendamento Atrasado - mais de 60 dias' }
							]}
							label='Agendamento'
							cols='12 6 3 2'
							placeholder='Selecione o status' 
							value={this.state.id_status} 
							onChange={value => this.setState({ ...this.state, id_status: value })} />

						<Select
							options={[
								{ id: 1, valor: 'Até 7 dias' }, { id: 2, valor: 'De 8 até 15 dias' }, 
								{ id: 3, valor: 'De 16 até 30 dias' }, { id: 4, valor: 'De 31 até 60 dias' },
								{ id: 5, valor: 'De 61 até 90 dias' }, { id: 6, valor: 'Mais de 90 dias' },
								{ id: 7, valor: 'Não contatado' }
							]}
							label='Último Contato'
							cols='12 6 3 2'
							placeholder='Selecione o tempo' 
							value={this.state.id_ultimo_contato} 
							onChange={value => this.setState({ ...this.state, id_ultimo_contato: value })} />
							
						<Select
							options={[
								{ id: 1, valor: 'Até 7 dias' }, { id: 2, valor: 'De 8 até 15 dias' }, 
								{ id: 3, valor: 'De 16 até 30 dias' }, { id: 4, valor: 'De 31 até 60 dias' },
								{ id: 5, valor: 'De 61 até 90 dias' }, { id: 6, valor: 'Mais de 90 dias' },
								{ id: 7, valor: 'Sem Venda' }
							]}
							label='Última Venda'
							cols='12 6 3 2'
							placeholder='Selecione o tempo' 
							value={this.state.id_ultima_venda} 
							onChange={value => this.setState({ ...this.state, id_ultima_venda: value })} />

						<Select
							options={this.props.listaCidade}
							label='Cidade'
							cols='12 6 3 4'
							placeholder='Selecione a cidade' 
							value={this.state.id_cidade} 
							onChange={value => this.setState({ ...this.state, id_cidade: value })} />

						<Grid cols='12 6 4 2' style={{ marginTop: 28 }}>
							<Button
								text='Enviar WhatsApp'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('whatsapp', { clientes: lista.map(item => item.id) });
									this.props.initFormWhatsapp({ clientes: lista.map(item => item.id) });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
										
					<ul class='pagination pagination-sm m-0 float-right'>
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: this.state.paginacaoIndice > 0 ? this.state.paginacaoIndice - 1 : this.state.paginacaoIndice
								})}>{'<<'}
							</a>
						</li>
						
						{paginacao.map((item, i) => (
							<li class='page-item'>
								<a class='page-link'  
									style={{ 
										fontWeight: 'bold',
										cursor: 'pointer',
										backgroundColor: i == this.state.paginacaoIndice ? '#007bff' : null,
										color: i == this.state.paginacaoIndice ? '#fff' : null
									}}
									onClick={() => this.setState({
										...this.state,
										paginacaoIndice: i
									})}>{i + 1}
								</a>
							</li>
						))}
						
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: paginacao.length - 1 > this.state.paginacaoIndice ? this.state.paginacaoIndice + 1 : this.state.paginacaoIndice
								})}>{'>>'}
							</a>
						</li>
					</ul>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value, paginacaoIndice: 0 });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th width={5}>Código</Th>
								<Th width={200}>Razão Social</Th>
								<Th width={200}>Nome Fantasia</Th>
								<Th width={100} alignCenter>CNPJ / CPF</Th>
								<Th width={100} alignCenter>Status</Th>
								<Th width={100} alignCenter>Data Agenda</Th>
								<Th width={100} alignCenter>Último Contato</Th>
								<Th width={100} alignCenter>Última Venda</Th>
								<Th width={200}></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.filter((item, i) => {
								return i >= (paginacao[this.state.paginacaoIndice] ? paginacao[this.state.paginacaoIndice].inicio : 0) 
								&& i <= (paginacao[this.state.paginacaoIndice] ? paginacao[this.state.paginacaoIndice].fim : 1000);
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.id_petroshow}</Td>
									<Td>{item.razao_social}</Td>
									<Td>{item.nome_fantasia}</Td>
									<Td alignCenter>{item.cnpj ? item.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : item.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</Td>
									<Td alignCenter
										bold
										backgroundColor={this.state.cards.filter(card => card.id == item.status)[0].primary}
										color={this.state.cards.filter(card => card.id == item.status)[0].secondary} >
										{this.state.cards.filter(card => card.id == item.status)[0].nome}
									</Td>
									<Td alignCenter
										backgroundColor={
											item.dias_agenda > 3 ? '#5dc763' : (
												item.dias_agenda >= 0 && item.datahora_agenda ? '#f2ec3d' : (
													item.datahora_agenda ? '#e31c07' : null
												)
											)
										}
										color={
											item.dias_agenda > 3 ? '#ffffff' : (
												item.dias_agenda >= 0 && item.datahora_agenda ? '#000000' : (
													item.datahora_agenda ? '#ffffff' : null
												)
											)
										} >
										{DateFormat.formatarDataSqlParaTela(item.datahora_agenda)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.datahora_ultimo_contato)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_ultima_venda)}</Td>
									<Td alignRight minWidth={230}
										backgroundColor={'#ffffff'} >
										<ButtonTable
											type={'primary'}
											icon={'fas fa-headset'}
											visible={this.state.linhaSelecionada == item.id}
											title='Eventos'
											event={() => {
												this.props.getProspeccaoAgenda(item.id_petroshow);
											}} />

										<ButtonTable
											type={'success'}
											icon={'fas fa-id-badge'}
											visible={this.state.linhaSelecionada == item.id}
											title='Contatos'
											event={() => {
												this.props.getProspeccao(item.id_petroshow);
											}} />

										<ButtonTable
											type={'primary'}
											icon={'fas fa-map-marker-alt'}
											visible={this.state.linhaSelecionada == item.id}
											title='Endereços'
											event={() => {
												this.props.setModoTela('endereco', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
										
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											title='Editar'
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											title='Excluir'
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.cliente.modoTela,
	aguardando: state.cliente.aguardando,
	lista: state.cliente.lista,
	listaCidade: state.endereco.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, getListaCidade, importar, getProspeccao, salvarProspeccao, getProspeccaoAgenda,
	getListaProdutoProspeccao, getListaCidadeProspeccao, getListaAtividadeProspeccao, getListaUsuarioProspeccao, initFormWhatsapp, salvarWhatsapp
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteCRM);
