import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaVendaForm';
import Acompanhamento from './metaVendaAcompanhamento';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCliente } from './metaVendaActions';

class MetaVenda extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaCliente();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'acompanhamento' ? (
						<Acompanhamento />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		let listaMeses = [];
		for (let i = 1; i <= 12; i++) {
			listaMeses.push({
				mes: `${i}`.padStart(2, '0')
			});		
		}

		if (!this.props.usuarioCarregado || (
			this.props.usuarioCarregado.id_perfil != 2 //ADMINISTRADOR
			&& !this.props.usuarioCarregado.acesso_vendas
		)) {
			return <div />;
		}

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							{this.props.usuarioCarregado.id_perfil == 2 && //ADMINISTRADOR
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({
										meses: listaMeses
									});
								}} />}
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Exercício</Th>
								<Th>Gerente</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								return this.props.usuarioCarregado.id_perfil == 2 //ADMINISTRADOR
									|| this.props.usuarioCarregado.id_perfil == 6 // Vendedor
									|| this.props.usuarioCarregado.acesso_meta_motorista
									|| this.props.usuarioCarregado.id == 60 //Roger Augusto de Souza Santo
									|| item.nome_a1 == this.props.usuarioCarregado.nome
									|| item.nome_a2 == this.props.usuarioCarregado.nome
									|| item.nome_a3 == this.props.usuarioCarregado.nome
									|| item.nome_a4 == this.props.usuarioCarregado.nome;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.exercicio}</Td>
									<Td>{item.gerente}</Td>
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-chart-line'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {

												const registro = {
													...item,
													peso_volume: FormatUtils.formatarValorTela(item.peso_volume || 0, 4),
													percentual_volume: FormatUtils.formatarValorTela(item.percentual_volume || 0, 4),
													peso_a1: FormatUtils.formatarValorTela(item.peso_a1 || 0, 4),
													peso_a2: FormatUtils.formatarValorTela(item.peso_a2 || 0, 4),
													peso_a3: FormatUtils.formatarValorTela(item.peso_a3 || 0, 4),
													peso_a4: FormatUtils.formatarValorTela(item.peso_a4 || 0, 4),
													meses: (item.meses || []).map(mes => ({
														...mes,
														volume_base: FormatUtils.formatarValorTela(mes.volume_base || 0, 4),
														volume_total: FormatUtils.formatarValorTela(mes.volume_total || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														inadimplencia_global: FormatUtils.formatarValorTela(mes.inadimplencia_global || 0, 4),
														prazo_global: FormatUtils.formatarValorTela(mes.prazo_global || 0, 4),
														volume_a1: FormatUtils.formatarValorTela(mes.volume_a1 || 0, 4),
														margem_a1: FormatUtils.formatarValorTela(mes.margem_a1 || 0, 4),
														inadimplencia_a1: FormatUtils.formatarValorTela(mes.inadimplencia_a1 || 0, 4),
														prazo_a1: FormatUtils.formatarValorTela(mes.prazo_a1 || 0, 4),
														volume_a2: FormatUtils.formatarValorTela(mes.volume_a2 || 0, 4),
														margem_a2: FormatUtils.formatarValorTela(mes.margem_a2 || 0, 4),
														inadimplencia_a2: FormatUtils.formatarValorTela(mes.inadimplencia_a2 || 0, 4),
														prazo_a2: FormatUtils.formatarValorTela(mes.prazo_a2 || 0, 4),
														volume_a3: FormatUtils.formatarValorTela(mes.volume_a3 || 0, 4),
														margem_a3: FormatUtils.formatarValorTela(mes.margem_a3 || 0, 4),
														inadimplencia_a3: FormatUtils.formatarValorTela(mes.inadimplencia_a3 || 0, 4),
														prazo_a3:FormatUtils.formatarValorTela(mes.prazo_a3 || 0, 4),
														volume_a4: FormatUtils.formatarValorTela(mes.volume_a4 || 0, 4),
														margem_a4: FormatUtils.formatarValorTela(mes.margem_a4 || 0, 4),
														inadimplencia_a4: FormatUtils.formatarValorTela(mes.inadimplencia_a4 || 0, 4),
														prazo_a4:FormatUtils.formatarValorTela(mes.prazo_a4 || 0, 4),
														realizado_volume_a1: FormatUtils.formatarValorTela(mes.realizado_volume_a1 || 0, 4),
														realizado_margem_a1: FormatUtils.formatarValorTela(mes.realizado_margem_a1 || 0, 4),
														realizado_inadimplencia_a1: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a1 || 0, 4),
														realizado_prazo_a1: FormatUtils.formatarValorTela(mes.realizado_prazo_a1 || 0, 4),
														realizado_volume_a2: FormatUtils.formatarValorTela(mes.realizado_volume_a2 || 0, 4),
														realizado_margem_a2: FormatUtils.formatarValorTela(mes.realizado_margem_a2 || 0, 4),
														realizado_inadimplencia_a2: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a2 || 0, 4),
														realizado_prazo_a2: FormatUtils.formatarValorTela(mes.realizado_prazo_a2 || 0, 4),
														realizado_volume_a3: FormatUtils.formatarValorTela(mes.realizado_volume_a3 || 0, 4),
														realizado_margem_a3: FormatUtils.formatarValorTela(mes.realizado_margem_a3 || 0, 4),
														realizado_inadimplencia_a3: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a3 || 0, 4),
														realizado_prazo_a3: FormatUtils.formatarValorTela(mes.realizado_prazo_a3 || 0, 4),
														realizado_volume_a4: FormatUtils.formatarValorTela(mes.realizado_volume_a4 || 0, 4),
														realizado_margem_a4: FormatUtils.formatarValorTela(mes.realizado_margem_a4 || 0, 4),
														realizado_inadimplencia_a4: FormatUtils.formatarValorTela(mes.realizado_inadimplencia_a4 || 0, 4),
														realizado_prazo_a4: FormatUtils.formatarValorTela(mes.realizado_prazo_a4 || 0, 4),
														ebitda: FormatUtils.formatarValorTela(mes.ebitda || 0, 4),
														faturamento: FormatUtils.formatarValorTela(mes.faturamento || 0, 4)
													}))
												};
												this.props.setModoTela('acompanhamento', registro);
												this.props.initForm(registro);
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id && this.props.usuarioCarregado.id_perfil == 2} //ADMINISTRADOR
											event={() => {

												const registro = {
													...item,
													peso_volume: FormatUtils.formatarValorTela(item.peso_volume || 0, 4),
													percentual_volume: FormatUtils.formatarValorTela(item.percentual_volume || 0, 4),
													peso_a1: FormatUtils.formatarValorTela(item.peso_a1 || 0, 4),
													peso_a2: FormatUtils.formatarValorTela(item.peso_a2 || 0, 4),
													peso_a3: FormatUtils.formatarValorTela(item.peso_a3 || 0, 4),
													peso_a4: FormatUtils.formatarValorTela(item.peso_a4 || 0, 4),
													meses: (item.meses || []).map(mes => ({
														...mes,
														volume_base: FormatUtils.formatarValorTela(mes.volume_base || 0, 4),
														volume_total: FormatUtils.formatarValorTela(mes.volume_total || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														inadimplencia_global: FormatUtils.formatarValorTela(mes.inadimplencia_global || 0, 4),
														prazo_global: FormatUtils.formatarValorTela(mes.prazo_global || 0, 4),
														volume_a1: FormatUtils.formatarValorTela(mes.volume_a1 || 0, 4),
														margem_a1: FormatUtils.formatarValorTela(mes.margem_a1 || 0, 4),
														inadimplencia_a1: FormatUtils.formatarValorTela(mes.inadimplencia_a1 || 0, 4),
														prazo_a1: FormatUtils.formatarValorTela(mes.prazo_a1 || 0, 4),
														volume_a2: FormatUtils.formatarValorTela(mes.volume_a2 || 0, 4),
														margem_a2: FormatUtils.formatarValorTela(mes.margem_a2 || 0, 4),
														inadimplencia_a2: FormatUtils.formatarValorTela(mes.inadimplencia_a2 || 0, 4),
														prazo_a2: FormatUtils.formatarValorTela(mes.prazo_a2 || 0, 4),
														volume_a3: FormatUtils.formatarValorTela(mes.volume_a3 || 0, 4),
														margem_a3: FormatUtils.formatarValorTela(mes.margem_a3 || 0, 4),
														inadimplencia_a3: FormatUtils.formatarValorTela(mes.inadimplencia_a3 || 0, 4),
														prazo_a3:FormatUtils.formatarValorTela(mes.prazo_a3 || 0, 4),
														volume_a4: FormatUtils.formatarValorTela(mes.volume_a4 || 0, 4),
														margem_a4: FormatUtils.formatarValorTela(mes.margem_a4 || 0, 4),
														inadimplencia_a4: FormatUtils.formatarValorTela(mes.inadimplencia_a4 || 0, 4),
														prazo_a4:FormatUtils.formatarValorTela(mes.prazo_a4 || 0, 4),
														ebitda: FormatUtils.formatarValorTela(mes.ebitda || 0, 4),
														faturamento: FormatUtils.formatarValorTela(mes.faturamento || 0, 4)
													}))
												};
												this.props.setModoTela('cadastro', registro);
												this.props.initForm(registro);
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id && this.props.usuarioCarregado.id_perfil == 2} //ADMINISTRADOR
											event={() => {
												const registro = {
													...item,
													peso_volume: FormatUtils.formatarValorTela(item.peso_volume || 0, 4),
													percentual_volume: FormatUtils.formatarValorTela(item.percentual_volume || 0, 4),
													peso_a1: FormatUtils.formatarValorTela(item.peso_a1 || 0, 4),
													peso_a2: FormatUtils.formatarValorTela(item.peso_a2 || 0, 4),
													peso_a3: FormatUtils.formatarValorTela(item.peso_a3 || 0, 4),
													peso_a4: FormatUtils.formatarValorTela(item.peso_a4 || 0, 4),
													meses: (item.meses || []).map(mes => ({
														...mes,
														volume_base: FormatUtils.formatarValorTela(mes.volume_base || 0, 4),
														volume_total: FormatUtils.formatarValorTela(mes.volume_total || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														margem_global: FormatUtils.formatarValorTela(mes.margem_global || 0, 4),
														inadimplencia_global: FormatUtils.formatarValorTela(mes.inadimplencia_global || 0, 4),
														prazo_global: FormatUtils.formatarValorTela(mes.prazo_global || 0, 4),
														volume_a1: FormatUtils.formatarValorTela(mes.volume_a1 || 0, 4),
														margem_a1: FormatUtils.formatarValorTela(mes.margem_a1 || 0, 4),
														inadimplencia_a1: FormatUtils.formatarValorTela(mes.inadimplencia_a1 || 0, 4),
														prazo_a1: FormatUtils.formatarValorTela(mes.prazo_a1 || 0, 4),
														volume_a2: FormatUtils.formatarValorTela(mes.volume_a2 || 0, 4),
														margem_a2: FormatUtils.formatarValorTela(mes.margem_a2 || 0, 4),
														inadimplencia_a2: FormatUtils.formatarValorTela(mes.inadimplencia_a2 || 0, 4),
														prazo_a2: FormatUtils.formatarValorTela(mes.prazo_a2 || 0, 4),
														volume_a3: FormatUtils.formatarValorTela(mes.volume_a3 || 0, 4),
														margem_a3: FormatUtils.formatarValorTela(mes.margem_a3 || 0, 4),
														inadimplencia_a3: FormatUtils.formatarValorTela(mes.inadimplencia_a3 || 0, 4),
														prazo_a3:FormatUtils.formatarValorTela(mes.prazo_a3 || 0, 4),
														volume_a4: FormatUtils.formatarValorTela(mes.volume_a4 || 0, 4),
														margem_a4: FormatUtils.formatarValorTela(mes.margem_a4 || 0, 4),
														inadimplencia_a4: FormatUtils.formatarValorTela(mes.inadimplencia_a4 || 0, 4),
														prazo_a4:FormatUtils.formatarValorTela(mes.prazo_a4 || 0, 4),
														ebitda: FormatUtils.formatarValorTela(mes.ebitda || 0, 4),
														faturamento: FormatUtils.formatarValorTela(mes.faturamento || 0, 4)
													}))
												};
												this.props.setModoTela('exclusao', registro);
												this.props.initForm(registro);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.metaVenda.modoTela,
	lista: state.metaVenda.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVenda);
