import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setFiltro(filtro) {
    return {
        type: 'RELATORIO_MOVIMENTOS_FILTRO',
        payload: filtro
    };
}

export function getLista() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/entrada/listarMovimentos`)
		.then(resp => {
			dispatch({
		        type: 'RELATORIO_MOVIMENTOS_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'RELATORIO_MOVIMENTOS_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'RELATORIO_MOVIMENTOS_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function imprimir(cabecalho, paginas, totalizadores) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/entrada/gerarRelatorioMovimentosPdf`, {
			cabecalho, paginas, totalizadores
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}
