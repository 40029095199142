import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './enderecoForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCidade, getListaVeculoTipo } from './enderecoActions';

class Endereco extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista(this.props.id_cliente);
		this.props.getListaCidade();
		this.props.getListaVeculoTipo();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'endereco' ? (
						<Form endereco onSubmit={this.props.salvar} />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ id_cliente: this.props.id_cliente });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Endereço</Th>
								<Th>Cidade</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.endereco}</Td>
									<Td>{item.nome_cidade}</Td>
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.endereco.modoTela,
	lista: state.endereco.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaCidade, getListaVeculoTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Endereco);
