import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaVendedorForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';

import { getListaResultado as getLista } from './metaVendedorActions';

class MetaVendedorResultado extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		competencia: `${new Date().getFullYear()}${String(new Date().getMonth()).padStart(2, '0')}`
	}

    componentWillMount() {
        this.props.getLista();
    }

	render() {

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		return (
			<div>
                <Content>
					<ContentCard>
						<ContentCardBody>
							<Row>
								<Select
									options={listaCompetencia}
									label='Competência'
									cols='12 4 4 3'
									placeholder='Selecione a competência'
									value={this.state.competencia}
									onChange={value => this.setState({ ...this.state, competencia: value })} />
							</Row>

							<Table responsive>
								<THead>
									<Tr>
										<Th rowspan='2'>Competência</Th>
										<Th rowspan='2'>Vendedor</Th>
										<Th alignCenter colspan='3' backgroundColor={'#f2f2f0'}>Volume</Th>
										<Th alignCenter colspan='3'>Margem Global</Th>
										<Th alignCenter colspan='3' backgroundColor={'#f2f2f0'}>Inadimplência Global</Th>
										<Th alignCenter colspan='3'>Prazo Global</Th>
									</Tr>
									<Tr>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Meta</Th>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Resultado</Th>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Desempenho</Th>
										<Th alignCenter>Meta</Th>
										<Th alignCenter>Resultado</Th>
										<Th alignCenter>Desempenho</Th>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Meta</Th>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Resultado</Th>
										<Th alignCenter backgroundColor={'#f2f2f0'}>Desempenho</Th>
										<Th alignCenter>Meta</Th>
										<Th alignCenter>Resultado</Th>
										<Th alignCenter>Desempenho</Th>
									</Tr>
								</THead>
								<TBody>
									{(this.props.lista).filter(item => {
										if (!this.state.competencia || item.competencia != this.state.competencia) {
											return false;
										}
										return true;
									}).map(item => (
										<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{FormatUtils.formatarCompetenciaTela(item.competencia)}</Td>
											<Td>{item.nome_vendedor}</Td>
											<Td alignCenter backgroundColor={'#f2f2f0'}>{FormatUtils.formatarValorTela(item.volume, 2)}</Td>
											<Td alignCenter backgroundColor={'#f2f2f0'}>{FormatUtils.formatarValorTela(item.resultado_volume, 2)}</Td>
											<Td alignCenter backgroundColor={item.desempenho_volume > 100 ? '#bae6be' : (item.desempenho_volume > 80 ? '#d2db70' : '#f5718b')}>{FormatUtils.formatarValorTela(item.desempenho_volume, 0)}%</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.margem, 2)}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.resultado_margem, 2)}</Td>
											<Td alignCenter backgroundColor={item.desempenho_margem > 100 ? '#bae6be' : (item.desempenho_margem > 80 ? '#d2db70' : '#f5718b')}>{FormatUtils.formatarValorTela(item.desempenho_margem, 0)}%</Td>
											<Td alignCenter backgroundColor={'#f2f2f0'}>{FormatUtils.formatarValorTela(item.inadimplencia, 2)}</Td>
											<Td alignCenter backgroundColor={'#f2f2f0'}>{FormatUtils.formatarValorTela(item.resultado_inadimplencia, 2)}</Td>
											<Td alignCenter backgroundColor={item.desempenho_inadimplencia > 100 ? '#bae6be' : (item.desempenho_inadimplencia > 80 ? '#d2db70' : '#f5718b')}>{FormatUtils.formatarValorTela(item.desempenho_inadimplencia, 0)}%</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.prazo, 2)}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.resultado_prazo, 2)}</Td>
											<Td alignCenter backgroundColor={item.desempenho_prazo > 100 ? '#bae6be' : (item.desempenho_prazo > 80 ? '#d2db70' : '#f5718b')}>{FormatUtils.formatarValorTela(item.desempenho_prazo, 0)}%</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</div>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.metaVendedor,
	modoTela: state.metaVendedor.modoTela,
	lista: state.metaVendedor.listaResultado,
	listaVendedor: state.metaVendedor.listaVendedor
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendedorResultado);
