const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaVeiculo: [],
    listaTipo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VEICULO_DOCUMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'VEICULO_DOCUMENTO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'VEICULO_DOCUMENTO_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };

        case 'VEICULO_DOCUMENTO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
            };
                      
        default:
            return state;
    }
}
