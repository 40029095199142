import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			<label htmlFor={props.name}>{props.label}</label>
			{props.onChange ? (
				<select
					{...props.input}
					className="form-control"
					value={props.value}
					onChange={props.onChange}
					readOnly={props.readOnly} >
					<option value="0">{props.placeholder}</option>
					{props.options.map(opt => {
						return (
							<option
								key={opt.id}
								value={opt.id}
								disabled={props.readOnly == 'readOnly'} >{opt.valor}</option>
						);
					})}
				</select>
			) : (
				<select
					{...props.input}
					className="form-control"
					readOnly={props.readOnly} >
					<option value="0">{props.placeholder}</option>
					{props.options.map(opt => {
						return (
							<option
								key={opt.id}
								value={opt.id}
								disabled={props.readOnly == 'readOnly'} >{opt.valor}</option>
						);
					})}
				</select>
			)}
       </div>
   </Grid>
)
