import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ENTRADA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('entradaForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/entrada`);
    return {
        type: 'ENTRADA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/entrada`, {
                ...registro,
                datahora_previsao_chegada: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_previsao_chegada),
                codigo_sm_ida: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_ida, 0)) || null,
                codigo_sm_volta: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_volta, 0)) || null,
				itens: (registro.itens || []).map(item => ({
					...item,
                    quantidade: FormatUtils.formatarValorBanco(item.quantidade, 2)
                })),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/entrada`, {
                ...registro,
                datahora_previsao_chegada: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_previsao_chegada),
                codigo_sm_ida: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_ida, 0)) || null,
                codigo_sm_volta: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_volta, 0)) || null,
				itens: (registro.itens || []).map(item => ({
					...item,
                    quantidade: FormatUtils.formatarValorBanco(item.quantidade, 2)
                })),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function carregar(registro) {
    return (dispatch, getState) => {

        axios.put(`${consts.API_URL}/entrada/carregar`, {
            ...registro,
            id_usuario_alteracao: getState().auth.usuario.id
        }).then(resp => {
            setSuccess('Operação Realizada com sucesso.');
            dispatch(getLista());
            dispatch(setModoTela('lista'));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function descarregar(registro) {
    return (dispatch, getState) => {

        axios.put(`${consts.API_URL}/entrada/descarregar`, {
            ...registro,
            id_usuario_alteracao: getState().auth.usuario.id
        }).then(resp => {
            setSuccess('Operação Realizada com sucesso.');
            dispatch(getLista());
            dispatch(setModoTela('lista'));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/entrada?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaPedido() {
    const request = axios.get(`${consts.API_URL}/pedido/logisticadireta`);
    return {
        type: 'ENTRADA_PEDIDO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'ENTRADA_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'ENTRADA_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTanque() {
    const request = axios.get(`${consts.API_URL}/tanque/listarSelect`);
    return {
        type: 'ENTRADA_TANQUE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'ENTRADA_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'ENTRADA_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaDistribuidora() {
    const request = axios.get(`${consts.API_URL}/distribuidora/listarSelect`);
    return {
        type: 'ENTRADA_DISTRIBUIDORA_SELECT_LISTADO',
        payload: request
    };
}
	