import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioPerfil from '../usuarioPerfil/usuarioPerfil';
import Usuario from '../usuario/usuario';
import Unidade from '../unidade/unidade';
import Motorista from '../motorista/motorista';
import VeiculoTipo from '../veiculoTipo/veiculoTipo';
import Veiculo from '../veiculo/veiculo';
import Produto from '../produto/produto';
import Distribuidora from '../distribuidora/distribuidora';
import Tanque from '../tanque/tanque';
import Cliente from '../cliente/cliente';
import ClienteCRM from '../cliente/clienteCRM';
import ClienteMapa from '../cliente/clienteMapa';
import ClienteCRMHistorico from '../cliente/clienteCRMHistorico';
import PrecoDia from '../precoDia/precoDia';
import AdicionalDiario from '../adicionalDiario/adicionalDiario';
import FreteLitro from '../freteLitro/freteLitro';
import PrecoGeral from '../precoGeral/precoGeral';
import PoliticaPreco from '../politicaPreco/politicaPreco';
import Pedido from '../pedido/pedido';
import PedidoAprovacao from '../pedido/pedidoAprovacao';
import PedidoLogistica from '../pedido/pedidoLogistica';
import PedidoFinalizado from '../pedido/pedidoFinalizado';
import PedidoEntrega from '../pedido/pedidoEntrega';
import CustoPrazo from '../custoPrazo/custoPrazo';
import Entrada from '../entrada/entrada';
import Saida from '../saida/saida';
import Transferencia from '../transferencia/transferencia';
import AnaliseCurvaPreco from '../analiseCurvaPreco/analiseCurvaPreco';
import DashboardPedido from '../dashboardPedido/dashboardPedido';
import DashboardPetroshow from '../dashboardPetroshow/dashboardPetroshow';
import Setor from '../setor/setor';
import ApontamentoGrupo from '../apontamentoGrupo/apontamentoGrupo';
import ApontamentoTipo from '../apontamentoTipo/apontamentoTipo';
import Apontamento from '../apontamento/apontamento';
import VeiculoTipoConsumo from '../veiculoTipoConsumo/veiculoTipoConsumo';
import MetaMotorista from '../metaMotorista/metaMotorista';
import MetaMotoristaGrupo from '../metaMotoristaGrupo/metaMotoristaGrupo';
import Chamado from '../chamado/chamado';
import MetaVenda from '../metaVenda/metaVenda';
import MetaVendedor from '../metaVendedor/metaVendedor';
import MetaVendedorResultado from '../metaVendedor/metaVendedorResultado';
import Prospeccao from '../prospeccao/prospeccao';
import VeiculoStatus from '../veiculoStatus/veiculoStatus';
import PontoMapa from '../pontoMapa/pontoMapa';
import Whatsapp from '../whatsapp/whatsapp';
import Checklist from '../checklist/checklist';
import MetaVendaAcompanhamentoDashboard from '../metaVenda/metaVendaAcompanhamentoDashboard';
import MetaVendaAcompanhamentoDashboardGerencia from '../metaVenda/metaVendaAcompanhamentoDashboardGerencia';
import VeiculoDashboard from '../veiculo/veiculoDashboard';
import VeiculoDashboardKmRodado from '../veiculo/veiculoDashboardKmRodado';
import RelatorioMovimentos from '../relatorioMovimentos/relatorioMovimentos';
import Oficina from '../oficina/oficina';
import Manutencao from '../manutencao/manutencao';
import EstoqueManual from '../estoqueManual/estoqueManual';
import EstoqueManualPetroshow from '../estoqueManual/estoqueManualPetroshow';
import PedidoEstoque from '../pedido/pedidoEstoque';
import LimiteCredito from '../limiteCredito/limiteCredito';
import VeiculoDocumento from '../veiculoDocumento/veiculoDocumento';
import ManutencaoPreventivaTipo from '../manutencaoPreventivaTipo/manutencaoPreventivaTipo';
import ManutencaoPreventiva from '../manutencaoPreventiva/manutencaoPreventiva';
import VeiculoDespesa from '../veiculoDespesa/veiculoDespesa';
import DashboardCliente from '../dashboardCliente/dashboardCliente';

export default props => {

	if (!props.id_cliente) {

		return (
			<div id='id-content-wrapper' className='content-wrapper' >
				<Switch>
					<Route exact path='/' component={Dashboard} />
					<Route path='/dashboardPedido' component={DashboardPedido} />
					<Route path='/dashboardPetroshow' component={DashboardPetroshow} />
					<Route path='/usuarioPerfil' component={UsuarioPerfil} />
					<Route path='/usuario' component={Usuario} />
					<Route path='/unidade' component={Unidade} />
					<Route path='/motorista' component={Motorista} />
					<Route path='/veiculoTipo' component={VeiculoTipo} />
					<Route path='/veiculo' component={Veiculo} />
					<Route path='/produto' component={Produto} />
					<Route path='/distribuidora' component={Distribuidora} />
					<Route path='/tanque' component={Tanque} />
					<Route path='/cliente' component={Cliente} />
					<Route path='/clienteCRM' component={ClienteCRM} />
					<Route path='/clienteMapa' component={ClienteMapa} />
					<Route path='/clienteCRMHistorico' component={ClienteCRMHistorico} />
					<Route path='/precoDia' component={PrecoDia} />
					<Route path='/adicionalDiario' component={AdicionalDiario} />
					<Route path='/freteLitro' component={FreteLitro} />
					<Route path='/precoGeral' component={PrecoGeral} />
					<Route path='/politicaPreco' component={PoliticaPreco} />
					<Route path='/pedido' component={Pedido} />
					<Route path='/pedidoAprovacao' component={PedidoAprovacao} />
					<Route path='/pedidoLogistica' component={PedidoLogistica} />
					<Route path='/pedidoFinalizado' component={PedidoFinalizado} />
					<Route path='/pedidoEntrega' component={PedidoEntrega} />
					<Route path='/pedidoEstoque' component={PedidoEstoque} />
					<Route path='/custoPrazo' component={CustoPrazo} />
					<Route path='/entrada' component={Entrada} />
					<Route path='/saida' component={Saida} />
					<Route path='/transferencia' component={Transferencia} />
					<Route path='/analiseCurvaPreco' component={AnaliseCurvaPreco} />
					<Route path='/setor' component={Setor} />
					<Route path='/apontamentoGrupo' component={ApontamentoGrupo} />
					<Route path='/apontamentoTipo' component={ApontamentoTipo} />
					<Route path='/apontamento' component={Apontamento} />
					<Route path='/veiculoTipoConsumo' component={VeiculoTipoConsumo} />
					<Route path='/metaMotorista' component={MetaMotorista} />
					<Route path='/metaMotoristaGrupo' component={MetaMotoristaGrupo} />
					<Route path='/chamado' component={Chamado} />
					<Route path='/metaVenda' component={MetaVenda} />
					<Route path='/metaVendedor' component={MetaVendedor} />
					<Route path='/metaVendedorResultado' component={MetaVendedorResultado} />
					<Route path='/prospeccao' component={Prospeccao} />
					<Route path='/veiculoStatus' component={VeiculoStatus} />
					<Route path='/pontoMapa' component={PontoMapa} />
					<Route path='/whatsapp' component={Whatsapp} />
					<Route path='/checklist' component={Checklist} />
					<Route path='/metaVendaDashboard' component={MetaVendaAcompanhamentoDashboard} />
					<Route path='/metaVendaDashboardGerencia' component={MetaVendaAcompanhamentoDashboardGerencia} />
					<Route path='/veiculoDashboard' component={VeiculoDashboard} />
					<Route path='/veiculoDashboardKmRodado' component={VeiculoDashboardKmRodado} />
					<Route path='/relatorioMovimentos' component={RelatorioMovimentos} />
					<Route path='/oficina' component={Oficina} />
					<Route path='/manutencao' component={Manutencao} />
					<Route path='/estoqueManual' component={EstoqueManual} />
					<Route path='/estoquePetroshow' component={EstoqueManualPetroshow} />
					<Route path='/limiteCredito' component={LimiteCredito} />
					<Route path='/veiculoDocumento' component={VeiculoDocumento} />
					<Route path='/manutencaoPreventivaTipo' component={ManutencaoPreventivaTipo} />
					<Route path='/manutencaoPreventiva' component={ManutencaoPreventiva} />
					<Route path='/veiculoDespesa' component={VeiculoDespesa} />
					<Redirect from='*' to='/' />
				</Switch>
			</div>
		);
	} else {
	
		return (
			<div id='id-content-wrapper' className='content-wrapper' >
				<Switch>
					<Route exact path='/' component={DashboardCliente} />
					<Redirect from='*' to='/' />
				</Switch>
			</div>
		);
	}
}
