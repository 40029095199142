import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ADICIONAL_DIARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'ADICIONAL_DIARIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'ADICIONAL_DIARIO_FILTRO',
        payload: filtro
    };
}

export function initForm(registro = {}) {
    return [
        initialize('adicionalDiarioForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/adicionalDiario?data=${DateFormat.formatarDataTelaParaSql(getState().adicionalDiario.filtro.data)}`)
		.then(resp => {
			dispatch({
		        type: 'ADICIONAL_DIARIO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));		
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/adicionalDiario`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(setAguardando(false));
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/adicionalDiario`, {
				...registro,
				percentual_indice: FormatUtils.formatarValorBanco(registro.percentual_indice, 4),
				dias_prazo: FormatUtils.formatarValorBanco(registro.dias_prazo, 4),
				percentual_fator: (30 - parseFloat(FormatUtils.formatarValorBanco(registro.dias_prazo, 4))) * parseFloat(FormatUtils.formatarValorBanco(registro.percentual_indice, 4)),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(async resp => {

				if (registro.indiceAlterado && registro.percentual_indice > 0) {
					for (let i = 0; i < getState().adicionalDiario.lista.length; i++) {

						let item = getState().adicionalDiario.lista[i];

						if (item.id != registro.id) {

							await axios.put(`${consts.API_URL}/adicionalDiario`, {
								...item,
								percentual_indice: FormatUtils.formatarValorBanco(registro.percentual_indice, 4),
								percentual_fator: (30 - parseFloat(item.dias_prazo)) * parseFloat(FormatUtils.formatarValorBanco(registro.percentual_indice, 4)),
								id_usuario_alteracao: getState().auth.usuario.id
							}).then(resp => {
								console.log(item.nome_unidade);								
							}).catch(e => {
								setErroAPI(e);
							});		
						
						}
					}
				}

				let nomeDistribuidora = registro.nome_distribuidora.split('-')[0].trim();
		
				for (let i = 0; i < getState().adicionalDiario.lista.length; i++) {

					let item = getState().adicionalDiario.lista[i];
					
					if (item.id != registro.id && item.nome_distribuidora.split('-')[0].trim() == nomeDistribuidora) {

						await axios.put(`${consts.API_URL}/adicionalDiario`, {
							...item,
							percentual_indice: FormatUtils.formatarValorBanco(registro.percentual_indice, 4),
							dias_prazo: FormatUtils.formatarValorBanco(registro.dias_prazo, 4),
							percentual_fator: (30 - parseFloat(FormatUtils.formatarValorBanco(registro.dias_prazo, 4))) * parseFloat(FormatUtils.formatarValorBanco(registro.percentual_indice, 4)),
							id_usuario_alteracao: getState().auth.usuario.id
						}).then(async resp => {

						}).catch(e => {
							setErroAPI(e);
						});		
					
					}
				}

				setSuccess('Operação Realizada com sucesso.');
				dispatch(setAguardando(false));
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/adicionalDiario?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
