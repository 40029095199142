import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import {
	setModoTela, initForm, salvar
} from './limiteCreditoActions';
import DateFormat from '../common/dateFormat/DateFormat';

class LimiteCreditoForm extends Component {

	state = {
		tabSelecionada: 0
	}

	componentWillMount() {

		let initSeletorBalanco = () => {
			const fileSelectorBalanco = document.createElement('input');
			fileSelectorBalanco.setAttribute('type', 'file');
			fileSelectorBalanco.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.balanco_irpf || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							balanco_irpf: lista
						});

						initSeletorBalanco();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorBalanco = fileSelectorBalanco;
		};

		initSeletorBalanco();

		let initSeletorDocumentos = () => {
			const fileSelectorDocumentos = document.createElement('input');
			fileSelectorDocumentos.setAttribute('type', 'file');
			fileSelectorDocumentos.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.documentos || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							documentos: lista
						});

						initSeletorDocumentos();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorDocumentos = fileSelectorDocumentos;
		};

		initSeletorDocumentos();

		let initSeletorContratoSocial = () => {
			const fileSelectorContratoSocial = document.createElement('input');
			fileSelectorContratoSocial.setAttribute('type', 'file');
			fileSelectorContratoSocial.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.contrato_social || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							contrato_social: lista
						});

						initSeletorContratoSocial();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorContratoSocial = fileSelectorContratoSocial;
		};

		initSeletorContratoSocial();

    }

    render() {

		if (this.state.tabSelecionada == 0) {
			this.setState({
				...this.state,
				tabSelecionada: this.props.formularioValues.datahora_gerencia_comercial ? 4 : (
					this.props.formularioValues.datahora_financeiro ? 3 : (
						this.props.formularioValues.datahora_abertura ? 2 : 1
					)
				) 
			});
		}

		let readOnly = '';

		if (this.props.excluir) {
			readOnly = 'readOnly';
		} else if (this.state.tabSelecionada == 1 && this.props.formularioValues.datahora_abertura) {
			readOnly = 'readOnly';
		} else if (this.state.tabSelecionada == 2 && this.props.formularioValues.datahora_financeiro) {
			readOnly = 'readOnly';
		} else if (this.state.tabSelecionada == 3 && this.props.formularioValues.datahora_gerencia_comercial) {
			readOnly = 'readOnly';
		} else if (this.state.tabSelecionada == 4 && this.props.formularioValues.datahora_gerencia_geral) {
			readOnly = 'readOnly';
		}

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					
					<div style={{ display: 'flex' }}>
						<a class={`btn btn-app bg-${this.state.tabSelecionada == 1 ? 'success' : 'info'}`} 
							style={{ width: '25%' }}
							onClick={() => {
								if (this.props.formularioValues.datahora_abertura) {
									this.setState({
										...this.state,
										tabSelecionada: 1
									});
								}
							}} >
							<i class={`fas fa-${this.props.formularioValues.datahora_abertura ? 'check' : 'pen'}`}></i> Cadastro Financeiro
						</a>

						<a class={`btn btn-app bg-${this.props.formularioValues.datahora_abertura ? (this.state.tabSelecionada == 2 ? 'success' : 'info') : 'secondary'}`} 
							style={{ width: '25%' }}
							onClick={() => {
								if (this.props.formularioValues.datahora_abertura) {
									this.setState({
										...this.state,
										tabSelecionada: 2
									});
								}
							}} >
							<i class={`fas fa-${this.props.formularioValues.datahora_financeiro ? 'check' : (this.props.formularioValues.datahora_abertura && !this.props.formularioValues.datahora_gerencia_comercial ? 'pen' : 'ban')}`}></i> Solicitar Limite de Crédito
						</a>

						<a class={`btn btn-app bg-${this.props.formularioValues.datahora_financeiro ? (this.state.tabSelecionada == 3 ? 'success' : 'info') : 'secondary'}`} 
							style={{ width: '25%' }}
							onClick={() => {
								if (this.props.formularioValues.datahora_financeiro) {
									this.setState({
										...this.state,
										tabSelecionada: 3
									});
								}
							}} >
							<i class={`fas fa-${this.props.formularioValues.datahora_gerencia_comercial ? 'check' : (this.props.formularioValues.datahora_financeiro && !this.props.formularioValues.datahora_gerencia_geral ? 'pen' : 'ban')}`}></i> Parecer Gerência Comercial
						</a>

						<a class={`btn btn-app bg-${this.props.formularioValues.datahora_gerencia_comercial ? (this.state.tabSelecionada == 4 ? 'success' : 'info') : 'secondary'}`} 
							style={{ width: '25%' }}
							onClick={() => {
								if (this.props.formularioValues.datahora_gerencia_comercial) {
									this.setState({
										...this.state,
										tabSelecionada: 4
									});
								}
							}} >
							<i class={`fas fa-${this.props.formularioValues.datahora_gerencia_geral ? 'check' : (this.props.formularioValues.datahora_gerencia_comercial ? 'pen' : 'ban')}`}></i> Parecer Gerência Geral
						</a>
					</div>

					<ContentCardBody>

						{/* ABA 1 */}
						{this.state.tabSelecionada == 1 &&
						<>
						<Row>

							<Select
								options={[{ id: 1, valor: 'Cadastro Novo' }, { id: 2, valor: 'Alteração de Limite' }]}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={this.props.formularioValues.id ? 'readOnly' : null}
								value={this.props.formularioValues.id_tipo}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										id_tipo: value,
										id_cliente: value == 2 ? this.props.formularioValues.id_cliente : null,
										id_endereco: value == 2 ? this.props.formularioValues.id_endereco : null,
										cpf_cnpj: value == 1 ? this.props.formularioValues.cpf_cnpj : null,
										nome: value == 1 ? this.props.formularioValues.nome : null,
										endereco: value == 1 ? this.props.formularioValues.endereco : null,
									});
								}} />

							{this.props.formularioValues.id_tipo == 2 &&
							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente'
								cols='12 6 6 6'
								placeholder='Selecione o cliente'
								readOnly={readOnly} />}

							{this.props.formularioValues.id_tipo == 2 &&
							<Field
								name='id_endereco'
								component={Select}
								options={this.props.listaEndereco.filter(item => item.id_cliente == this.props.formularioValues.id_cliente)}
								label='Endereço'
								cols='12 6 6 6'
								placeholder='Selecione o tipo'
								readOnly={readOnly} />}
								
							{!this.props.formularioValues.id &&
							<Select
								label='Tipo de Pessoa'
								options={[{ id: 1, valor: 'Pessoa Física' }, { id: 2, valor: 'Pessoa Jurídica' }]}
								cols='12 6 4 4'
								placeholder='Selecione o tipo'
								readOnly={this.props.formularioValues.id ? 'readOnly' : null}
								value={this.props.formularioValues.id_tipo_pessoa}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										id_tipo_pessoa: value,
										cpf_cnpj: null
									});
								}} />}

							{(this.props.formularioValues.id_tipo == 1 && this.props.formularioValues.id_tipo_pessoa == 1) &&
							<Field
								name='cpf_cnpj'
								component={LabelAndInputMask}
								label='CPF' placeholder='Informe o CPF'
								cols='12 6 4 4'
								mask='999.999.999-99'
								readOnly={readOnly} />}
								
							{(this.props.formularioValues.id_tipo == 1 && this.props.formularioValues.id_tipo_pessoa == 2) &&
							<Field
								name='cpf_cnpj'
								component={LabelAndInputMask}
								label='CNPJ' placeholder='Informe o CNPJ'
								cols='12 6 4 4'
								mask='99.999.999/9999-99'
								readOnly={readOnly} />}

							{this.props.formularioValues.id_tipo == 1 &&
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 6 6 6'
								readOnly={readOnly} />}

							{this.props.formularioValues.id_tipo == 1 &&
							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereço'
								cols='12 6 6 6'
								readOnly={readOnly} />}
							
						</Row>

						<Row>
							<Grid cols='12 4 4 4'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Balanço / IRPF</label>
									</Grid>
									{(this.props.formularioValues.balanco_irpf || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`https://api-valedaserra.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															balanco_irpf: this.props.formularioValues.balanco_irpf.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorBalanco.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>

							</Grid>

							<Grid cols='12 4 4 4'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Documentos</label>
									</Grid>
									{(this.props.formularioValues.documentos || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`https://api-valedaserra.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															documentos: this.props.formularioValues.documentos.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorDocumentos.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>

							</Grid>
								
							<Grid cols='12 4 4 4'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Contrato Social</label>
									</Grid>
									{(this.props.formularioValues.contrato_social || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`https://api-valedaserra.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															contrato_social: this.props.formularioValues.contrato_social.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorContratoSocial.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>
							
							</Grid>
						</Row>

						<Row>

							<Field
								name='referencias_comerciais'
								component={LabelAndInput}
								label='Referências Comerciais' placeholder='Informe as referências comerciais'
								cols='12 6 6 6'
								readOnly={readOnly} />

							<Field
								name='referencia_bancaria'
								component={LabelAndInput}
								label='Referências Bancárias' placeholder='Informe as referências bancárias'
								cols='12 6 6 6'
								readOnly={readOnly} />

							<Field
								name='referencia_fornecedor'
								component={LabelAndInput}
								label='Referências (Fornecedor)' placeholder='Informe a referência de onde compra'
								cols='12 6 6 6'
								readOnly={readOnly} />
								
							<Field
								name='referencia_pessoal'
								component={LabelAndInput}
								label='Referências Pessoais' placeholder='Informe as referências pessoais'
								cols='12 6 6 6'
								readOnly={readOnly} />

						</Row> 
						<Row>
							<Field
								name='volume_mes'
								component={LabelAndInputNumber}
								label='Volume Mês (L)' placeholder='Informe o volume'
								cols='12 6 4 4'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='valor_medio'
								component={LabelAndInputNumber}
								label='Valor Médio (R$/L)' placeholder='Informe o valor'
								cols='12 6 4 4'
								casas={2}
								readOnly={readOnly} />								
						</Row>
						<Row>
							<Field
								name='limite_sugerido'
								component={LabelAndInputNumber}
								label='Limite Sugerido (R$)' placeholder='Informe o valor'
								cols='12 6 3 3'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='limite_proposto'
								component={LabelAndInputNumber}
								label='Limite Proposto (R$)' placeholder='Informe o valor'
								cols='12 6 3 3'
								casas={2}
								readOnly={readOnly} />
								
							<Field
								name='limite_liberado'
								component={LabelAndInputNumber}
								label='Limite Liberado (R$)' placeholder='Informe o valor'
								cols='12 6 3 3'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='limite_volume'
								component={LabelAndInputNumber}
								label='Limite em Volume (L)' placeholder='Informe o volume'
								cols='12 6 3 3'
								casas={0}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='prazo'
								component={LabelAndInputNumber}
								label='Prazo (dias)' placeholder='Informe o prazo'
								cols='12 6 3 3'
								casas={0}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='area_frota_propria'
								component={LabelAndInputNumber}
								label='Área (ha) / Frota Própria' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_frota_arrendada'
								component={LabelAndInputNumber}
								label='Área (ha) / Frota Arrendada' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_soja'
								component={LabelAndInputNumber}
								label='Área Soja (ha)' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_milho'
								component={LabelAndInputNumber}
								label='Área Milho (ha)' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_algodao'
								component={LabelAndInputNumber}
								label='Área Algodão (ha)' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_pasto'
								component={LabelAndInputNumber}
								label='Área Pasto (ha)' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='area_outros'
								component={LabelAndInputNumber}
								label='Área Outros (ha)' placeholder='Informe a área'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='responsavel'
								component={LabelAndInput}
								label='Responsável' placeholder='Informe o responsável'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndTextarea}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 6 6'
								readOnly={readOnly}
								rows={3} />

							<Field
								name='justificativa'
								component={LabelAndTextarea}
								label='Justificativa' placeholder='Informe a justificativa'
								cols='12 12 6 6'
								readOnly={readOnly}
								rows={3} />
						</Row>
						
						</>}

						{/* ABA 2 */}
						{this.state.tabSelecionada == 2 &&
						<>

						<Row>

							<Field
								name='parecer_financeiro'
								component={LabelAndTextarea}
								label='Parecer Financeiro' placeholder='Informe o parecer'
								cols='12 12 12 12'
								readOnly={readOnly}
								rows={3} />

						</Row>
						
						</>}

						{/* ABA 3 */}
						{this.state.tabSelecionada == 3 &&
						<>

						<Row>

							<Field
								name='parecer_gerencia_comercial'
								component={LabelAndTextarea}
								label='Parecer da Gerência Comercial' placeholder='Informe o parecer'
								cols='12 12 12 12'
								readOnly={readOnly}
								rows={3} />
								
							<Field
								name='limite_liberado'
								component={LabelAndInputNumber}
								label='Limite Liberado (R$)' placeholder='Informe o valor'
								cols='12 6 3 3'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='limite_volume'
								component={LabelAndInputNumber}
								label='Limite em Volume (L)' placeholder='Informe o volume'
								cols='12 6 3 3'
								casas={0}
								readOnly={readOnly} />
						</Row>
						
						</>}

						{/* ABA 4 */}
						{this.state.tabSelecionada == 4 &&
						<>

						<Row>
							<Field
								name='parecer_gerencia_geral'
								component={LabelAndTextarea}
								label='Parecer da Gerência Geral' placeholder='Informe o parecer'
								cols='12 12 12 12'
								readOnly={readOnly}
								rows={3} />


							<Grid cols='6 6 6 6'>
								<button type='button' class={`btn btn-block btn${this.props.formularioValues.id_status_gerencia_geral == 1 ? '' : '-outline'}-success`}
									onClick={() => {
										if (!readOnly) {
											this.props.initForm({
												...this.props.formularioValues,
												id_status_gerencia_geral: this.props.formularioValues.id_status_gerencia_geral == 1 ? null : 1
											});
										}
									}}>Aprovado</button>
							</Grid>
							<Grid cols='6 6 6 6'>
								<button type='button' class={`btn btn-block btn${this.props.formularioValues.id_status_gerencia_geral == 2 ? '' : '-outline'}-danger`}
									onClick={() => {
										if (!readOnly) {
											this.props.initForm({
												...this.props.formularioValues,
												id_status_gerencia_geral: this.props.formularioValues.id_status_gerencia_geral == 2 ? null : 2
											});
										}
									}}>Rejeitado</button>
							</Grid>
																
						</Row>
						</>}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							{(this.state.tabSelecionada == 1 && (this.props.excluir || !this.props.formularioValues.id)) &&
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Enviar para Financeiro'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>}

							{(!this.props.excluir && this.state.tabSelecionada == 1 
								&& this.props.formularioValues.id && !this.props.formularioValues.datahora_abertura) &&
								<>
									<Grid cols='6 6 4 3'>
										<Button
											text='Enviar para Financeiro'
											type={'success'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												datahora_abertura: DateFormat.getDataHoraAtual()
											})} />
									</Grid>
								</>
							}

							{(!this.props.excluir && this.state.tabSelecionada == 2 && !this.props.formularioValues.datahora_financeiro) &&
								<>
									<Grid cols='6 6 4 3'>
										<Button
											text='Retornar para Cadastro'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												datahora_abertura: null
											})} />
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Confirmar Parecer'
											type={'success'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												id_usuario_financeiro: this.props.sessao.id,
												datahora_financeiro: DateFormat.getDataHoraAtual()
											})} />
									</Grid>
								</>
							}
							
							{(!this.props.excluir && this.state.tabSelecionada == 3 && !this.props.formularioValues.datahora_gerencia_comercial) &&
								<>
									<Grid cols='6 6 4 3'>
										<Button
											text='Retornar para Financeiro'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												datahora_financeiro: null
											})} />
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Confirmar Parecer'
											type={'success'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												id_usuario_gerencia_comercial: this.props.sessao.id,
												datahora_gerencia_comercial: DateFormat.getDataHoraAtual()
											})} />
									</Grid>
								</>
							}
							
							{(!this.props.excluir && this.state.tabSelecionada == 4 && !this.props.formularioValues.datahora_gerencia_geral) &&
								<>
									<Grid cols='6 6 4 3'>
										<Button
											text='Retornar Gerência Comercial'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												datahora_gerencia_comercial: null,
												id_status_gerencia_geral: null
											})} />
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Confirmar Parecer'
											type={'success'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												id_usuario_gerencia_geral: this.props.sessao.id,
												datahora_gerencia_geral: DateFormat.getDataHoraAtual()
											})} />
									</Grid>
								</>
							}
							
							{(!this.props.excluir && this.state.tabSelecionada == 4 && this.props.formularioValues.datahora_gerencia_geral) &&
								<>
									<Grid cols='6 6 4 3'>
										<Button
											text='Reabrir'
											type={'danger'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.salvar({
												...this.props.formularioValues,
												id_usuario_gerencia_geral: null,
												datahora_gerencia_geral: null
											})} />
									</Grid>
								</>
							}

							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
		
    }


}

LimiteCreditoForm = reduxForm({form: 'limiteCreditoForm', destroyOnUnmount: false})(LimiteCreditoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('limiteCreditoForm')(state),
	registro: state.limiteCredito.registro,
	listaCliente: state.limiteCredito.listaCliente,
	listaEndereco: state.limiteCredito.listaEndereco
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LimiteCreditoForm);
