const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTipo: [],
	listaItem: [],
	listaAno: [{ id: 2024, valor: '2024', dataInicial: '2024/01/01', dataFinal: '2024/12/31' }],
	listaSemestre: [
		{ id: 202401, valor: '1º 2024', dataInicial: '2024/01/01', dataFinal: '2024/06/30' }, 
		{ id: 202402, valor: '2º 2024', dataInicial: '2024/07/01', dataFinal: '2024/12/31' }
	],
	listaTrimestre: [
		{ id: 202401, valor: '1º 2024', dataInicial: '2024/01/01', dataFinal: '2024/03/31' }, 
		{ id: 202402, valor: '2º 2024', dataInicial: '2024/04/01', dataFinal: '2024/06/30' }, 
		{ id: 202403, valor: '3º 2024', dataInicial: '2024/07/01', dataFinal: '2024/09/30' }, 
		{ id: 202404, valor: '4º 2024', dataInicial: '2024/10/01', dataFinal: '2024/12/31' }
	],
	listaMes: [
		{ id: 202401, valor: 'Janeiro 2024', dataInicial: '2024/01/01', dataFinal: '2024/01/31', }, 
		{ id: 202402, valor: 'Fevereiro 2024', dataInicial: '2024/02/01', dataFinal: '2024/02/29', }, 
		{ id: 202403, valor: 'Março 2024', dataInicial: '2024/03/01', dataFinal: '2024/03/31', }, 
		{ id: 202404, valor: 'Abril 2024', dataInicial: '2024/04/01', dataFinal: '2024/04/30', }, 
		{ id: 202405, valor: 'Maio 2024', dataInicial: '2024/05/01', dataFinal: '2024/05/31', }, 
		{ id: 202406, valor: 'Junho 2024', dataInicial: '2024/06/01', dataFinal: '2024/06/30', }, 
		{ id: 202407, valor: 'Julho 2024', dataInicial: '2024/07/01', dataFinal: '2024/07/31', }, 
		{ id: 202408, valor: 'Agosto 2024', dataInicial: '2024/08/01', dataFinal: '2024/08/31', }, 
		{ id: 202409, valor: 'Setembro 2024', dataInicial: '2024/09/01', dataFinal: '2024/09/30', }, 
		{ id: 2024010, valor: 'Outubro 2024', dataInicial: '2024/10/01', dataFinal: '2024/10/31', }, 
		{ id: 2024011, valor: 'Novembro 2024', dataInicial: '2024/11/01', dataFinal: '2024/11/30', }, 
		{ id: 2024012, valor: 'Dezembro 2024', dataInicial: '2024/12/01', dataFinal: '2024/12/31' }
	],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VEICULO_DESPESA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VEICULO_DESPESA_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'VEICULO_DESPESA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'VEICULO_DESPESA_TIPO_SELECT_LISTADO':
            return {
				...state,
				listaTipo: action.payload.data
			};

		case 'VEICULO_DESPESA_ITEM_SELECT_LISTADO':
            return {
				...state,
				listaItem: action.payload.data
			};

        default:
            return state;
    }
}
