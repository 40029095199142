const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaTipo: [],
    listaGrupo: [],
    listaMotorista: [],
    listaVeiculo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'APONTAMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'APONTAMENTO_LISTADO':
            return {
                ...state,
                lista: action.payload.data
            };
           
        case 'APONTAMENTO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
            };

        case 'APONTAMENTO_GRUPO_SELECT_LISTADO':
			return {
				...state,
				listaGrupo: action.payload.data
            };

        case 'APONTAMENTO_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
            };

        case 'APONTAMENTO_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };
            
        default:
            return state;
    }
}
