import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndTextarea from '../../common/form/labelAndTextarea';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndCheckbox from '../../common/form/labelAndCheckbox';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
 
import {
	setModoTela, initForm
} from './prospeccaoEventoActions';

class ProspeccaoEventoForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<Row>
							<Field
								name='contato_reativo'
								component={LabelAndCheckbox}
								options={this.props.listaTipo}
								label='Contato Reativo'
								cols='12 12 12 12'
								placeholder='Selecione o contato'
								readOnly={readOnly} />

							<Field
								name='id_tipo_contato'
								component={Select}
								options={this.props.listaTipo}
								label='Contato'
								cols='12 6 6 6'
								placeholder='Selecione o contato'
								readOnly={readOnly} />

							<Field
								name='descricao'
								component={LabelAndTextarea}
								label='Descrição (mínimo 50 caracteres)' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_tipo_retorno'
								component={Select}
								options={this.props.listaTipoRetorno}
								label='Retorno'
								cols='12 6 6 6'
								placeholder='Selecione o retorno'
								readOnly={readOnly} />
								
						</Row>
						
						{this.props.formularioValues.id_tipo_retorno == 2 ? (
							<Row>
								<Select
									name='id_produto'
									options={[{ id: 1, valor: 'OLEO DIESEL B S10 ADITIVA' }, { id: 3, valor: 'OLEO DIESEL B S500 ADITIVADO' }]}
									label='Produto'
									cols='12 12 12 12'
									placeholder='Selecione o produto'
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.id_produto : ''}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												id_produto: data
											}
										});
									}} />

								<LabelAndInputNumber
									name='quantidade'
									label='Quantidade' placeholder='Informe a quantidade'
									cols='12 6 4 3'
									casas={0}
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.quantidade : ''}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												quantidade: data.target.value
											}
										});
									}} />

								<LabelAndInputNumber
									name='proposta'
									label='Valor Proposta' placeholder='Informe o preço'
									cols='12 6 4 3'
									casas={4}
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.proposta : ''}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												proposta: data.target.value
											}
										});
									}} />

								<LabelAndInputNumber
									name='prazo'
									label='Prazo' placeholder='Informe o prazo'
									cols='12 6 4 3'
									casas={0}
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.prazo : ''} 
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												prazo: data.target.value
											}
										});
									}}/>

								<LabelAndInputNumber
									name='valor_fechado'
									label='Valor Fechado' placeholder='Informe o preço'
									cols='12 6 4 3'
									casas={4}
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.valor_fechado : ''} 
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												valor_fechado: data.target.value
											}
										});
									}}/>

								<LabelAndInput
									name='fornecedor'
									label='Fornecedor' placeholder='Informe o fornecedor'
									cols='12 12 12 12'
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.fornecedor : ''} 
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												fornecedor: data.target.value
											}
										});
									}}/>

								<LabelAndTextarea
									name='observacao'
									label='Observação' placeholder='Informe a observação'
									cols='12 12 12 12'
									readOnly={readOnly}
									value={this.props.formularioValues.formulario_venda_perdida ? this.props.formularioValues.formulario_venda_perdida.observacao : ''} 
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											formulario_venda_perdida: {
												...(this.props.formularioValues.formulario_venda_perdida || {}),
												observacao: data.target.value
											}
										});
									}}/>
							</Row>
						) : null}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									disabled={(this.props.formularioValues.descricao || '').length < 50}
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

ProspeccaoEventoForm = reduxForm({form: 'prospeccaoEventoForm', destroyOnUnmount: false})(ProspeccaoEventoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('prospeccaoEventoForm')(state),
	registro: state.prospeccaoEvento.registro,
	listaTipo: state.prospeccaoEvento.listaTipo,
	listaTipoRetorno: state.prospeccaoEvento.listaTipoRetorno
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProspeccaoEventoForm);
