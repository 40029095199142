const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaCategoria: [
		{ id: 'A', valor: 'A' }, { id: 'B', valor: 'B' }, { id: 'C', valor: 'C' }, { id: 'D', valor: 'D' }, { id: 'E', valor: 'E' },
		{ id: 'AB', valor: 'AB' }, { id: 'AC', valor: 'AC' }, { id: 'AD', valor: 'AD' }, { id: 'AE', valor: 'AE' }
	]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MOTORISTA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'MOTORISTA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        default:
            return state;
    }
}
