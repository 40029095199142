import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './freteLitroForm';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro } from './freteLitroActions';

class FreteLitro extends Component {

	state = {
		linhaSelecionada: null,
		idAlteracao: null,
		volume: null,
		valor_km: null,
		distancia: null,
		valor_despesa: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							label='Data' placeholder='Informe a data'
							cols='6 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data: data.target.value });
								this.props.getLista();
								this.setState({ ...this.state, idAlteracao: null, volume: null, valor_km: null, distancia: null, valor_despesa: null });
							}} />

						{this.props.filtro.data && DateFormat.formatarDataTelaParaSql(this.props.filtro.data) 
							&& DateFormat.formatarDataTelaParaSql(this.props.filtro.data) == DateFormat.getDataAtual() ? (
							<Grid cols='6 6 4 2' style={{ marginTop: 28 }}>
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.salvar({});
									}} />
							</Grid>
						) : null}
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th width={200}>Unidade</Th>
								<Th width={200} alignCenter>Cidade</Th>
								<Th width={100} alignCenter>Volume</Th>
								<Th width={100} alignCenter>R$/km</Th>
								<Th width={100} alignCenter>Distância</Th>
								<Th width={100} alignCenter>Despesas</Th>
								<Th width={100} alignCenter>Frete</Th>
								<Th width={100}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_unidade} ${item.nome_cidade || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id}>
									<Td minWidth={200}>{item.nome_unidade}</Td>
									<Td minWidth={200} alignCenter>{item.nome_cidade}</Td>
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.volume, 0)
									: (
										<LabelAndInputNumber
											placeholder='Informe o volume'
											cols='12 12 12 12'
											casas={0}
											value={this.state.volume}
											onChange={(data) => {
												this.setState({ ...this.state, volume: data.target.value })
											}} />
									)}</Td>
									
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.valor_km, 2)
									: (
										<LabelAndInputNumber
											placeholder='Informe o R$/km'
											cols='12 12 12 12'
											casas={2}
											value={this.state.valor_km}
											onChange={(data) => {
												this.setState({ ...this.state, valor_km: data.target.value })
											}} />
									)}</Td>
									
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.distancia, 0)
									: (
										<LabelAndInputNumber
											placeholder='Informe o distância'
											cols='12 12 12 12'
											casas={0}
											value={this.state.distancia}
											onChange={(data) => {
												this.setState({ ...this.state, distancia: data.target.value })
											}} />
									)}</Td>
									
									<Td minWidth={100} alignCenter>{item.id != this.state.idAlteracao ?
										FormatUtils.formatarValorTela(item.valor_despesa, 0)
									: (
										<LabelAndInputNumber
											placeholder='Informe o valor das despesas'
											cols='12 12 12 12'
											casas={0}
											value={this.state.valor_despesa}
											onChange={(data) => {
												this.setState({ ...this.state, valor_despesa: data.target.value })
											}} />
									)}</Td>

									<Td minWidth={100} alignCenter>{FormatUtils.formatarValorTela(item.valor_frete, 4)}</Td>
									<Td alignRight minWidth={100}>
										{!this.state.idAlteracao || item.id != this.state.idAlteracao ? (
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={this.state.idAlteracao && item.id != this.state.idAlteracao}
												event={() => {
													this.setState({ 
														...this.state, 
														idAlteracao: item.id, 
														volume: FormatUtils.formatarValorTela(item.volume, 0),
														valor_km: FormatUtils.formatarValorTela(item.valor_km, 2),
														distancia: FormatUtils.formatarValorTela(item.distancia, 0),
														valor_despesa: FormatUtils.formatarValorTela(item.valor_despesa, 0)
													});
												}} />
										) : (
											<>
												<ButtonTable
													type={'success'}
													icon={'fas fa-check'}
													visible={true}
													disabled={false}
													event={() => {
														this.props.salvar({ 
															...item,
															volume: this.state.volume, 
															valor_km: this.state.valor_km, 
															distancia: this.state.distancia, 
															valor_despesa: this.state.valor_despesa
														});
														this.setState({ ...this.state, idAlteracao: null, volume: null, valor_km: null, distancia: null, valor_despesa: null });
													}} />
													
												<ButtonTable
													type={'danger'}
													icon={'fas fa-times'}
													visible={true}
													disabled={false}
													event={() => {
														this.setState({ ...this.state, idAlteracao: null, volume: null, valor_km: null, distancia: null, valor_despesa: null });
													}} />
											</>
										)}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.freteLitro.modoTela,
	lista: state.freteLitro.lista,
	filtro: state.freteLitro.filtro,
	aguardando: state.freteLitro.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FreteLitro);
