import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './whatsappForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaCliente } from './whatsappActions';
import DateFormat from '../common/dateFormat/DateFormat';

class Whatsapp extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCliente();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					{/* <Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({  whatsapp: true, ponto_atendimento: false, vendedor: false });
								}} />
						</Grid>
					</Row> */}
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Data</Th>
								<Th colspan={1}>Assunto</Th>
								<Th colspan={1}>Mensagem</Th>
								<Th colspan={1} alignCenter>Clientes</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.assunto} ${item.mensagem || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
									<Td>{item.assunto}</Td>
									<Td>{item.mensagem.split('\n').map(mensagem => (
										<p>{mensagem}</p>
									))}</Td>
									<Td alignCenter>{(item.clientes || []).length}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.whatsapp,
	modoTela: state.whatsapp.modoTela,
	lista: state.whatsapp.lista,
	listaCliente: state.whatsapp.listaCliente,
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Whatsapp);
