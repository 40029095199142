const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaUnidade: [],
    listaProduto: [],
    listaTanque: [],
    listaVeiculo: [],
    listaMotorista: [],
    listaTipo: [
        { id: 1, valor: 'Abastecimento' },
        { id: 2, valor: 'Perdas / Derramamento' },
        { id: 3, valor: 'Aferição' },
        { id: 4, valor: 'Quebra de Estoque' }
    ]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'SAIDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'SAIDA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'SAIDA_UNIDADE_SELECT_LISTADO':
			return {
				...state,
				listaUnidade: action.payload.data
            };

        case 'SAIDA_PRODUTO_SELECT_LISTADO':
			return {
				...state,
				listaProduto: action.payload.data
            };

        case 'SAIDA_TANQUE_SELECT_LISTADO':
			return {
				...state,
				listaTanque: action.payload.data
            };
        
        case 'SAIDA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
            };

        case 'SAIDA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
            };
                      
        default:
            return state;
    }
}
