import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './metaMotoristaGrupoActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

class MetaMotoristaGrupoForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='id_grupo'
								component={Select}
								options={this.props.listaGrupo}
								label='Grupo'
								cols='12 12 12 12'
								placeholder='Selecione o grupo'
								readOnly={readOnly} />

							<Field
								name='peso'
								component={LabelAndInputNumber}
								label='Peso' placeholder='Informe os peso'
								cols='12 12 12 12'
								casas={0}
								readOnly={readOnly} />
								
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

MetaMotoristaGrupoForm = reduxForm({form: 'metaMotoristaGrupoForm', destroyOnUnmount: false})(MetaMotoristaGrupoForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaMotoristaGrupo,
	formularioValues: getFormValues('metaMotoristaGrupoForm')(state),
	registro: state.metaMotoristaGrupo.registro,
	listaGrupo: state.metaMotoristaGrupo.listaGrupo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaMotoristaGrupoForm);
