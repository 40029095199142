

export default class FormatUtils {

	static removerMascara(valor) {
		return (valor || '').replace(/[^a-z0-9]/gi, '');
	}

	static formatarCPF(valor) {
		return (valor || '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
	}

	static formatarCNPJ(valor) {
		return (valor || '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
	}

	static formatarCEP(valor) {
		return (valor || '').replace(/(\d{5})(\d{3})/, "$1-$2");
	}

	static formatarTelefone(valor) {
		return (valor || '').replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
	}

	static formatarValorTela(valor, casas = 0) {
		let formatado = parseFloat(valor || 0).toFixed(casas);
		return casas == 0 ? formatado : formatado.split('.').join(',');
	}

	static formatarValorTelaDecimal(valor, casas = 0) {
		let formatado = parseFloat(valor || 0).toFixed(casas);
		return casas == 0 ? formatado.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.') : formatado.split('.').join(',').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
	}

	static formatarValorBanco(valor, casas = 0) {
		return parseFloat((String(valor || '0')).split('.').join('').split(',').join('.')).toFixed(casas);
	}
	
	static formatarCompetenciaTela(competencia) {
		return competencia ? `${String(competencia).substring(4, 6)}/${String(competencia).substring(0, 4)}`: '';
	}

	static formatarCompetenciaBanco(competencia) {
		return competencia ? `${String(competencia).split('/')[1]}${String(competencia).split('/')[0]}}`: '';
	}
}
