const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [],
    listaCliente: [],
    registroDashboard: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_VENDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_VENDA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_VENDA_CLIENTE_LISTADO':
            return { ...state, listaCliente: action.payload.data };

        case 'META_VENDA_DASHBOARD_LISTADO':
            return { ...state, registroDashboard: action.payload };

        default:
            return state;
    }
}
