import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pedidoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';

import { 
	getListaEstoque, getListaUnidade, getListaProduto
} from './pedidoActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

class PedidoEntrega extends Component {

	state = {
		linhaSelecionada: null,
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 2592000000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
		this.props.getListaEstoque(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaUnidade();
		this.props.getListaProduto();
	}
	
	componentWillUnmount() {

	
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
	
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />

						<Select
							options={this.props.listaUnidade}
							label='Unidade'
							cols='12 6 4 3'
							placeholder='Selecione a unidade'
							value={this.state.id_unidade}
							onChange={value => this.setState({ ...this.state, id_unidade: value })} />
							
						<Select
							options={this.props.listaProduto}
							label='Produto'
							cols='12 6 4 3'
							placeholder='Selecione o produto'
							value={this.state.id_produto}
							onChange={value => this.setState({ ...this.state, id_produto: value })} />
							
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getListaEstoque(this.state.dataInicial, this.state.dataFinal);
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>

				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data do Pedido</Th>
								<Th alignCenter>Aprovação</Th>
								<Th alignCenter>Finalizado Base</Th>
								<Th alignCenter>Entrega</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Unidade</Th>
								<Th alignCenter>Giro</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Vendedor</Th>
								<Th alignCenter>Cliente</Th>
								<Th alignCenter>Endereço</Th>
								<Th alignCenter>Produto</Th>
								<Th alignCenter>Quantidade</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaEstoque.filter(item => {
								if (this.state.id_unidade && item.id_unidade != this.state.id_unidade) {
									return false;
								}
								if (this.state.id_produto && item.id_produto != this.state.id_produto) {
									return false;
								}
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.data_pedido)} ${item.nome_status} ${item.nome_cliente}
										${item.giro} ${item.nome_motorista} ${item.placa_veiculo} ${item.nome_usuario_inclusao}
										${item.nome_endereco || ''} ${item.nome_produto || ''}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={`${item.id} ${item.id_item}`} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_pedido)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_aprovacao_rejeicao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_logistica)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega)}</Td>
									<Td alignCenter>{item.nome_status}</Td>
									<Td alignCenter>{item.nome_unidade}</Td>
									<Td alignCenter>{item.giro}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_usuario_inclusao}</Td>
									<Td alignCenter>{item.nome_cliente}</Td>
									<Td alignCenter>{item.nome_endereco}</Td>
									<Td alignCenter>{item.nome_produto}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)}</Td>									
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.pedido.aguardando,
	modoTela: state.pedido.modoTela,
	listaEstoque: state.pedido.listaEstoque,
	listaUnidade: state.pedido.listaUnidade,
	listaProduto: state.pedido.listaProduto
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaEstoque, getListaUnidade, getListaProduto
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoEntrega);
