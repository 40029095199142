const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaGrupo: [],
    listaSetor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'APONTAMENTO_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'APONTAMENTO_TIPO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'APONTAMENTO_TIPO_GRUPO_SELECT_LISTADO':
			return {
				...state,
				listaGrupo: action.payload.data
            };
            
        case 'APONTAMENTO_TIPO_SETOR_SELECT_LISTADO':
			return {
				...state,
				listaSetor: action.payload.data
			};

        default:
            return state;
    }
}
