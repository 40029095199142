const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaGrupo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_MOTORISTA_GRUPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_MOTORISTA_GRUPO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_MOTORISTA_GRUPO_GRUPO_SELECT_LISTADO':
			return {
				...state,
				listaGrupo: action.payload.data
			};

        default:
            return state;
    }
}
