import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './adicionalDiarioActions';

class AdicionalDiarioForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='acesso_usuario'
								component={LabelAndCheckbox}
								label='Cadastro de Usuários' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_cadastro'
								component={LabelAndCheckbox}
								label='Cadastro' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_preco_dia'
								component={LabelAndCheckbox}
								label='Preço do Dia' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_politica'
								component={LabelAndCheckbox}
								label='Política de Preços' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_vendas'
								component={LabelAndCheckbox}
								label='Vendas' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='acesso_logistica'
								component={LabelAndCheckbox}
								label='Logística' placeholder='Selecione'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

AdicionalDiarioForm = reduxForm({form: 'adicionalDiarioForm', destroyOnUnmount: false})(AdicionalDiarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('adicionalDiarioForm')(state),
	registro: state.adicionalDiario.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdicionalDiarioForm);
